@import '../../../../../styles/shared';

.c-article-metadata {
  display: flex;
  overflow: hidden;
  margin-bottom: 25px;

  @include mq($until: tablet) {
    margin-bottom: 15px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    font-weight: 300;

    &:nth-child(1) {
      padding-right: 25px;
      max-width: 50%;
    }

    &-title {
      margin-bottom: 3px;
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 17px;
      text-transform: uppercase;
      color: #b1b1b1;
    }

    &-value {
      font-family: $font-family-sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: #333;
    }
  }
}
