@import '../../../../styles/shared';

.c-payment-form-block__vat-rules {
  margin-bottom: 15px;

  .c-vat-rules {
    position: relative;
    .form-row {
      position: relative;
    }
    .c-input-label {
      color: #000;
    }
    &__select-field {
      position: relative;
      margin-bottom: 20px;

      .form-row {
        z-index: 2;

        select {
          background-color: transparent;
        }
      }
    }

    .c-form-field__select-icon {
      position: absolute;
      @include right(12px);
      top: 42px;
      font-size: 26px;
    }
    &__radioGroup-field {
      margin: 25px 0 5px;
      position: relative;
      label {
        display: inline-block;
        margin: 10px;
      }
      input {
        @include float(left);
        margin: 10px;
      }
    }

    &__text-field {
      position: relative;
    }

    &.has-error .error {
      .c-form-field {
        border-color: $form-error-color;
      }
      .c-input-error {
        display: block;
        color: $form-error-color;
      }
    }
  }
}
