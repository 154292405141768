@import '../../../../styles/shared';

.person-content-table {
  background: #fff;
  color: #000;

  &__header {
    padding: 30px 0 26px 0;
    margin: 0;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    border-top: 1px solid;
    border-bottom: 1px solid;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include theme-only($ASIATECHXSG) {
      font-family: 'Noto Sans', sans-serif;
      font-weight: bold;
      text-transform: none;
      color: $body-color !important;
      border-color: $body-color !important;
    }
  }
}
