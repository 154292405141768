@import '../../../../styles/shared';

.v-co-lo-agenda-view {
  &__content-mobile {
    @include mq($from: tablet) {
      display: none;
    }
  }

  &__content {
    position: relative;
    @include mq($until: tablet) {
      display: none;
    }
  }

  &__menu-bar {
    @include mq($until: tablet) {
      display: none;
    }
  }

  &__menu-bar-mobile {
    @include mq($from: tablet) {
      display: none;
    }
  }

  &__filters,
  &__content {
    padding: 0;
  }

  &__filters {
    background-color: #fff;
    position: relative;
  }

  .row {
    margin: 0;
  }
}
