@import '../../../styles/shared';

.c-billing-details-form__container {
  color: #333;

  &-wrapper {
    padding: 60px 0;
  }

  &__payment-method {
    margin-bottom: 10px;
  }
}

.c-billing-details-form {
  &__payment-method {
    margin-bottom: 10px;
  }

  &__restart-booking {
    padding-top: 20px;
    font-size: 14px;
    color: #999999;

    &-link {
      text-decoration: underline;
      font-size: 16px;
      cursor: pointer;
      i {
        font-size: 20px;
        @include margin-right(5px);
        vertical-align: text-bottom;
      }
    }
  }
}
