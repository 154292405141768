@import '../../../../../styles/shared';

.c-article-share-bar {
  margin-bottom: 26px;

  &__title {
    margin: 0 0 16px;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    color: $tenant-primary-color;

    @include mq($from: tablet) {
      display: inline-block;
      margin: 0 20px 16px 0;
    }
  }

  &__icons {
    display: inline-block;
    border-radius: 3px;
    background-color: $form-disabled-color;
    padding: 7px 9px 6px;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    margin: 0 9px;
    font-size: 22px;
    text-decoration: none;

    .fa-linkedin-in {
      color: #0976b4;
    }

    .fa-x-twitter {
      color: #333;
    }

    .fa-facebook-f {
      color: #3b5998;
    }

    .fa-envelope {
      position: relative;
      top: 1px;
      font-size: 24px;
      color: #586876;
    }

    .fa-link {
      color: #586876;
    }
  }

  &__input {
    position: absolute;
    left: -9999px;
  }

  &__tooltip {
    background-color: #586876 !important;

    &.place-top:after {
      border-top-color: #586876 !important;
    }
  }
}

.black-and-red-view {
  .c-article-share-bar {
    &__link i {
      color: #c51f3d;
    }
  }
}
