@import '../../../../styles/shared';

.c-other-contact-details-form {
  padding: 60px 0;

  &__link {
    display: block;
    margin-top: 10px;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;

    &--delete {
      color: $form-error-color;
    }
  }

  &__submit-panel {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;

    > * {
      flex-grow: 1;
    }

    > *:last-child {
      @include text-align(right);
    }

    a {
      font-size: 14px;
      line-height: 19px;
      color: #092864;
    }
  }

  .c-billing-form__heading {
    margin-bottom: 20px;
  }
}
