@import '../../../../styles/shared';

@include theme-only($ASIATECHXSG) {
  .c-person-content-section__container {
    a {
      color: $body-color !important;
    }
  }
}

.c-person-content-section__external-link {
  margin: 0 5px;
  font-size: 14px;
}
