@import '../../../styles/shared';

.order-info-legalese {
  margin-top: 60px;
  font-size: 13px;
  line-height: 1.4;
  font-weight: 400;
  text-transform: none;
  color: #333;

  &__link {
    text-decoration: underline;
    color: inherit;
  }
}
