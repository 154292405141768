@import '../../../../styles/shared';
@import '../../../../styles/fonts/roboto';
//TODO: move this part to global theme level after branding updates
@import 'typography';
@import 'palette';

:root {
  /**
   * Shared component styles
   */
  --payment-component-shared-border-radius: 4px;
  --payment-page-layout-sticky-column-padding: 56px;
  --payment-page-layout-sticky-column-top-offset: 64px;

  /**
   * Button
   */
  --payment-component-button-shape-square-border-radius: var(
    --payment-component-shared-border-radius
  );
  --payment-component-button-shape-rounded-border-radius: 40px;
  --payment-component-button-size-medium-padding: 11px 40px;
  --payment-component-button-size-small-padding: 9px 24px;

  /**
  * Paper
  */
  --payment-component-paper-border-radius: var(
    --payment-component-shared-border-radius
  );
  --payment-component-paper-padding: 16px;
  --payment-component-paper-padding-small: 12px;
  --payment-component-paper-padding-extra-small: 8px;

  @include mq($from: desktop) {
    --payment-component-paper-padding: 24px;
    --payment-component-paper-padding-small: 16px;
  }

  /**
   * Tooltip
   */
  --payment-component-tooltip-border-radius: 8px;
  --payment-component-tooltip-padding: 12px;
  --payment-component-tooltip-max-width: 360px;

  /**
   * Tabs
   */
  --payment-component-tabs-border-radius: var(
    --payment-component-shared-border-radius
  );
  --payment-component-tabs-padding: 2px;

  /**
   * Tab
   */
  --payment-component-tab-border-radius: var(
    --payment-component-shared-border-radius
  );
  --payment-component-tab-padding: 8px 16px;

  /**
   * CircledIcon
   */
  --payment-component-circled-icon-padding-extra-small: 4px;
  --payment-component-circled-icon-padding-small: 8px;
  --payment-component-circled-icon-padding-medium: 12px;
  --payment-component-circled-icon-padding-large: 16px;

  /**
   * Tag
   */
  --payment-component-tag-shape-square-border-radius: var(
    --payment-component-shared-border-radius
  );
  --payment-component-tag-shape-rounded-border-radius: 24px;
  --payment-component-tag-padding: 4px 8px;

  /**
   * Accordion
   */
  --payment-component-accordion-title-min-height: 64px;
  --payment-component-accordion-content-indention: 0;
  --payment-component-accordion-content-small-indention: 16px;

  @include mq($from: desktop) {
    --payment-component-accordion-content-indention: 13px;
    --payment-component-accordion-title-min-height: 77px;
  }

  /**
   * Stepper
   */
  --payment-component-stepper-icon-size: 24px;
  --payment-component-stepper-font-size: 16px;

  @include mq($from: desktop) {
    --payment-component-stepper-icon-size: 32px;
    --payment-component-stepper-font-size: 18px;
  }

  /**
   * IconProgress
   */
  --payment-component-avatar-progress-size: 56px;
  --payment-component-icon-progress-size: 32px;

  @include mq($from: desktop) {
    --payment-component-avatar-progress-size: 60px;
    --payment-component-icon-progress-size: 40px;
  }

  /**
   * CardBadge
   */
  --payment-component-card-badge-border-radius: var(
    --payment-component-shared-border-radius
  );

  /**
   * Alert
   */
  --payment-component-alert-border-radius: var(
    --payment-component-shared-border-radius
  );

  /**
   * OutlinedInput
   */
  --payment-component-outlined-input-border-radius: var(
    --payment-component-shared-border-radius
  );

  /**
   * Select
   */
  --payment-component-select-menu-border-radius: var(
    --payment-component-shared-border-radius
  );

  /**
   * FileUpload
   */
  --payment-component-file-upload-border-radius: 8px;

  /**
   * ProductsWrapper
   */
  --payment-products-wrapper-border-radius: var(
    --payment-component-shared-border-radius
  );

  /**
   * Section
   */
  --payment-layout-section-margin: 56px;
  --payment-layout-section-margin-small: 40px;
  --payment-layout-section-margin-extra-small: 16px;

  @include mq($from: desktop) {
    --payment-layout-section-margin: 100px;
    --payment-layout-section-margin-small: 56px;
    --payment-layout-section-margin-extra-small: 24px;
  }

  /**
   * Popper
   */
  --payment-component-popper-padding: 24px;
  --payment-component-popper-arrow-size: 22px;
}
