@import '../../../../styles/shared';

$bg-color: #dbe4e8;

.c-l1-topic-box {
  display: block;
  position: relative;
  min-height: 135px;
  margin-top: 40px;
  padding-bottom: 50px;
  text-decoration: none;
  border: 1px solid $bg-color;
  border-left-color: $tenant-border-separator-color;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  &--primary {
    min-height: 155px;
    padding: 22px 22px 0 22px;
  }

  &:hover {
    border: 1px solid $tenant-border-separator-color;
  }

  &__overlay,
  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__overlay {
    background-color: rgba(2, 34, 68, 0.9);
  }

  &__content {
    padding: 18px 18px 0 18px;
    background-color: $bg-color;
    transition: background-color 0.4s ease;
    z-index: 1;
  }

  &__popular-badge {
    margin-bottom: 15px;
    font-family: $font-family-sans-serif;
    font-size: 15px;
    font-weight: bold;
    line-height: 16px;
    color: #333;
  }

  &__name {
    display: flex;
    flex-direction: row;
    font-family: $font-family-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
    color: $tenant-primary-color;

    span {
      flex-grow: 1;
    }
  }

  &--primary &__name {
    font-size: 28px;
    line-height: 34px;

    @include mq($until: desktop) {
      font-size: 26px;
    }
  }

  &__icon {
    position: relative;
    top: 9px;
    width: 17px;
    height: 17px;
    min-width: 17px;
    margin-left: 5px;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    border-radius: 50%;
    background: $tenant-primary-color;
    color: #fff;

    &--external {
      font-size: 20px;
      background: transparent;
      color: $tenant-primary-color;
    }
  }

  &--primary &__icon {
    top: 4px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    font-size: 18px;
    line-height: 24px;
  }

  &--primary &__icon--external {
    font-size: 24px;
  }

  &__featured-brands {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: bottom 0.4s ease;

    &-label {
      min-width: 125px;
      padding: 17px 0 17px 20px;
      font-size: 14px;
      line-height: 16px;
      color: #a6bcc6;
    }

    &-list {
      flex-grow: 1;
      margin: 9px 20px;
      overflow: hidden;
    }

    &-item {
      font-family: 'Noto Sans', sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
      color: #fff;

      & + & {
        margin-left: 20px;
      }

      img {
        max-height: 32px;
        vertical-align: middle;
      }
    }
  }

  &:hover &__content {
    background-color: transparent;
  }

  &:hover &__popular-badge,
  &:hover &__name {
    color: #fff;
  }

  &:hover &__icon {
    background-color: #fff;
    color: $tenant-accent-color;
  }

  &:hover &__icon--external {
    background-color: transparent;
    color: #fff;
  }

  &:hover &__featured-brands {
    bottom: 0;
  }
}
