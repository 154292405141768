@import '../../../../../styles/shared';

.c-search-content-view-all {
  position: relative;
  width: 20%;
  border-radius: 0 0 10px 10px;
  background-color: #333;
  color: #fff;
  font-family: $font-family-serif;
  font-size: 31px;
  line-height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;

  @include mq($until: $extended-layout-width) {
    width: 30%;
  }

  &--full-width {
    @include mq($until: $extended-layout-width) {
      width: 100%;
    }
  }

  @include mq($until: tablet) {
    min-width: 228px;
  }

  &__info {
    margin: 20px 0 45px 20px;
  }

  &__arrow {
    width: 40px;
    height: 40px;
    position: absolute;
    background-color: $tenant-accent-color;
    color: #fff;
    font-size: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -18px;
    left: 20px;
  }
}
