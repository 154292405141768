@import '../../../styles/shared';

.lead-gen-form {
  padding-bottom: 50px;

  &__title {
    font-weight: 300;
    font-size: 28px;
    color: #333;
    line-height: 28px;
    margin: 0 0 11px;
    padding-top: 0;

    &-type-1 {
      font-size: 22px;
    }

    &-type-2 {
      font-size: 28px;
    }

    &--uppercase {
      text-transform: uppercase;
    }

    &--update-form {
      padding: 0;
      font-size: 32px;
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      font-weight: 800;
      line-height: 44px;
      color: #333333;
    }
  }

  &__subtitle {
    color: $form-helper-color;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 13px;
  }

  &__error {
    color: #c9474d;
    font-size: 16px;
    //@include text-align(right);
    text-align: right;
  }

  &__error-message {
    margin: 20px 0 10px 0;
    color: #c9474d;
    font-size: 12px;
    //@include text-align(left);
    text-align: left;
  }

  .lead-gen-register-button,
  .lead-gen-update-button {
    margin-top: 20px;
    min-width: 200px;
  }

  &.lead-gen-update-form {
    padding-top: 20px;
  }
}
@include theme-only($DEFAULT) {
  .lead-gen-container__form-article {
    label {
      font: normal 14px/22px $font-family-sans-serif;
      color: #333;
    }
  }
}
