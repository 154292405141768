@import '../../../styles/shared';

.c-accessing-event-message {
  padding: 30px 15px 15px;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background-color: #fff;

  &__title {
    max-width: 560px;
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 800;
    line-height: 1;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: 'Aleo', serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__content {
    b {
      font-weight: bold;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
