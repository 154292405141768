@import '../../../styles/shared';

.basket-totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-top: #dcdcdc 1px solid;
  color: #000;

  &--empty {
    border: none;
  }

  &__empty-message {
    width: 100%;
    margin-top: 10px;
  }

  &__table {
    padding: 32px 0 20px 0;
  }

  &__label {
    @include padding-right(20px);

    &--carbon-incl-vat {
      font-size: 12px;
      font-style: italic;
      font-weight: 300;
      line-height: 14px;
      @include text-align(right);
      color: #b1b1b1;
    }

    &--carbon-plus-vat {
      @include padding-right(20px);
      font-size: 12px;
      font-style: italic;
      @include text-align(right);
      color: #333333;
    }
  }

  &__carbon-incl-vat {
    color: #333333;
    font-size: 12px;
    line-height: 14px;
    @include text-align(right);
  }

  &__carbon-plus-vat,
  &__carbon-incl-vat {
    td {
      height: initial;
    }
  }

  &__label,
  &__value {
    height: 32px;
    vertical-align: bottom;
    @include text-align(right);
  }

  &__row {
    &-price {
      font-weight: bold;
    }

    &-discount {
      font-weight: bold;
      color: #70cb14;
    }

    &-including-tax {
      font-size: 12px;
      font-weight: 300;
      color: #aaaaaa;
    }
  }

  &__icon {
    position: relative;
    top: 6px;
    @include right(10px);
    font-size: 24px;
  }

  &__basket-submit {
    margin: 10px 0 60px;

    @include mq($until: tablet) {
      display: block;
      margin-bottom: 40px;
    }

    &[disabled] {
      background-color: #aaaaaa !important;
      color: #e5e5e5 !important;
    }
  }
}
