@import '../../../styles/shared';

$line-width: 1px;

.c-billing-form {
  &__body {
    padding: 15px;
    background: #fff;
  }

  &__heading {
    font-size: 28px;
    font-weight: 300;
    margin: 0 0 40px 0;
    line-height: 1.2;

    @include mq($until: tablet) {
      font-size: 24px;
    }
  }

  p.heading {
    margin: 5px 0;
    padding: 8px 0;
    font-size: 14px;
  }

  table {
    width: 100%;

    thead {
      td {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        @include text-align(left);
        padding: 10px 0;
        color: #333;
      }
    }

    tbody {
      @include border-left($line-width solid #000);
      border-top: $line-width solid #000;

      tr {
        td {
          @include border-right($line-width solid #000);
          border-bottom: $line-width solid #000;
        }
      }
    }

    tr {
      display: flex;
      flex-direction: column;

      @include mq($from: tablet) {
        flex-direction: row;
      }

      td {
        display: block;
        flex: 1;
      }
    }
  }

  table + table {
    margin-top: 20px;
  }

  button[type='submit'] {
    margin-top: 20px;
  }
}
