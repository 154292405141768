@import '../../../../styles/shared';

.c-select-delegate-block {
  margin-bottom: 40px;

  &__item + &__item {
    margin-top: 10px;
  }

  &__description {
    font-weight: 300;
  }
}
