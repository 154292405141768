@import '../../../../styles/shared';

.person-content-carousel {
  padding: 30px 0;

  &__item {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    background: no-repeat center center #fff;
    background-size: cover;

    &-text {
      margin: 25px 10px 0;

      &:hover .person-content-carousel__item-link {
        text-decoration: underline;
      }
    }

    &-link {
      display: block;
      text-decoration: none;
      z-index: 100;

      &:hover {
        text-decoration: underline;
      }
    }

    &-name,
    &-job,
    &-company {
      text-align: center;

      @include theme-only($ASIATECHXSG) {
        color: $body-color;
      }
    }

    &-name {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    &-job,
    &-company {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      top: 60px;
      width: 62px;
      height: 62px;
      background: no-repeat center center #fff;
      background-size: cover;
      border-radius: 50%;
      border: none;
      outline: none;
      z-index: 1;

      &:before {
        position: absolute;
        margin: 0;
        @include left(50%);
        top: 50%;
        [dir='ltr'] & {
          transform: translateX(-50%) translateY(-50%);
        }
        [dir='rtl'] & {
          transform: translateX(50%) translateY(-50%);
        }
        font-size: 18px;
        color: #fff;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
      }
    }

    &.slick-prev {
      @include left(30px);
      [dir='rtl'] & {
        left: initial;
      }

      @include mq($until: tablet) {
        @include left(20px);
      }

      &:hover {
        border: none;
        box-shadow: none;
      }
    }

    &.slick-next {
      @include right(30px);

      @include mq($until: tablet) {
        @include right(20px);
      }

      &:hover {
        border: none;
        box-shadow: none;
      }
    }

    .arrow-text {
      position: relative;
      top: 65px;
      font-size: 13px;
      line-height: 1.4;
      font-weight: 400;
      text-align: center;
      color: #000;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
