@import '../../../../../styles/shared';

@include one-of-themes-except([$FANEXPOHQ, $MEFCC]) {
  .c-nav-accordion {
    &__panel {
      margin: 0 0 2px;
      @include padding(10px, 0, 10px, 20px);
      border-top: $multi-level-nav-underline-width solid
        $multi-level-nav-level-1-text-color;
      border-bottom: $multi-level-nav-underline-width solid
        $multi-level-nav-level-1-text-color;

      @include mq($from: tablet) {
        margin: 0 0 3px;
        @include padding(15px, 0, 15px, 20px);
      }

      .c-multi-level-nav-btn {
        &__link {
          padding: 0;
          height: auto !important;
        }

        &__one-line {
          font-size: 18px;
          font-weight: 300;
          line-height: 44px;

          @include mq($from: tablet) {
            line-height: 54px;
          }
        }
      }
    }

    &__button {
      position: relative;
      display: flex;
      flex-direction: row;

      .c-multi-level-nav-btn {
        flex-grow: 1;

        &__upper {
          font-size: 16px;
          line-height: 1.8;

          @include mq($from: tablet) {
            font-size: 22px;
          }
        }

        &__lower {
          font-size: 16px;

          @include mq($from: tablet) {
            font-size: 22px;
          }
        }

        &--multi-line {
          padding: 13px 0 16px;

          @include mq($from: tablet) {
            padding: 13px 0 21px;
          }

          .c-multi-level-nav-btn__link {
            flex-direction: column;
          }
        }

        &--multi-line:not(.c-multi-level-nav-btn--active) {
          border-bottom: 1px solid !important;
        }

        &--multi-line-active {
          border-bottom-width: 1px !important;
          border-bottom-style: solid !important;
        }

        &--group {
          border-bottom: 0 !important;
        }

        &__link {
          padding: 0;
          height: auto !important;

          &::after {
            display: none !important;
          }
        }
      }
    }

    &__expand-icon,
    &__collapse-icon {
      position: absolute;
      top: 10px;
      right: 0;
      display: none;

      @include mq($from: tablet) {
        top: 17px;
      }
    }

    [aria-expanded='false'] &__expand-icon {
      display: flex;
    }

    [aria-expanded='true'] &__collapse-icon {
      display: flex;
    }

    &__expand-chevron,
    &__collapse-chevron {
      display: none;
    }
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-nav-accordion {
    &__panel {
      @include padding-left(24px);

      @include mq($from: tablet) {
        @include padding(2px, 0, 3px, 22px);
      }

      .c-multi-level-nav-btn__link {
        font-size: 27px;
        line-height: 32px;

        @include mq($from: tablet) {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    &__button {
      position: relative;
      display: flex;
      flex-direction: row;
      @include padding-left(10px);

      @include mq($from: tablet) {
        @include padding-left(13px);
      }
    }

    &__expand-chevron,
    &__collapse-chevron {
      position: absolute;
      top: 11px;
      @include left(-11px);
      display: none;
      flex-direction: row;
      align-items: center;

      @include mq($from: tablet) {
        top: 8px;
        @include left(-4px);
        transform: scale(0.7);
      }
    }

    [aria-expanded='false'] &__expand-chevron {
      display: flex;
    }

    [aria-expanded='true'] &__collapse-chevron {
      display: flex;
    }

    &__expand-icon,
    &__collapse-icon {
      display: none;
    }
  }
}

@include one-of-themes([$ONEOFAKIND, $NAJAHSTACK]) {
  .c-nav-accordion {
    &__panel {
      padding-top: 0;
      padding-bottom: 0;
      border-top: none;
      border-bottom: none;

      .c-multi-level-nav-btn {
        &__link {
          padding-top: 32px;
        }

        &__one-line {
          font-family: 'GT America Thin', sans-serif;
          font-size: 32px;
          font-weight: 100;
          line-height: 40px;
        }
      }
    }

    &__item {
      padding: 16px 0;
      &:first-child {
        padding-top: 32px;
      }
    }

    &__button {
      justify-content: flex-start;
      align-items: center;
      .c-multi-level-nav-btn {
        flex-grow: initial;
      }
    }

    &__expand-icon,
    &__collapse-icon {
      position: initial;

      i {
        font-size: 32px;
      }
    }
  }
}

@include theme-only($THEARTISTPROJECT) {
  .c-nav-accordion {
    &__panel {
      padding-top: 0;
      padding-bottom: 0;
      border-top: none;
      border-bottom: none;

      .c-multi-level-nav-btn {
        &__link {
          padding-top: 32px;
        }

        &__one-line {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 32px;
          font-weight: 300;
          line-height: 40px;
        }
      }
    }

    &__item {
      padding: 16px 0;
      &:first-child {
        padding-top: 32px;
      }
    }

    &__button {
      justify-content: flex-start;
      align-items: center;
      .c-multi-level-nav-btn {
        flex-grow: initial;
      }
    }

    &__expand-icon,
    &__collapse-icon {
      position: initial;

      i {
        font-size: 32px;
      }
    }
  }
}

@include theme-only($ARTTORONTO) {
  .c-nav-accordion {
    &__panel {
      padding-top: 0;
      padding-bottom: 0;
      border-top: none;
      border-bottom: none;

      .c-multi-level-nav-btn {
        &__link {
          padding-top: 32px;
        }

        &__one-line {
          font-family: 'Barlow Regular', sans-serif;
          font-size: 32px;
          font-weight: 400;
          line-height: 40px;
        }
      }
    }

    &__item {
      padding: 16px 0;
      &:first-child {
        padding-top: 32px;
      }
    }

    &__button {
      justify-content: flex-start;
      align-items: center;
      .c-multi-level-nav-btn {
        flex-grow: initial;
      }
    }

    &__expand-icon,
    &__collapse-icon {
      position: initial;
      margin-top: 7px;

      i {
        font-size: 32px;
      }
    }
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-nav-accordion {
    &__panel {
      padding-top: 0;
      padding-bottom: 0;
      border-top: none;
      border-bottom: none;

      .c-multi-level-nav-btn {
        &__link {
          padding-top: 32px;
        }

        &__one-line {
          font-family: 'Gibson', sans-serif;
          font-size: 32px;
          font-weight: 300;
          line-height: 40px;
        }
      }
    }

    &__item {
      padding: 16px 0;
      &:first-child {
        padding-top: 32px;
      }
    }

    &__button {
      justify-content: flex-start;
      align-items: center;
      .c-multi-level-nav-btn {
        flex-grow: initial;
      }
    }

    &__expand-icon,
    &__collapse-icon {
      position: initial;

      i {
        font-size: 32px;
      }
    }
  }
}
