@import '../../../../styles/shared';

.c-main-contact-details-form {
  padding: 60px 0;

  &__submit-panel {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;

    > * {
      flex-grow: 1;
    }

    > *:last-child {
      @include text-align(right);
    }

    a {
      font-size: 14px;
      line-height: 19px;
      color: #092864;
    }
  }

  &__marketing {
    margin-top: 20px;
    padding: 5px 0;
    font-size: 14px;
    @include text-align(left);
    color: #333;

    &-variants {
      margin: 10px 0;

      .c-billing-form-check {
        position: relative;
        display: inline-block;
        @include margin-right(20px);
        margin-top: 0;
      }
    }

    .c-billing-form-check {
      position: relative;

      label {
        display: block;
      }

      input {
        position: absolute;
        top: 6px;
        @include left(0);
        width: 12px;
        height: auto;
      }

      .label {
        position: relative;
        top: 1px;
        @include margin-left(15px);
        font-size: 14px;
        font-weight: 300;
        color: #333;
      }
    }

    b {
      font-weight: bold;
    }

    a {
      color: #333;
    }
  }

  .c-billing-form__heading {
    margin-bottom: 20px;
  }
}
