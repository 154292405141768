@import '../../../styles/shared';

$shadow: 0 2px 2px rgba(0, 0, 0, 0.5);

.portfolio-hero {
  background: #000 no-repeat center center;
  background-size: cover;
  overflow: hidden;
  padding-top: 35px;
  color: #fff;

  .container {
    @include mq($from: tablet) {
      padding: 0 10px;
    }

    @include mq($from: $large-desktop-breakpoint + 1) {
      padding: 0;
    }
  }

  &__heading {
    margin-bottom: 60px;

    @include mq($until: tablet) {
      margin-bottom: $gutter-width;
    }

    &-name {
      position: relative;
      margin-bottom: 28px;
      line-height: 1;
      font-size: 14px;
      text-transform: uppercase;
      text-shadow: $shadow;

      &:after {
        content: '';
        position: absolute;
        @include left(0);
        width: 20px;
        height: 3px;
        bottom: -10px;
        box-shadow: $shadow;
        background: #fff;
      }
    }

    &-title {
      font-family: 'Noto Sans', sans-serif;
      font-size: 44px;
      font-weight: bold;
      line-height: 1;
      text-shadow: $shadow;
      margin-bottom: 20px;
    }

    &-subtitle {
      font-size: 18px;
      font-weight: 300;
      text-shadow: $shadow;
    }
  }

  &__tile {
    height: calc(100% - 20px);
    @include padding(20px, 0, 20px, 15px);
    margin-bottom: $gutter-width;
    @include border-left(5px solid #fff);
    background-color: rgba(0, 0, 0, 0.7);

    @include mq($until: tablet) {
      height: auto;
      @include border-left(none);
      margin: 0 (-$gutter-width) 1px;
    }

    &-large {
      font-size: 42px;
      font-weight: 300;
      line-height: 1;

      @include mq($until: tablet) {
        font-size: 32px;
      }
    }

    &-small {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__banner {
    padding: $gutter-width;
    margin: 60px (-$gutter-width) 0;
    background-color: rgba(0, 0, 0, 0.7);

    @include mq($until: tablet) {
      margin-top: 0;
    }

    &-text {
      font-size: 22px;
      font-weight: 300;
      color: #fff;

      @include mq($until: tablet) {
        font-size: 18px;
      }
    }

    &-cta {
      font-size: 14px;
      font-weight: 600;
      padding: 10px 15px 10px 15px;
      text-transform: uppercase;
      background-color: #7014cb;
      border-color: #7014cb;
      color: #fff;

      @include mq($until: tablet) {
        font-size: 26px;
        margin-top: $gutter-width;
      }

      &:hover {
        background-color: #7014cb !important;
        border-color: #7014cb !important;
      }
    }
  }
}
