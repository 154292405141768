@import '../../../../../styles/shared';

.footer-lower {
  direction: ltr;
  padding: 20px 0;
  color: #fff;
  background-color: $tenant-secondary-color;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq($until: desktop) {
      align-items: flex-start;
    }

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__logo {
    padding-top: 15px;

    @include mq($until: tablet) {
      padding-top: 25px;
      order: 2;
    }
  }
}

@include one-of-themes(
  [$FANEXPOHQ,
  $ONEOFAKIND,
  $NAJAHSTACK,
  $INTERIORDESIGN,
  $ARTTORONTO,
  $THEARTISTPROJECT,
  $MEFCC]
) {
  .footer-lower {
    &__wrapper {
      @include mq($until: desktop) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__logo {
      padding: 0;

      @include mq($until: desktop) {
        margin-bottom: 20px;
      }

      @include mq($until: tablet) {
        order: 0;
      }
    }
  }
}
