@import '../../../styles/shared';

.c-heading-section {
  padding: 60px 0 40px;
  color: #000;

  &--branded {
    color: #fff;
  }

  &__title {
    font-weight: 700;
    font-size: 52px;
    line-height: 1;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__title + &__text {
    margin-top: 40px;
  }
}

@include theme-only($FANEXPOHQ) {
  .c-heading-section {
    padding: 40px 0;

    &__title {
      font-family: 'Proxima Nova ExCn Rg', sans-serif;
      font-size: 44px;
      font-weight: bold;
      line-height: 38px;
    }

    &__text {
      font-size: 16px;
      line-height: 21px;
    }

    &__title + &__text {
      margin-top: 25px;
    }
  }
}

@include theme-only($MEFCC) {
  .c-heading-section {
    padding: 40px 0;

    &__title {
      font-family: 'Barlow Condensed Regular', sans-serif;
      font-size: 44px;
      font-weight: bold;
      line-height: 38px;
    }

    &__text {
      font-size: 16px;
      line-height: 21px;
    }

    &__title + &__text {
      margin-top: 25px;
    }
  }
}

@include theme-only($ASIATECHXSG) {
  .c-heading-section {
    &--branded {
      color: $body-color;
    }

    &__title {
      font-family: 'Noto Sans', sans-serif;
      font-size: 38px;
      text-transform: none;
      line-height: 64px;
    }
  }
}

@include theme-only($ONEOFAKIND) {
  .c-heading-section {
    padding: 90px 0 70px;
    background-color: #f9f1e1 !important;

    &__title {
      font-family: 'Berlingske Serif', sans-serif;
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      text-transform: none;
    }

    &--branded {
      color: $body-color;
    }
  }
}

@include theme-only($NAJAHSTACK) {
  .c-heading-section {
    padding: 90px 0 70px;
    background-color: #fff !important;

    &__title {
      font-family: 'Proxima Nova Regular', sans-serif;
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      text-transform: none;
    }

    &__text {
      font-family: 'Proxima Nova Regular', sans-serif;
    }

    &--branded {
      color: $body-color;
    }
  }
}

@include theme-only($THEARTISTPROJECT) {
  .c-heading-section {
    background-color: #eaeaea !important;

    &__title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 40px;
      font-weight: 300;
      line-height: 46px;
      text-transform: none;
    }

    &--branded {
      color: $body-color;
    }
  }
}

@include theme-only($ARTTORONTO) {
  .c-heading-section {
    background-color: #eaeaea !important;

    &__title {
      font-family: 'Barlow Regular', sans-serif;
      font-size: 40px;
      font-weight: 400;
      line-height: 46px;
      text-transform: none;
    }

    &--branded {
      color: $body-color;
    }
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-heading-section {
    background-color: #eaeaea !important;

    &__title {
      font-family: 'Gibson SemiBold', sans-serif;
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      text-transform: none;
    }

    &--branded {
      color: $body-color;
    }
  }
}
