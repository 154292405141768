@import '../../../../../styles/shared';

.c-search-content-header {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  padding: 18px 0 24px 0;

  @include mq($until: tablet) {
    flex-direction: column;
    padding: 10px;
  }

  &__name {
    height: 48px;
    color: $tenant-primary-color;
    font-family: $font-family-serif;
    font-size: 36px;
    line-height: 48px;
    margin-right: 28px;

    @include mq($until: tablet) {
      font-size: 26px;
      margin-right: 14px;
    }
  }

  &__result-wrapper {
    display: flex;
    color: $tenant-accent-color;
    font-family: $font-family-sans-serif;
    font-size: 20px;
    line-height: 48px;

    @include mq($until: tablet) {
      font-size: 16px;
    }
  }

  &__result-info {
    color: $tenant-primary-color;
    margin-right: 20px;
  }

  &__view-all {
    color: $tenant-accent-color;
    text-decoration: underline;
    cursor: pointer;
  }

  &__preview-unavailable {
    color: $tenant-primary-color;
    margin-right: 20px;

    @include mq($until: tablet) {
      display: flex;
      flex-direction: column;
    }
  }

  &__preview-unavailable-text {
    margin-right: 28px;

    @include mq($until: tablet) {
      margin-right: 14px;
    }
  }
}
