@import '../../../styles/shared';

.c-coLo-agenda-filters {
  background-color: #fff;
  padding-bottom: 20px;

  &__container {
    overflow-y: auto;
  }

  &--sticky {
    position: fixed;
    z-index: 6;
    width: 16.66666667%;

    @include mq($until: desktop) {
      width: 33.33333333%;
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  &--boundary {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 5;
  }

  @include mq($until: tablet) {
    margin-top: 80px;
  }
}
