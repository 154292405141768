@import '../../../styles/shared';

.c-redux-form-wrapper {
  .form-control {
    max-width: 668.33px; // 7 columns

    ~ .form-control {
      margin-top: 16px;
    }
  }

  .form-label {
    display: block;
    margin-bottom: 11px;
    font-size: 16px;
    line-height: 19px;
    color: #3a3a3a;
  }

  .form-fake-field {
    display: block;
    vertical-align: middle;
    height: 44px;
    font-size: 16px;
    font-weight: 300;
    line-height: 44px;
  }

  .form-error {
    margin-top: 10px;
    margin-bottom: 10px;
    color: $form-error-color;
  }

  .form-submit {
    margin: 31px 0 0;

    &--no-margin {
      margin: 0;
    }
  }

  .form-anchor {
    margin-top: 5px;

    a {
      font-size: 14px;
      line-height: 16px;
      color: #666666;
    }

    a + a {
      @include margin-left(10px);
    }
  }

  .form-alt-proposition {
    display: inline-block;
    margin-top: 10px;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
    color: #3a3a3a;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    a {
      text-decoration: underline;
      color: inherit;
    }

    @include mq($from: tablet) {
      margin-top: 0;
      @include margin-left(25px);
    }
  }
}
