@import '../../../styles/shared';

.c-sign-in-with-proposition {
  margin: 60px 0;

  & > .container {
    padding: 0;
  }

  .c-form-block {
    border: none;
    margin: 0;
  }

  &__block {
    border: 1px solid $form-border-color;
    background: #fff;

    & > .row > .col-xs-12 {
      &:first-child {
        @include mq($from: desktop) {
          @include padding-right(0);
          @include border-right(1px solid $form-border-color);
        }
      }

      &:last-child {
        @include mq($from: desktop) {
          @include padding-left(0);
        }
      }
    }
  }
}
