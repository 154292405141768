@import '../../../../styles/shared';

.package-selection {
  padding-right: 20px;

  &__ticket-number {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__product-name {
    margin-bottom: 12px;
  }

  &__option-name {
    font-size: 12px;
    font-weight: 300;
  }

  &__carbon-name {
    margin-top: 15px;
  }
}
