@import '../../../styles/shared';

.c-sign-in-form {
  margin: 0;

  .c-form-field {
    &:-ms-input-placeholder {
      color: #cccccc !important;
    }
  }

  &.with-proposition {
    .c-form-block__header,
    .c-form-block__body {
      padding-left: 45px;
      padding-right: 45px;
    }

    .c-form-block__header {
      border-bottom: none;
      padding-bottom: 0;

      .c-form-block__heading {
        font-size: 30px;
      }
    }
  }

  .c-input-label {
    color: #333;
  }

  .c-button--submit:not(.c-button--accent) {
    background-color: #31b03f;
    color: #fff;
    text-transform: capitalize;
    border: none;

    &:hover {
      background-color: #268830;
    }
  }

  &__forgot-link {
    display: inline-block;
    font-size: 14px;
    text-decoration: none;

    @include mq($until: tablet) {
      display: block;
      @include text-align(left);
      margin: 20px 0 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .c-button__loader {
    width: 14px;
    height: 14px;

    & div {
      width: 14px;
      height: 14px;
      border-width: 3px;
    }
  }
}

@include theme-only($DEFAULT) {
  .c-sign-in-form {
    .c-form-heading {
      font-family: $font-family-serif;
      font-size: 32px;
      font-weight: normal;
      line-height: 48px;
      color: #022244;
    }

    .c-sign-up-form__paragraph {
      color: #333333;
      font-size: 16px;
      line-height: 26px;
    }

    .c-input-label {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }

    .form-row {
      margin-top: 12px;

      &:first-of-type {
        margin-top: 0;
      }
    }
    .c-form-field {
      height: 40px;
      font-family: 'Open Sans', serif;
      font-weight: 600;
      color: rgb(51, 51, 51);

      &.select {
        color: #999;
      }

      &.error {
        @include padding-left(10px);
      }

      &:-ms-input-placeholder {
        color: #cccccc !important;
      }

      &.password {
        &::-ms-clear,
        &::-ms-reveal {
          display: none;
        }
      }
    }

    .c-sign-in-form__forgot-link {
      color: #cc31c2;
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      margin-top: 6px;
      margin-bottom: 26px;
    }

    .ic-submit-button {
      outline: none;
      border: none;
      display: block;
      background-color: #cc31c2;
      color: white;
      width: 150px;
      height: 40px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      font-family: $font-family-serif;
      text-decoration: none;
      border-radius: 20px;
      padding: 10px;

      &:hover {
        background-color: #cc31c2;
      }
    }

    .c-form-helper {
      color: #333333;
      font-size: 14px;
      line-height: 26px;
    }

    .c-form .form-row {
      margin-bottom: 0;
    }

    .show-password-btn {
      @include border-left(none);
      bottom: 2px;
      color: #333333;
      font-size: 14px;
      line-height: 46px;
    }

    .c-button.c-button--accent {
      width: 200px;
    }
  }
}
