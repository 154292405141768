@import '../../../../styles/shared';

.c-total-price-box {
  &__heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 43px;
    margin: 30px 0 10px;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__basket {
    position: relative;
    padding-bottom: 52px;

    @include mq($until: tablet) {
      padding-bottom: 42px;
    }

    & .row:not(:last-child) {
      padding-bottom: 20px;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[disabled] {
      border: none;
    }

    &-quantity {
      width: 45px;
      height: 28px;
      padding: 0 5px;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid $form-border-color;
      text-align: center;
      color: #333;
      -moz-appearance: textfield;
    }

    &-saving-message {
      margin-top: 3px;
      font-size: 12px;
      font-weight: 300;
      color: #999999;
    }

    &-submit {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      border-radius: 0 0 3px 3px;
    }
  }
}
