@import '../../../../../../styles/shared';

.c-multi-level-nav-menu {
  position: relative;

  &__desktop {
    @include mq($until: desktop) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @include mq($until: desktop) {
      display: block;
    }
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: -6px;
    left: 0;
    height: 6px;
    content: '';
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &__body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: $extended-layout-width;
    margin: 0 auto;

    @include mq($until: tablet) {
      flex-wrap: wrap;
    }

    @include mq($until: desktop) {
      position: relative;
      min-height: $multi-level-nav-level-1-height-mobile;
    }
  }

  &--fullscreen &__body {
    max-width: none;
  }

  &__body-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: hidden;

    @include mq($until: desktop) {
      @include padding-left($gutter-width);
    }
  }

  &__body-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0 $gutter-width;

    &:has(&__cover-shadow) {
      padding: 0 $gutter-width 0 0;
    }

    @include mq($from: desktop) {
      position: relative;
    }

    @include nav-height;
  }

  &__cover-shadow {
    @include mq($from: desktop) {
      position: absolute;
      left: 0;
      content: '';
      display: block;
      width: 50px;
      height: 100%;
      margin-left: -50px;
      background: linear-gradient(
        90deg,
        transparent,
        var(--cover-shadow-color) 80%
      );
    }
  }

  &__magnifying-glass-search {
    &:has(.open) {
      @include mq($until: tablet) {
        position: absolute;
        top: $multi-level-nav-level-1-height-mobile;
        left: 0;
        right: 0;
        padding: 5px $gutter-width;
      }
    }
  }

  &__body-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $multi-level-nav-level-1-height-mobile;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    @include mq($until: tablet) {
      min-width: 100%;
      padding: 0 $gutter-width;
    }
  }

  &__secondary-cta {
    padding: 0 0;
    background: none;
    border: none;
    box-shadow: none;

    &:hover,
    &:active,
    &:visited {
      background: none;
      border: none;
      box-shadow: none;
    }
  }

  &__body--hamburger-menu-only &__book-now-mobile {
    body:not(.multi-level-nav-opened) & {
      display: none;
    }
  }

  &__body--lead-gen-gate {
    .c-multi-level-nav-home-btn {
      width: 100%;
      @include margin-left(0);
      @include text-align(left);
      border-bottom-color: #fff;

      &__icon {
        display: none;
      }

      &__text {
        display: initial;
      }
    }
  }

  &__link {
    display: block;
    font-size: 0;

    @include mq($from: tablet) {
      display: none;
    }
  }

  &__logo {
    height: 30px;
  }
}

@include theme-only($DEFAULT) {
  .c-multi-level-nav-menu {
    &__book-now {
      font-size: 22px !important;
      padding: 13px 30px 9px !important;

      &::after {
        top: -2px !important;
      }
    }
  }
}

@include one-of-themes-except([$DEFAULT, $FANEXPOHQ, $MEFCC]) {
  .c-multi-level-nav-menu {
    &__book-now--large {
      padding: 8px 18px 9px !important;
      font-size: 32px;
    }
  }
}

@include theme-only($FANEXPOHQ) {
  .c-multi-level-nav-menu {
    position: initial;

    &::after {
      display: none;
    }

    &__book-now {
      padding: 4px 6px 1px;
      font-size: 19px;

      &:hover {
        background-color: #fff !important;
        border-color: #000 !important;
        color: #000 !important;
      }
    }
  }
}

@include theme-only($MEFCC) {
  .c-multi-level-nav-menu {
    position: initial;

    &::after {
      display: none;
    }

    &__book-now {
      font-size: 19px;
      padding: 2px 6px;

      &:hover {
        background-color: #fff !important;
        border-color: #000 !important;
        color: #000 !important;
      }
    }
  }
}

@include theme-only($ASIATECHXSG) {
  .c-multi-level-nav-menu {
    &__book-now {
      padding: 18px 35px 16px;
    }
  }
}

@include one-of-themes([$ONEOFAKIND, $NAJAHSTACK]) {
  .c-multi-level-nav-menu {
    &::after {
      display: none;
    }

    &__book-now--large {
      font-size: 22px;
      padding: 8px 16px;
    }
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-multi-level-nav-menu {
    &::after {
      display: none;
    }

    &__book-now--large {
      font-size: 18px;
      padding: 11px 28px;
    }
  }
}

@include one-of-themes([$ARTTORONTO, $THEARTISTPROJECT]) {
  .c-multi-level-nav-menu {
    &::after {
      display: none;
    }

    &__book-now--large {
      font-size: 22px;
      padding: 10px 16px 12px;
    }

    &__desktop {
      @include mq($until: $desktop-breakpoint) {
        display: none;
      }
    }

    &__mobile {
      display: none;

      @include mq($until: $desktop-breakpoint) {
        display: block;
      }
    }

    &__body-left {
      @include mq($until: $desktop-breakpoint) {
        @include padding-left($gutter-width);
      }
    }
  }
}
