@import '../../../styles/shared';

.c-icon-badge {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  //@include left(0);
  left: 0;

  .material-icons {
    font-size: 28px;
    color: #333;
  }

  &--round {
    border-radius: 50%;
  }

  &--portrait {
    position: relative;
    bottom: initial;
    //@include float(left);
    //@include margin(0, 20px, 0, 0);
    float: left;
    margin: 0 20px 0 0;
    cursor: pointer;

    @include mq($from: tablet) {
      position: absolute;
      bottom: 0;
      margin: 0;
    }
  }

  &--portrait-no-image {
    position: relative;
    //@include margin-right(20px);
    margin-right: 20px;
  }

  &--audio {
    margin-bottom: 15px;
  }

  &.c-file-download-section__icon {
    @include mq($until: tablet) {
      display: flex !important;
    }

    bottom: initial;
  }
}
