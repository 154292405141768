@import '../../../styles/shared';

.c-person-footer-section {
  @include theme-only($ASIATECHXSG) {
    color: $body-color;
  }

  .c-event-section__course-cta {
    margin-top: 0;
  }

  .c-cta + .c-cta {
    margin-top: 10px;

    @include mq($from: tablet) {
      @include margin-left(10px);
    }
  }
}
