@import '../../../styles/shared';

.c-profile-form {
  margin: 20px 0;

  .c-form-block {
    border: none;

    &__body {
      padding: 0;

      .c-input-label {
        color: #333;
      }
    }
  }

  &__buttons-row {
    margin-top: 35px;

    a {
      color: #333;
    }
  }
}
