@import '../../../../styles/shared';

.c-co-lo-agenda-grid-header {
  display: flex;
  position: relative;
  background-color: #efefef;
  z-index: 3;

  &--fixed {
    position: fixed;
  }

  &--relative {
    position: relative;
    top: 0;
  }
}
