@import '../../../../../styles/shared';

.c-payment-journey-input {
  padding: 0 5px;

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
  }

  &__label-inner-wrapper {
    @include margin-right(10px);
    line-height: 0;
  }

  &--hidden * {
    margin: 0;
    padding: 0;
  }

  &--error {
    background-color: #ffe1e3;
  }

  select,
  textarea,
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='number'] {
    width: 100%;
    height: 30px;
    padding: 5px 0;
    font-size: 12px;
    color: #000 !important;
    background-color: transparent !important;
    border: 0;
    outline: none;
    -webkit-appearance: none;
  }

  textarea {
    min-height: 38px;
    resize: vertical;
  }

  select:not(:disabled) {
    cursor: pointer;
  }
}

.display-inline-fields {
  .c-payment-journey-check-input {
    display: inline-block;
    @include margin-right(15px);
  }
}
