@import '../../../../../../styles/shared';

.c-search-content-page {
  width: 100%;
  margin-bottom: 20px;
  text-decoration: none;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-family: $font-family-sans-serif;

  &__default {
    background-color: #f5f5f5;
    height: 24px;
    margin-bottom: 20px;

    &:nth-child(1) {
      width: 100%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &--preview-mode {
    &:last-child {
      margin-bottom: 0;
    }

    @include mq($until: tablet) {
      min-width: 226px;
      margin: 0 20px 0 0;
      &:last-child,
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }

  &--view-mode {
    &:last-child {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    padding-bottom: 10px;
    text-decoration: none;
    color: $tenant-search-text-color;
    display: block;
  }

  &__url {
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    padding-bottom: 16px;
    word-break: break-all;
    color: $tenant-search-text-color;
    display: block;
  }

  &__match-text {
    color: $tenant-search-text-color;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 15px;
    display: inline-block;
    text-decoration: none;

    em {
      background-color: rgba(204, 49, 194, 0.2);
      color: #000;
    }
  }

  &__name {
    word-break: break-all;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: $tenant-search-text-color;

    &-url {
      text-decoration: underline;
      margin-right: 5px;
    }
  }
}
