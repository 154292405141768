@import '../../../styles/shared';

.c-virtual-event-proposition {
  a {
    color: inherit;
  }

  &__loader {
    & > [class$='-loader'] {
      transform: scale(0.2);
    }
  }

  &__access-title {
    display: inline-block;
    color: #333;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-weight: 800;
    font-size: 25px;
    line-height: 44px;
    text-transform: uppercase;
    margin-right: 38px;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include mq($until: tablet) {
      margin: 0 0 15px 0;
    }
  }

  &__btn-login {
    @include margin-right(20px);
    @include mq($until: tablet) {
      margin: 0 0 20px 0;
    }

    &--with-paddings {
      padding-left: 70px;
      padding-right: 70px;
    }

    &--no-booking {
      @include mq($until: tablet) {
        margin: 0;
      }
    }
  }

  &__btn-book-now--with-paddings {
    padding-left: 50px;
    padding-right: 50px;
  }

  &__login-form {
    margin-top: 30px;
  }
}
