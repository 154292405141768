@import '../../../styles/shared';

.c-speakers-table {
  @include mq($until: tablet) {
    margin-top: $gutter-width;
  }
}

// Sorry for that fix, but I cannot fix it because of a code shit!
@include mq($until: tablet) {
  .l-site--speakers {
    .c-sidebar-filter__content-input {
      margin-left: -$gutter-width;
      margin-right: -$gutter-width;

      i {
        position: relative;
        top: 0 !important;
        @include right(35px !important);
        font-size: 24px !important;
      }
    }
  }
}
