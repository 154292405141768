@import '../../../styles/shared';

.c-industry-hero {
  min-height: 100px;
  background-size: cover;
  background-position: center top;
  background-color: $tenant-primary-color;

  @include mq($from: tablet) {
    min-height: 270px;
  }

  @include mq($from: wide) {
    min-height: 365px;
  }
}
