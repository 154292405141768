@import '../../../../../../styles/shared';

.c-product-popup {
  &__header {
    position: relative;
    height: 60px;
    padding: 20px;
    background: #333;
    vertical-align: middle;
    @include text-align(right);
    color: #fff;
  }

  &__body {
    padding: 30px 20px;
    background: #fff;
  }

  &__heading {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.2;
    margin: 0 0 20px 0;
  }

  &__close {
    position: absolute;
    @include right(20px);
    top: 13px;
    font-size: 46px;
    font-weight: 300;
    line-height: 26px;
    cursor: pointer;
    color: #fff;

    span {
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
      vertical-align: middle;
      @include margin-left(5px);
    }
  }
}
