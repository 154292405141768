.c-co-lo-agenda-toggle-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-weight: 400;
  cursor: pointer;

  &__text {
    font-size: 14px;
  }

  &__icon {
    font-size: 20px;
  }
}
