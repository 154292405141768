@import '../../../../../styles/shared';

.c-article-download {
  padding: 9px 35px 7px 45px;
  font-family: $font-family-serif;
  font-size: 18px;
  font-weight: bold;
  border-radius: 20px;
  border: 1px solid $tenant-accent-color;
  background-color: $tenant-accent-color;
  transition: border-color 0.2s ease;
  color: #ffffff;
  text-decoration: none;

  &__icon {
    font-size: 19px;
    vertical-align: middle;
    margin-left: 7px;
  }
}
