@import '../../../../styles/shared';

.v-profile-view {
  flex: 1 1 auto;
  padding: 40px 0;
  font-family: 'Open Sans', sans-serif;
  color: #333;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;

  h2 {
    font-family: 'Noto Sans', sans-serif;
    font-size: 30px;
    font-weight: 900;
    text-transform: none;
  }
}
