@import '../../../styles/shared';

.search-content-container {
  height: 100%;
  position: relative;

  &__header {
    padding: 20px;
    background: #f5f5f5;
    &-filters-btn {
      @include mq($from: desktop) {
        display: none;
      }
    }
  }

  &__filters {
    padding: 20px 0;
    @include mq($from: desktop) {
      display: none;
    }

    @include mq($until: tablet) {
      padding: 0;
    }
  }
}
