@import '../../../../styles/shared';

.c-audience-page-listing {
  border-bottom: 40px solid #f5f5f5;
  background-color: #f5f5f5;

  .c-audience-section {
    border: none;
  }

  &__load-more-button {
    position: relative;
    height: 120px;
  }
}
