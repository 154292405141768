@import '../../../../styles/shared';

.c-co-lo-agenda-session-highlights {
  &__title {
    font-size: 15px;
    line-height: 17px;
    font-weight: 300;
  }

  &__description {
    font-size: 13px;
    line-height: 18px;
    margin-top: 7px;
    overflow: hidden;

    p {
      margin: 0;
    }

    ul {
      @include padding-left(20px);
      margin: 0;
      li {
        margin: 0;
      }
    }
  }

  &__speakers {
    margin: 0;
    background-color: transparent;
    color: #999999;
  }

  &__description,
  &__title,
  &__speakers {
    mark {
      font-style: normal;
      background-color: #f5ca39;
    }
  }
}
