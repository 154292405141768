@import '../../../../styles/shared';

.c-co-lo-agenda-session-description {
  font-size: 13px;
  margin-top: 7px;
  overflow: hidden;

  p {
    margin: 0;
  }

  ul {
    @include padding-left(20px);
    margin: 0;
    li {
      margin: 0;
    }
  }
}
