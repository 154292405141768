@import '../../../styles/shared';

.c-search-form-section {
  padding: 40px 0 60px;
  background-color: #f5f5f5;

  @include mq($until: tablet) {
    padding: 30px 0;
  }

  .container {
    max-width: $extended-layout-width;
  }

  &__title {
    margin-bottom: 10px;
    font-family: $font-family-serif;
    font-size: 32px;
    line-height: 38px;
    color: $tenant-primary-color;

    @include mq($until: tablet) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}

.c-search-form-section {
  .c-search-form-widget {
    &__proposition {
      color: #333;
    }

    &__link {
      color: $tenant-accent-color;
    }
  }
}
