.l-hub {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #333;
  background-color: #efefef;

  &-sticky {
    position: relative;
    z-index: 1000;
  }

  &__top {
  }

  &__bottom {
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 0;
  }
}
