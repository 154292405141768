@import '../../styles/shared';

.lead-gen-container {
  padding: 30px 0 0 0;

  &.article-container {
    padding: 33px 0 0 0;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    &--empty-teaser {
      justify-content: center;
    }
  }

  &__teaser {
    &--empty {
      display: none;
    }
  }

  &__teaser-row {
    background: white;
    .lead-gen-teaser {
      padding: 0 10px;
    }
  }

  &__switcher-row {
    background: #f3f3f3;
    padding: 0 10px;
  }

  &__update-row {
    background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 300px);

    &.article-container {
      background: #ffffff;

      .update-title-container {
        background: #f5f5f5;
      }
      .update-title {
        font-family: 'Open Sans', sans-serif;
        text-transform: none;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        color: #002244;
        padding: 25px 0;
      }
    }

    .update-title {
      font-size: 32px;
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      font-weight: 800;
      line-height: 44px;
      text-transform: uppercase;
      color: #333333;
    }
    .update-form-container,
    .update-title-container {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  @include mq($from: tablet) {
    padding: 60px 0 0 0;
  }
}
