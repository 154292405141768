@import '../../../../../../../styles/shared';

$verticals-max-width: 330px;
$verticals-max-height: 480px;

.verticals-list {
  min-width: $verticals-max-width;
  background: #586876;
  min-height: $verticals-max-height;

  @include mq($until: tablet) {
    width: 100%;
    min-width: $verticals-max-width - 10px;
    display: table;
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;

    &-title {
      position: relative;
      display: flex;
      flex-grow: 1;
      padding: 10px 17px;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.6;
      border-left: 3px solid transparent;
      cursor: pointer;
    }

    &:hover:not(&--active) {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &--active {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &-icon.material-icons {
      display: flex;
      align-items: center;
      margin-left: 5px;
      font-size: 20px;
    }

    &-button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      font-size: 24px;
      text-decoration: none;
      color: #fff;
      background-color: #542650;
    }
  }

  .hub-menu-border-color-about:hover,
  .hub-menu-border-color-about--active {
    border-color: #542650;
  }

  &__subitems-list-mobile {
    display: none;
    background: #fff;
  }

  .submenu-mobile-appear {
    max-height: 0;
    overflow: hidden;
  }

  .submenu-mobile-appear.submenu-mobile-appear-active {
    max-height: 100vh;
    transition: max-height 500ms ease-in;
  }

  .submenu-mobile-enter {
    max-height: 0;
    overflow: hidden;

    &.submenu-mobile-enter-active {
      max-height: 100vh;
      transition: max-height 500ms ease-in;
    }
  }

  .submenu-mobile-exit {
    max-height: 100vh;
    overflow: hidden;

    &.submenu-mobile-exit-active {
      max-height: 0;
      transition: max-height 500ms ease-in;
    }
  }

  &__subitem-mobile {
    &-link {
      display: block;
      padding: 10px 20px;
      font-family: 'Noto Sans', sans-serif;
      font-size: 18px;
      line-height: 1.1;
      text-decoration: none;
      color: #333;
      cursor: pointer;
    }
  }
}
