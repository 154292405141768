@import '../../../styles/shared';

.lead-gen-confirm-registration {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;

  &__title-type-2 {
    color: #333;
    font-size: 24px;
    font-weight: 300;
    line-height: 28px;
  }

  &__description-type-2 {
    color: #333;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
  }
}
