@import '../../../styles/shared';

.c-select-package-form {
  padding: 60px 0;
  color: #333;
  border-top: 1px solid #aaaaaa;
  position: relative;
  @include mq($until: tablet) {
    padding: 40px 0;
  }

  &__page-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .c-product-type + &__heading {
    margin-top: 30px;
  }

  &__heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 43px;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__page-content {
    h2 {
      font-size: 32px;
      font-weight: 400;
      line-height: 43px;
      margin: 0 0 8px;
    }

    h3 {
      @include mq($until: tablet) {
        font-size: 24px;
      }
    }

    h4 {
      @include mq($until: tablet) {
        font-size: 22px;
      }
    }

    a,
    a:hover,
    a:visited {
      text-decoration: none;
      color: #333;
    }

    a:hover {
      text-decoration: underline;
    }

    @include mq($until: tablet) {
      margin-bottom: $gutter-width;
    }

    &__package-discounts-description {
      .title {
        font-size: 21px;
        font-weight: 700;
        margin: 0 0 30px 0;
        line-height: 1.4;
      }

      .description {
        margin: 0 0 1em 0;
      }
    }
  }

  &__description {
    margin: 8px 0 20px;

    &.has-error {
      color: $form-error-color;
    }
  }
}
