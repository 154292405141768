@import '../../../../styles/shared';

.c-co-lo-agenda-session-badges {
  &__item {
    display: inline-block;
    padding: 1px 5px;
    @include margin-right(5px);
    border-radius: 3px;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: middle;

    &--free {
      line-height: 14px;
      color: #fff;
    }

    &--not-free {
      border-width: 1px;
      border-style: solid;
      line-height: 12px;
      background-color: #fff;
      color: #333;
    }
  }
}

@each $index, $color in $streamColors {
  .c-co-lo-agenda-session-badges[data-color-index='#{$index}'] {
    .c-co-lo-agenda-session-badges__item--not-free {
      color: $color;
      border-color: $color;
    }
  }
}
