@import '../../../styles/shared';

.c-co-lo-agenda-menu-bar-mobile {
  &--sticky {
    position: fixed;
    z-index: 5;
    width: 100%;
  }

  &__wrapper {
    padding: 10px 20px;
    background-color: #fff;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__show-dates,
  &__show-filters {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    font-size: 20px;
  }
}
