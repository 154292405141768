@import '../../../styles/shared';

.c-co-lo-agenda-grid {
  overflow: hidden;
  padding: 0;

  &__content {
    position: relative;
    height: auto;
    border: none;
    box-sizing: content-box;
    top: 0;
    @include left(0);
    margin: 0;
    overflow: scroll;
    width: auto;
    @include padding(20px, 20px, 0, 20px);
    scroll-behavior: smooth;

    &--top-offset {
      @include padding(179px, 20px, 0, 20px);
    }
  }

  &__strip {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 16px;
    height: 100%;
    background: #efefef;
    z-index: 4;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }
}
