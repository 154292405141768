@import '../../../../styles/shared';

$box-padding: 20px;

.c-audience-box {
  position: relative;
  display: block;
  height: 100%;
  padding: $box-padding;
  text-decoration: none;
  border-radius: 0 0 10px 10px;
  border: 1px solid #cccccc;
  background-color: #fff;
  color: #333;

  &:hover {
    color: #333;
  }

  &--large &__wrapper,
  &--medium &__wrapper {
    display: table;
    width: 100%;
    height: 100%;
  }

  &--large &__content,
  &--medium &__content {
    display: table-cell;
    vertical-align: middle;
  }

  &--medium &__content {
    @include mq($from: tablet) {
      padding-left: 20px;
    }
  }

  &--large &__content {
    @include mq($from: tablet) {
      padding-left: 20px;
    }

    @include mq($from: desktop) {
      padding-left: 40px;
    }
  }

  &__image {
    background-size: cover;
    background-position: center;
    background-color: $tenant-primary-color;
    margin: -$box-padding (-$box-padding) $box-padding;
    min-height: 132px;
    position: relative;
  }

  &--small &__image {
    @include mq($from: tablet) {
      min-height: 162px;
    }
  }

  &--medium &__image {
    @include mq($from: tablet) {
      min-height: 210px;
      margin: 0;
    }

    @include mq($from: desktop) {
      min-height: 240px;
    }
  }

  &--medium#{&}--event &__image {
    @include mq($from: tablet) {
      min-height: 210px;
    }
  }

  &--large &__image {
    @include mq($from: tablet) {
      min-height: 210px;
      margin: 0;
    }

    @include mq($from: desktop) {
      min-height: 320px;
    }
  }

  &__topic {
    margin-bottom: 7px;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    color: #4d798d;
  }

  &__title {
    margin-bottom: 10px;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
  }

  &--small &__title {
  }

  &--medium &__title {
    @include mq($from: tablet) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &--large &__title {
    @include mq($from: tablet) {
      font-size: 24px;
      line-height: 30px;
    }

    @include mq($from: desktop) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__description {
    margin-top: 13px;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: #3a3a3a;
  }

  &--small &__description {
  }

  &--medium &__description {
    @include mq($from: tablet) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &--large &__description {
    @include mq($from: tablet) {
      font-size: 16px;
      line-height: 22px;
    }

    @include mq($from: desktop) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__text-wrapper,
  &__date,
  &__location {
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #3a3a3a;
  }

  &--medium &__text-wrapper,
  &--medium &__date,
  &--medium &__location {
    @include mq($from: tablet) {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  &--large &__text-wrapper,
  &--large &__date,
  &--large &__location {
    @include mq($from: tablet) {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    @include mq($from: desktop) {
      font-size: 22px;
      line-height: 20px;
    }
  }

  &__date {
    display: inline;
  }

  &__fake-button {
    display: inline-block;
    padding: 10px 40px 8px;
    margin-top: 30px;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    border-radius: 20px;
    background-color: $tenant-accent-color;
    color: #fff;

    @include mq($until: tablet) {
      display: none;
    }
  }
}
