@import '../../../../../styles/shared';

.c-search-checkbox {
  &__item {
    margin-bottom: 6px;

    &--show {
      display: block;
    }

    &--hide {
      display: none;
    }
  }

  &__input {
    display: none;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      .c-search-checkbox__label-count,
      .c-search-checkbox__label-text {
        color: #cccccc;
      }

      .c-search-checkbox__label-square {
        border: 1px solid #cccccc;
      }
    }
  }

  &__label-count {
    color: $tenant-secondary-color;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    margin-right: 8px;
  }

  &__label-text {
    color: #404041;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__label-square-wrapper {
    margin-right: 10px;
  }

  &__label-square {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #404041;
    position: relative;

    &-tick {
      content: '';
      position: absolute;
      width: 9px;
      height: 5px;
      left: 3px;
      top: 3px;
      transform: rotate(-45deg);
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }

    &--active {
      background-color: #002244;
      border: 1px solid #002244;
    }
  }
}
