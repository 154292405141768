@import '../../../../styles/shared';

.c-audience-hero {
  position: relative;
  padding: 100px 0;
  background-size: cover;
  background-position: center;
  background-color: $tenant-primary-color;
  border-bottom: 1px solid #fff;

  @include mq($until: desktop) {
    padding: 40px 0 30px;
  }

  @include mq($until: tablet) {
    padding: 20px 0;
  }

  &--CONDENSED {
    padding: 40px 0;
  }

  &--SOLID {
    padding: 60px 0;
    background-color: #dbe4e8;

    @include mq($until: desktop) {
      padding: 40px 0 30px;
    }

    @include mq($until: tablet) {
      padding: 20px 0;
    }
  }

  .container {
    max-width: $extended-layout-width;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      #000000 100%
    );
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__solid-wrapper {
    display: table;
    margin: 0 - ($gutter-width * 0.5);
  }

  &__solid-column {
    padding: 0 10px;
    display: table-cell;
    vertical-align: middle;
    width: 50%;

    @include mq($until: desktop) {
      display: block;
      width: 100%;
    }
  }

  &__link {
    height: 100%;
    display: inline-block;
    text-decoration: none;
    color: #fff;
  }

  &__title {
    margin-bottom: 10px;
    font-family: $font-family-serif;
    font-size: 42px;
    line-height: 48px;
    color: #fff;

    @include mq($until: tablet) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  &--CONDENSED &__title {
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 32px;

    @include mq($until: desktop) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }

  &--SOLID &__title {
    color: $tenant-primary-color;
  }

  &__value-proposition {
    font-family: $font-family-sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    color: #fff;

    @include mq($until: desktop) {
      font-size: 28px;
      font-weight: 300;
      line-height: 34px;
    }

    @include mq($until: tablet) {
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
    }
  }

  &--CONDENSED &__value-proposition {
    font-size: 16px;
    font-weight: bold !important;
    line-height: 23px;
  }

  &--SOLID &__value-proposition {
    font-weight: 600;
    color: #333;
  }

  &__sponsor {
    margin-top: 15px;

    @include mq($from: tablet) {
      text-align: right;
      margin-top: 30px;
    }

    @include mq($from: desktop) {
      position: absolute;
      bottom: -100px;
      right: $gutter-width;
    }

    img {
      width: auto;
      max-width: 100%;
      max-height: 64px;

      @include mq($until: tablet) {
        max-height: 50px;
      }

      @include mq($from: desktop) {
        margin-bottom: 30px;
      }
    }
  }

  &--CONDENSED &__sponsor {
    @include mq($from: desktop) {
      bottom: -15px;
      margin-top: 0;
    }

    img {
      vertical-align: middle;

      @include mq($from: desktop) {
        margin-bottom: 0 !important;
      }
    }
  }

  &--SOLID &__sponsor {
    img {
      @include mq($from: desktop) {
        margin-bottom: 60px;
      }
    }
  }
}
