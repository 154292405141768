@import '../../../../styles/shared';

$sponsor-border-color: rgb(239, 239, 239);

.session-sponsors {
  padding: 0 20px;
  @include margin(20px, 10px, 0, 0);

  &__title {
    @include theme-only($ASIATECHXSG) {
      color: $body-color;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &-type {
      display: inline-block;
      margin: 0 0 0 20px;
      padding: 1px 15px;
      font-size: 14px;
      text-transform: uppercase;
      color: #fff;
      border-radius: 10px 10px 0 0;

      @include theme-only($ASIATECHXSG) {
        color: #000 !important;
      }

      &:empty {
        background: none !important;
      }
    }

    &-link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      gap: 20px;
      align-items: flex-start;
      min-height: 132px;
      padding: 26px;
      margin-bottom: $gutter-width;
      border: 1px solid $sponsor-border-color;
      color: #000;
      text-decoration: none;

      @include theme-only($DEFAULT) {
        border-radius: 16px;
      }
    }
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  & a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
