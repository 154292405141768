@import '../../../styles/shared';

.c-select-course-option-form {
  padding: 60px 0;
  color: #333;
  border-top: 1px solid #aaaaaa;

  @include mq($until: tablet) {
    padding: 40px 0;
  }

  &__heading {
    font-size: 40px;
    font-weight: 800;
    line-height: 43px;
    margin-bottom: 20px;

    @include mq($until: tablet) {
      margin-top: 20px;
    }

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: 'Aleo', serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__option {
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 20px 27px 20px 22px;

    &-label {
      font-size: 24px;
      font-weight: 400;
      line-height: 27px;
    }

    &-price {
      font-size: 25px;
      line-height: 27px;
      font-weight: 300;
      @include text-align(right);

      @include mq($until: tablet) {
        @include text-align(left);
        margin-top: 10px;
      }
    }

    &-saving {
      margin-top: 20px;
      b {
        font-weight: bold;
      }
    }
  }

  &__submit {
    width: 100%;
  }

  .clear-basket-btn {
    margin: 20px 0;

    button:hover:not(:disabled) {
      box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.4);
    }
  }

  &__disabled-btn {
    width: 100%;
    background-color: #e5e5e5;
    font-size: 32px;
    padding: 16px 20px 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    text-align: center;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.6);

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: 'Aleo', serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  .expanded-box-content {
    height: auto;
    overflow: hidden;
    margin: 0 -8px 20px;
    padding: 0 8px;

    b {
      font-weight: bold;
    }

    &.collapsed {
      margin: 0;
      height: 0;
    }
  }
}
