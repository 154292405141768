@import '../../../../styles/shared';

.events-grid-item {
  display: flex;
  flex-direction: column;

  &__background,
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;
  }

  &__overlay {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.05) 100%
    );
    opacity: 1;
    z-index: 2;
    transition: opacity 0.3s linear;
  }

  &__link:hover &__overlay {
    opacity: 0.8;
  }

  &__link {
    position: relative;
    display: flex;
    padding: 16px 24px;
    height: 100%;
    min-height: 140px;
    margin-bottom: 20px;
  }

  &__title {
    display: flex;
    align-items: flex-end;
    max-height: 74px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: #333;
  }

  &__date-and-city {
    display: inline-block;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-top: 10px;
    color: #3a3a3a;
  }

  &__value-proposition {
    margin-top: 10px;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    line-height: 24px;
  }
}

.row-direction {
  .events-grid-item {
    @include mq($from: wide) {
      flex-direction: row;

      &__link,
      &__info {
        flex-basis: 50%;
      }

      &__link {
        margin-right: 10px;
      }

      &__info {
        padding-left: 10px;
      }
    }
  }
}
