@import '../../../styles/shared';

$toolbar-size: 36px;

.c-maximise-screen-wrapper {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);

  @include mq($from: tablet) {
    margin-top: 30px;
  }

  &--full-size {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: 0;
    z-index: 1001;
  }

  &__body {
    iframe {
      display: block;
    }
  }

  &__toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: $toolbar-size;
    padding: 0 8px;

    &-left,
    &-right {
      flex-grow: 1;
      display: flex;
    }

    &-left {
      justify-content: flex-start;

      .material-icons {
        @include margin-right(10px);
      }
    }

    &-right {
      justify-content: flex-end;

      .material-icons {
        @include margin-left(10px);
      }
    }
  }

  &:fullscreen &__toolbar--top {
    top: 0;
  }

  &__enter-fullscreen {
    display: inline-block;
  }

  &__exit-fullscreen {
    display: none;
  }

  &:fullscreen &__enter-fullscreen {
    display: none;
  }

  &:fullscreen &__exit-fullscreen {
    display: inline-block;
  }

  &--full-size &__enter-fullscreen {
    display: none;
  }

  &--full-size &__exit-fullscreen {
    display: inline-block;
  }

  &__close {
    display: none;
  }

  &--popup &__close {
    display: inline-block;
  }

  .material-icons {
    padding: 5px;
    transition: all 0.2s;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.c-base-section-wrapper {
  &--iframe-section {
    position: initial;

    .container,
    .container-fluid {
      position: initial;
    }
  }
}
