@import '../../../../styles/shared';

.lead-gen-teaser-landscape {
  &__image {
    position: relative;
    width: 100%;
    max-height: 300px;
    padding-bottom: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__title {
    margin-bottom: 22px;
    font-size: 34px;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-weight: 800;
    line-height: 34px;
    text-transform: uppercase;
    color: #fff;
    background: #333;
    padding: 20px 30px;
    min-height: auto;

    @include mq($from: tablet) {
      margin-bottom: 60px;
    }

    &--white {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      border-bottom: none;
      padding: 25px 30px;
      margin-bottom: 0;
      color: #fff;
      background: #333;
    }
  }

  &__body {
    color: #000;

    @include mq($from: tablet) {
      //@include padding-left(20px);
      padding-left: 20px;
    }

    p {
      line-height: 26px;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }
  }
}
