@import '../../../../../styles/shared';

.c-informa-connect-header {
  min-height: $TENANT-HEADER-HEIGHT;
  font-family: $font-family-sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  background: $tenant-primary-color;

  &__content {
    display: flex;
    flex-direction: row;
    vertical-align: top;
  }

  .brand-logo {
    width: 190px;
    min-width: 190px;
    @include margin-right(40px);

    @include mq($until: tablet) {
      @include margin-right(20px);
    }

    img {
      position: relative;
      top: 10px;
      width: 100%;
      height: auto;
    }
  }

  .browse-topics {
    $self: '.browse-topics';
    width: 160px;
    min-width: 160px;
    @include margin-right(60px);
    margin-top: 21px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    user-select: none;

    @include mq($until: tablet) {
      display: none;
    }

    &__icon {
      position: relative;
      top: -1px;
      @include margin-left(10px);
      font-size: 20px;
      vertical-align: middle;
      color: #fff;

      &--open {
        display: initial;
      }

      &--close {
        display: none;
      }
    }

    &--opened #{$self}__icon--open {
      display: none;
    }

    &--opened #{$self}__icon--close {
      display: initial;
    }
  }

  .nav {
    flex-grow: 1;

    @include mq($until: tablet) {
      display: none;
    }

    &--short {
      flex-grow: initial;
      @include margin-right(40px);
      min-width: 65px;

      @include mq($until: wide) {
        display: inline-block;
      }

      @include mq($until: tablet) {
        display: none;
      }
    }

    ul {
      overflow: hidden;
      height: $TENANT-HEADER-HEIGHT;

      li {
        display: inline-block;

        &:not(:first-child) {
          @include margin-left(18px);

          @include mq($from: 1300px) {
            @include margin-left(28px);
          }

          @include mq($from: $extended-layout-width) {
            @include margin-left(40px);
          }
        }
      }
    }

    &__link {
      height: $TENANT-HEADER-HEIGHT;
      padding-top: 2px;
      display: table-cell;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      vertical-align: middle;
      text-decoration: none;
      color: #fff;
    }
  }

  .account-widget {
    flex-grow: 1;
    position: relative;
    @include text-align(right);

    &.update-alert {
      min-width: 130px;
      flex-grow: initial;
    }

    &--short {
      min-width: 80px;
    }

    @include mq($until: desktop) {
      display: none;
    }

    @include mq($from: desktop) {
      max-width: calc(100% - 850px);

      &.update-alert {
        max-width: calc(100% - 1280px);
      }
    }

    @include mq($from: 1300px) {
      max-width: calc(100% - 950px);

      &.update-alert {
        max-width: calc(100% - 1380px);
      }
    }

    &__link {
      display: inline-block;
      margin-top: 16px;
      padding: 5px 15px 3px;
      font-family: $font-family-serif;
      font-size: 14px;
      font-weight: bold;
      border: 1px solid #fff;
      border-radius: 20px;
      text-decoration: none;
      color: #fff;

      &--primary {
        border-color: $tenant-accent-color;
        background: $tenant-accent-color;
      }

      &:not(:first-child) {
        @include margin-left(10px);
      }
    }
  }

  .sidebar-toggle {
    $self: '.sidebar-toggle';
    flex: 1;
    margin-top: 10px;
    @include text-align(right);
    z-index: 100;
    vertical-align: top;
    user-select: none;
    cursor: pointer;

    &__icon {
      display: inline-block;
      width: 26px;
      position: relative;
      top: 9px;
      font-size: 42px;
      color: #fff;

      &--burger {
        span {
          display: block;
          height: 2px;
          border-radius: 2px;
          background-color: #fff;

          &:not(:first-child) {
            margin-top: 7px;
          }
        }
      }

      &--cross {
        position: relative;
        width: 27px;
        top: 3px;

        span {
          position: relative;
          display: block;
          height: 2px;
          border-radius: 2px;
          background-color: #fff;

          &:first-child {
            transform: rotate(45deg);
            top: 2px;
          }

          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }

      &--open {
        display: inline-block;
      }

      &--close {
        display: none;
      }
    }

    &--opened #{$self}__icon--open {
      display: none;
    }

    &--opened #{$self}__icon--close {
      display: inline-block;
    }
  }

  .search-bar {
    position: relative;
    padding-top: 16px;
    @include margin-left(20px);
    min-width: 29px;

    @include mq($from: desktop) {
      min-width: 315px;
    }

    &--mobile {
      user-select: none;
    }

    &__input {
      width: 100%;
      height: 29px;
      padding: 0 35px 0 15px;
      font-size: 14px;
      border: 1px solid #fff;
      border-radius: 20px;
      background: #fff;

      &:focus {
        outline: none;
        background: #efefef;
      }

      @include mq($until: desktop) {
        display: none;
      }
    }

    &__icon {
      position: absolute;
      top: 16px;
      @include right(-1px);
      width: 29px;
      height: 29px;
      font-size: 18px;
      text-align: center;
      line-height: 29px;
      border-radius: 29px;
      background: $tenant-accent-color;
      color: #fff;
      cursor: pointer;
      user-select: none;

      @include mq($until: desktop) {
        top: 12px;
        width: 35px;
        height: 35px;
        border-radius: 35px;
        font-size: 21px;
        line-height: 36px;
      }
    }
  }
}

.c-informa-connect-header {
  .c-account-widget {
    padding-top: 0;

    &__button {
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      line-height: $TENANT-HEADER-HEIGHT;
      height: $TENANT-HEADER-HEIGHT;
    }

    &__dropdown {
      top: 60px;
      border-radius: 0;

      a,
      span {
        font-family: $font-family-sans-serif;
        font-weight: 600;
      }
    }
  }

  &.homepage-header {
    @include mq($until: wide) {
      .account-widget {
        display: none;
      }

      .c-informa-connect-header__content .nav {
        visibility: hidden;
      }
    }
  }
}
