@import '../../../../../styles/shared';

.c-co-lo-agenda-checkbox-item {
  padding: 7px 0;

  @include mq($until: tablet) {
    @include padding(15px, 0, 15px, 20px);
    background-color: #efefef;
    border-bottom: 1px solid #dcdcdc;
  }

  &__input {
    display: none;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__label-text {
    color: $body-color;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__label-square-wrapper {
    @include margin-right(17px);
  }

  &__label-square {
    position: relative;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 1px solid #56595b;
    border-radius: 2px;

    &-tick {
      content: '';
      position: absolute;
      width: 12px;
      height: 6px;
      @include left(5px);
      top: 6px;
      [dir='ltr'] & {
        transform: rotate(-45deg);
      }
      [dir='rtl'] & {
        transform: rotate(45deg);
      }
      @include border-left(2px solid #fff);
      border-bottom: 2px solid #fff;
    }

    &--active {
      background-color: $tenant-primary-color;
      border: 1px solid $tenant-primary-color;
    }
  }

  &__label-name {
    word-break: break-word;
  }

  &--disabled {
    .c-co-lo-agenda-checkbox-item {
      &__label {
        cursor: default;
      }

      &__label-text {
        color: #aaaaaa;
      }

      &__label-square {
        background-color: #e5e5e5;
        border-color: #aaaaaa !important;
      }

      &__label-square-tick {
        @include border-left(2px solid #e5e5e5);
        border-bottom: 2px solid #e5e5e5;
      }
    }
  }
}

@each $index, $color in $streamColors {
  .c-co-lo-agenda-checkbox-item__label[data-color-index='#{$index}'] {
    .c-co-lo-agenda-checkbox-item__label-square--active {
      background-color: $color;
      border: 1px solid $color;
    }

    .c-co-lo-agenda-checkbox-item__label-square {
      border: 1px solid $color;
    }
  }
}
