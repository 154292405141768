@import '../../../styles/shared';

.c-payment-journey-message {
  color: #000;

  .c-base-section-title {
    margin-bottom: 20px;
    font-size: 52px;

    @include mq($until: tablet) {
      font-size: 32px;
    }
  }

  .c-base-section-text {
    @include mq($until: tablet) {
      font-size: 16px;
    }
  }
}
