@import '../../../../../styles/shared';

.c-payment-journey-check-input {
  position: relative;

  label {
    @include padding-left(23px);
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
  }

  input[type='radio'],
  input[type='checkbox'] {
    position: absolute;
    top: 4px;
    @include left(0);
    width: 15px;
    height: 15px;
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
  }
}
