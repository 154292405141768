@import '../../../styles/shared';

.c-not-found-section {
  color: #000;

  h2 {
    margin-bottom: 20px;
  }
}

@include one-of-themes-except([$FANEXPOHQ, $MEFCC]) {
  .c-base-section-wrapper--not-found {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-not-found-section {
    h2 {
      font-size: 34px;
    }
  }
}
