@import '../../../../../../../styles/shared';

.events-list {
  &-columns {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;

    @include mq($until: tablet) {
      display: none;
    }

    &__link {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      padding: 10px;
      text-decoration: none;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        margin-top: 10px;
        background-color: #dee0e5;
      }

      &-line {
        position: relative;
        top: -2px;
        display: block;
        height: 2px;
        width: 25px;
        background-color: #542650;
        transition: width 0.3s;
      }

      &:hover &-line {
        width: 100%;
      }
    }

    &__item {
      display: flex;
      background-size: cover;
      background-position: center center;
      border-right: 1px solid #e7e7e7;

      & > ul {
        width: 100%;
      }

      &-header {
        margin: 15px 0;
        font-family: 'Noto Sans', sans-serif;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.1;
        color: #542650;
      }

      &-content {
        font-size: 18px;
        line-height: 1.3;
        color: #333;
        width: 100%;
      }

      &-link {
        margin-top: 15px;

        &-text {
          padding: 0 10px;
          font-size: 16px;
          font-weight: bold;
          text-decoration: none;
          color: #542650;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
