@import '../../../styles/shared';

.c-social-checkout-section {
  > .container {
    padding: 0;
  }

  &__continue-link {
    color: #333;
    font-weight: 700;
    font-size: 26px;
    display: block;
    margin-top: 25px;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }
}
