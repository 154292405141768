@import '../../../styles/shared';

.c-l1-topics {
  padding: 60px 0;
  background-color: #ffffff;

  @include mq($until: tablet) {
    padding: 15px 0 0;
  }

  .container {
    max-width: $extended-layout-width;
  }

  &__title {
    position: relative;
    margin-bottom: 20px;
    font-family: $font-family-serif;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: $tenant-primary-color;

    i {
      display: none;
      position: absolute;
      right: 0;
      top: 4px;
    }

    @include mq($until: tablet) {
      font-size: 22px;
      line-height: 28px;
      text-align: left;
    }
  }

  &__mobile-view {
    padding: 10px $gutter-width;
    background-color: #dbe4e8;

    h4 {
      margin: 15px 0 5px;
      font-family: $font-family-sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #333333;
    }

    ul {
      li {
        & + li {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
