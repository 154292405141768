@import '../../../styles/shared';

.confirm-purchase-form {
  margin: 0 auto;
  @include mq($from: tablet) {
    width: 82%;
  }

  &__title {
    margin: 0 0 27px;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include mq($until: tablet) {
      margin: 0 0 12px;
    }

    &--virtual {
      margin: 0 0 8px;
    }

    &--course {
      font-size: 28px;
      text-transform: initial;
    }
  }

  &__sub-text {
    margin: 0 0 27px;
    font-size: 24px;
    line-height: 28px;

    @include mq($until: tablet) {
      margin: 0 0 33px;
    }
  }
}
