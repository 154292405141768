@import '../../../../styles/shared';

.c-product-type {
  &__heading {
    font-size: 24px;
    font-weight: 400;
    margin: 20px 0;
  }

  &__body {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    overflow: hidden;
  }

  &__instruction {
    margin-bottom: 20px;
  }
}
