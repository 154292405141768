@import '../../../../../styles/shared';

.c-marketing-terms-acceptance {
  margin-bottom: 20px;
  padding: 15px;
  color: #333;
  background: #f7f7f7;

  &__ticket-for {
    margin: -15px;

    &--hidden {
      display: none;
    }

    &-hint {
      font-size: 14px;
      color: #333;

      @include mq($from: tablet) {
        margin-top: -32px;
      }
    }

    .c-payment-journey-input {
      padding: 15px 15px 5px;
    }
  }

  &__info {
    padding: 16px 0;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    border-bottom: 1px solid #333333;
  }

  &__consent-form {
    margin-top: 15px;

    & + & {
      margin-top: 0;
    }

    &-question {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 20px;
      color: #333;
    }

    .c-payment-journey-input {
      padding: 0;
    }
  }

  &__paragraph {
    font-size: 12px;
    line-height: 16px;
    color: #667;
  }

  b {
    font-weight: bold;
  }

  a {
    cursor: pointer;
  }

  .c-payment-journey-input-label {
    font-size: 14px;
    color: #333;
  }
}
