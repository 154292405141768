@import '../../../../../styles/shared';

.c-search-radio-btn {
  font-family: $font-family-sans-serif;

  &__item {
    margin-bottom: 6px;

    &--show {
      display: block;
    }

    &--hide {
      display: none;
    }
  }

  &__native-radio {
    display: none;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      .c-search-radio-btn__label-count,
      .c-search-radio-btn__label-text {
        color: #cccccc;
      }

      .c-search-radio-btn__custom-radio {
        border: 1px solid #cccccc;
      }
    }
  }

  &__label-count {
    color: $tenant-secondary-color;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    margin-right: 8px;
  }

  &__label-text {
    color: #404041;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__custom-radio {
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #404041;
    margin-right: 9px;
    box-sizing: border-box;
    border-radius: 8px;

    &--active {
      border: 5px solid $tenant-primary-color;
    }
  }
}
