@import '../../../../../../styles/shared';

.c-festival-social-network {
  &__heading {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;

    @include mq($from: desktop) {
      @include text-align(left);
    }
  }

  ul {
    display: flex;
    justify-content: center;

    @include mq($from: desktop) {
      justify-content: flex-start;
    }
  }

  li {
    + li {
      @include margin-left(10px);
    }
  }
}
