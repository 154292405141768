@import '../../../../../styles/shared';

.c-audience-text-section {
  padding: 45px 0 40px;
  outline: 1px solid #fff;

  @include mq($until: tablet) {
    padding: 15px 0 0;
  }

  .container {
    max-width: $extended-layout-width;
  }

  &__title {
    margin-bottom: 30px;
    font-family: $font-family-serif;
    font-size: 36px;
    line-height: 48px;
    color: $tenant-primary-color;

    @include mq($until: tablet) {
      font-size: 26px;
      line-height: 28px;
    }
  }
}
