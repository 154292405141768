@import '../../../styles/shared';

.c-payment-journey-form-box {
  padding: 20px 15px;
  background-color: #fff;

  & + & {
    margin-top: 20px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 300;
    line-height: 32px;
    color: #333132;
  }

  &__text {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 18px;
  }
}
