@import '../../../../styles/shared';

.c-related-articles {
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #cccccc;

  @include mq($until: tablet) {
    .container {
      padding: 0;
    }
  }

  &__list {
    display: flex;

    @include mq($until: desktop) {
      flex-direction: column;
    }

    @include mq($until: tablet) {
      border: none;
    }

    &--vertical {
      flex-direction: column;
    }
  }

  &__item {
    &:last-child &-link {
      border-bottom: none;
    }
  }

  &__list--vertical &__item {
    &-link {
      border-bottom: 1px solid #dee0e5;
    }
  }

  &__item {
    display: flex;
    padding: 20px 20px 0;

    &-link {
      display: flex;
      height: 100%;
      padding-bottom: 20px;
      text-decoration: none;
      background-color: transparent;
      transition: background-color 0.3s linear;
    }

    &-background {
      min-width: 80px;
      height: 80px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &-text {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      color: $tenant-primary-color;
    }

    &-topic {
      font-family: $font-family-sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      color: #4d798d;

      &-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
      }
    }

    &-title {
      font-family: $font-family-serif;
      font-size: 16px;
      line-height: 20px;
      color: #333;
    }
  }
}

.black-and-red-view {
  .c-related-articles__item-text {
    color: #333;
  }
}
