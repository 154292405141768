@import '../../../../styles/shared';

.c-audience-section {
  padding: 40px 0 25px;
  border-bottom: 1px solid #d4d4d4;
  background-color: #f5f5f5;

  @include mq($until: desktop) {
    padding: 40px 0;
  }

  @include mq($until: tablet) {
    padding: 20px 0 5px;
  }

  .container {
    max-width: $extended-layout-width;
  }

  &__title {
    display: inline-block;
    margin-right: 27px;
    font-family: $font-family-serif;
    font-size: 36px;
    line-height: 48px;
    color: #16285c;

    @include mq($until: tablet) {
      display: block;
      font-size: 26px;
      line-height: 28px;
    }
  }

  &__description {
    font-family: $font-family-serif;
    font-size: 22px;
    line-height: 32px;
    color: $tenant-primary-color;

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__details {
    display: inline-block;
    font-family: $font-family-sans-serif;
    font-size: 20px;
    line-height: 48px;
    color: #16285c;

    @include mq($until: tablet) {
      font-size: 16px;
      line-height: 22px;
    }

    &-text {
      display: inline-block;
      margin-right: 20px;
      font-family: $font-family-sans-serif;
    }

    &-link {
      display: inline-block;
      color: $tenant-accent-color;
    }
  }

  &__content {
    margin-top: 30px;

    @include mq($until: tablet) {
      margin-top: 10px;
    }

    & > .row > [class^='col-'] {
      margin-bottom: $gutter-width;

      @include mq($from: desktop) {
        margin-bottom: 30px;
      }
    }
  }

  &--mobile-view &__content {
    & > .row:first-child {
      @include mq($until: tablet) {
        overflow-x: auto;
        position: relative;
        padding: 0 ($gutter-width * 0.5);
        margin: 15px (-$gutter-width) 0;
      }

      @include mq($until: tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: initial;
      }

      & > [class^='col-'] {
        @include mq($until: tablet) {
          width: 80%;
          max-width: 80%;
          min-width: 226px;
        }
      }
    }
  }
}
