@import '../../../../styles/shared';

.c-search-filters-header {
  text-align: right;

  @include mq($until: desktop) {
    display: none;
  }

  &__clear-all {
    cursor: pointer;
    font-family: $font-family-sans-serif;
    color: #cccccc;
    font-size: 14px;
    line-height: 19px;
    text-decoration: underline;

    &--active {
      color: $tenant-primary-color;
    }
  }
}
