@import '../../../../../styles/shared';

.c-agenda-main-item-session,
.c-agenda-main-item-session_break {
  position: relative;
  overflow: hidden;
  line-height: 18px;
  color: #333;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;

  &:not(:first-child) {
    margin-top: 10px;
  }

  .c-agenda-stream-item-inner {
    padding: 0 10px 10px;

    .agenda-item-heading {
      padding: 10px 0 0;

      .agenda-item-time {
        font-size: 13px;
        margin-bottom: 5px;
      }

      .agenda-item-title {
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 0;

        @include theme-only($DEFAULT) {
          font-family: $font-family-serif;
          font-weight: 400;

          @include course-only {
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
          }
        }
      }

      .agenda-item-free-label {
        background-color: #15cb8e;
        border-radius: 2px;
        font-size: 11px;
        font-weight: bold;
        color: #fff;
        line-height: 15px;
        @include margin-left(5px);
        padding: 2px 5px 1px;
        position: relative;
        top: -1px;
        text-transform: uppercase;

        @include theme-only($DEFAULT) {
          text-transform: none;
        }
      }
    }

    .agenda-item-description {
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 400;
      font-size: 14px;
      margin-top: 18px;
      color: #737373;
    }

    .agenda-item-speakers {
      margin-top: 18px;
      padding-left: 10px;
      padding-right: 10px;

      li {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
    }
  }

  &.shortest-main-agenda-item {
    &.shortest-main-agenda-item--3 {
      .agenda-item-heading {
        padding-top: 2px;
      }
    }

    &.truncated:not(:hover):after {
      content: '';
      position: absolute;
      top: 0;
      @include right(0);
      height: 100%;
      width: 60px;
      [dir='ltr'] & {
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgb(255, 255, 255) 100%
        );
      }

      [dir='rtl'] & {
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 0) 0%,
          rgb(255, 255, 255) 100%
        );
      }
    }

    &.truncated:hover {
      .agenda-item-time {
        float: none;
      }
      .agenda-item-title {
        font-size: 16px;
        white-space: normal;
      }
    }

    .agenda-item-heading {
      overflow: hidden;
      padding-top: 5px;

      .agenda-item-time {
        float: left;
        padding-right: 5px;
      }

      .agenda-item-title {
        font-size: 14px;
        line-height: 16px;
        white-space: nowrap;
      }
    }
  }
}

.c-agenda-main-item-session {
  &:hover {
    .c-agenda-stream-item-inner {
      min-height: 100%;
      background-color: #f7f7f7;
    }
  }

  &.truncated {
    &:last-child:hover {
      height: 100% !important;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      @include right(0);
      height: 35px;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 20%,
        rgb(255, 255, 255) 100%
      );
    }

    &:hover {
      overflow: visible;
      z-index: 3;
      .c-agenda-stream-item-inner {
        box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
      }
      &:after {
        background: none;
      }
    }
  }
}

.c-agenda-main-item-session_break {
  background: #e5e5e5;
  border-top: 3px solid #aaaaaa;
  .c-agenda-stream-item-inner {
    line-height: 16px;
  }
  &:hover {
    background-color: #dcdcdc;
  }
}
