@import '../../../../../styles/shared';

.agenda-stream-title {
  height: 100%;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin: 0 5px;

  .agenda-item-free-label {
    @include float(right);
    background-color: #15cb8e;
    border-radius: 2px;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    line-height: 15px;
    @include margin-left(5px);
    padding: 2px 5px 1px;
    position: relative;
    top: -1px;
    text-transform: uppercase;

    @include theme-only($DEFAULT) {
      text-transform: none;
    }
  }

  .group-item-wrapper {
    padding: 7px 5px 10px;
    margin-bottom: -3px;
  }
}

.c-agenda-stream {
  padding: 0 5px;

  .slick-slide:not(.slick-active) & .agenda-stream-content-wrapper {
    overflow: hidden;
  }
}
