@import '../../../styles/shared';

.c-booking-closed-section {
  padding: 30px 0;
  flex-grow: 1;
  text-align: center;
  color: #fff;

  &__heading {
    font-weight: 800;
    font-size: 52px;
    line-height: 1;
    margin: 30px 0 15px;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: 'Aleo', serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__description {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 30px;
  }

  &__description,
  &__message {
    margin-bottom: 20px;

    .formatted-text {
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        text-align: left;
      }
    }

    a {
      color: #fff;
    }
  }
}
