@import '../../../../styles/shared';

.lead-gen-interest-checkbox {
  background: #f7f7f7;
  //@include padding(18px, 30px, 0, 22px);
  padding: 18px 30px 0 22px;
  margin: 10px 0;

  &__header {
    color: #333;
    border-bottom: 1px solid $form-border-color;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 10px;
    margin-bottom: 12px;
  }

  &__title {
    color: #333;
  }

  &__subtitle {
    color: $form-helper-color;
    font-size: 14px;
  }

  &__error {
    color: #c9474d;
    font-size: 12px;
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    @include mq($until: tablet) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__item {
    //@include margin-right(26px);
    margin-right: 26px;

    &:last-of-type {
      //@include margin-right(0);
      margin-right: 0;
    }

    &--two-cols {
      @include mq($from: desktop) {
        width: 50%;
        //@include margin-right(0);
        margin-right: 0;
      }
    }

    @include mq($until: tablet) {
      width: 100%;
    }
  }
}

@include theme-only($DEFAULT) {
  .lead-gen-container__form-article {
    .lead-gen-interest-checkbox__group {
      .lead-gen-checkbox {
        &__label-text--small {
          font: normal 16px/32px $font-family-sans-serif;
          color: #333;
        }

        &__label-square-wrapper--small {
          margin-top: 5px;
        }
      }
    }
  }
}
