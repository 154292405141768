@import '../../../../../styles/shared';

.c-agenda-list-view {
  .agenda-group {
    .agenda-stream-name {
      position: relative;
      min-height: 40px;
      border-bottom-style: solid;
      border-bottom-width: 3px;
      font-size: 14px;
      font-weight: 700;
      padding-top: 10px;
      @include padding-left(10px);
      @include padding-right(40px);

      &.expanded_list {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
