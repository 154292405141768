@import '../../../../styles/shared';

.c-co-lo-agenda-session-speakers {
  margin: 0;
  background-color: transparent;
  color: #999999;

  &__name {
    font-weight: bold;
  }
}
