@import '../../../../styles/shared';

.c-base-section-title {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 1;

  @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    text-transform: uppercase;
  }

  @include theme-only($DEFAULT) {
    @include event-only() {
      font-family: $font-family-serif;
    }

    @include course-only() {
      font-family: 'Open Sans', sans-serif;
    }
  }

  @include theme-only($FANEXPOHQ) {
    font-family: 'Proxima Nova ExCn Bl', sans-serif;
  }

  @include mq($until: tablet) {
    font-size: 32px;
  }

  &--small {
    font-size: 32px;
    margin-bottom: 20px;

    .material-icons {
      position: relative;
      top: -2px;
      @include margin-left(5px);
      font-size: 32px;
      vertical-align: top;
    }
  }

  &--sentence-case {
    text-transform: none;
  }
}

@include theme-only($FANEXPOHQ) {
  .c-base-section-title {
    font-family: 'Proxima Nova ExCn Rg', sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 38px;
    text-shadow: none;

    &--styled {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
      font-size: 58px;
      line-height: 47px;
    }
  }
}

@include theme-only($MEFCC) {
  .c-base-section-title {
    font-family: 'Barlow Condensed Regular', sans-serif;
    font-size: 44px;
    font-weight: bold;
    line-height: 38px;
    text-shadow: none;

    &--styled {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      font-size: 58px;
      line-height: 47px;
    }
  }
}

@include theme-only($ASIATECHXSG) {
  .c-base-section-title {
    font-family: 'Noto Sans', sans-serif;
    text-transform: none;
  }
}

@include theme-only($ONEOFAKIND) {
  .c-base-section-title {
    font-family: 'Berlingske Serif', sans-serif;
    line-height: 48px;
    text-transform: none;

    @include mq($until: tablet) {
      font-size: 40px;
    }
  }
}

@include theme-only($NAJAHSTACK) {
  .c-base-section-title {
    font-family: 'Proxima Nova Regular', sans-serif;
    line-height: 48px;
    text-transform: none;

    @include mq($until: tablet) {
      font-size: 40px;
    }
  }
}

@include theme-only($THEARTISTPROJECT) {
  .c-base-section-title {
    font-family: 'Source Sans Pro', sans-serif;
    line-height: 46px;
    text-transform: none;
    font-weight: 300;

    @include mq($until: tablet) {
      font-size: 40px;
    }
  }
}

@include theme-only($ARTTORONTO) {
  .c-base-section-title {
    font-family: 'Barlow Regular', sans-serif;
    line-height: 46px;
    text-transform: none;
    font-weight: 400;

    @include mq($until: tablet) {
      font-size: 40px;
    }
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-base-section-title {
    font-family: 'Gibson SemiBold', sans-serif;
    font-weight: 600;
    line-height: 48px;
    text-transform: none;

    @include mq($until: tablet) {
      font-size: 40px;
    }
  }
}
