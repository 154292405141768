@import '../../../../../styles/shared';

.c-terms-of-use {
  padding: 60px 0;
  background: #fff;
  border-top: 1px solid #efefef;
  direction: ltr;

  @include mq($until: tablet) {
    padding: 40px 0;
  }

  h1 {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 700;
    text-transform: none;
  }

  h2 {
    margin: 25px 0 10px;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1;
  }

  p {
    margin: 0 0 1em 0;
  }

  ol {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-inline-start: 40px;
    counter-reset: list;

    > li {
      position: relative;
      margin: 10px 0;
      list-style: none;

      &:before {
        position: absolute;
        //@include left(-1.6em);
        left: -1.6em;
        content: '(' counter(list, lower-alpha) ') ';
        counter-increment: list;
      }
    }
  }

  a {
    text-decoration: underline;
    color: $link-color;
  }

  table {
    width: 100%;
    border: 1px solid #e5e5e5;
    border-spacing: 0;

    th {
      min-width: 170px;
      padding: 5px 20px;
      //@include text-align(left);
      text-align: left;
      font-weight: 600;
      vertical-align: middle;
      color: #fff;
      background: #002144;

      @include mq($until: tablet) {
        min-width: auto;
        padding: 5px 10px;
      }
    }

    td {
      padding: 15px 20px;

      @include mq($until: tablet) {
        padding: 5px 10px;
      }
    }
  }
}
