@import '../../../../../../styles/shared';

.c-search-content-article {
  width: 18%;
  display: block;
  text-decoration: none;
  border: 1px solid #cccccc;
  border-radius: 0 0 10px 10px;
  background-color: #fff;

  @include mq($until: $extended-layout-width) {
    width: 30%;
  }

  @include mq($until: tablet) {
    min-width: 228px;
  }

  &__preview-mode--more-four {
    margin: 0;

    @include mq($until: $extended-layout-width) {
      margin: 0 20px 20px 0;
      &:nth-child(4) {
        margin: 0 20px 0 0;
      }
    }
    @include mq($until: tablet) {
      margin: 0 20px 0 0;
    }
  }

  &__preview-mode--less-four {
    margin: 0 20px 0 0;

    @include mq($until: $extended-layout-width) {
      margin: 0 20px 20px 0;
      &:nth-child(4) {
        margin: 0 20px 0 0;
      }
    }

    @include mq($until: tablet) {
      margin: 0 20px 0 0;
    }
  }

  &__view-mode--more-four {
    margin: 0 20px 40px 0;
    @include mq($until: $extended-layout-width) {
      margin: 0 20px 20px 0;
    }

    @include mq($until: tablet) {
      margin: 0 0 20px 0;
      width: 100%;
    }
  }

  &__view-mode--less-four {
    margin: 0 20px 0 0;

    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &__image {
    height: 162px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    position: relative;
    background-color: #e5e5e5;
  }

  &__image-default {
    background-size: auto 130px;
  }

  &__gated {
    height: 27px;
    width: 84px;
    border-radius: 0 0 5px 0;
    background-color: $tenant-accent-color;
    color: #fff;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px 20px 20px 20px;
    border-radius: 0 0 10px 10px;
  }

  &__description-default {
    background-color: #f5f5f5;
    height: 24px;
    margin-bottom: 10px;

    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 100%;
      margin-bottom: 20px;
    }

    &:nth-child(3) {
      width: 50%;
    }
  }

  &__title {
    color: #333;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 13px;
    text-overflow: ellipsis;
  }

  &__topic-name {
    color: #4d798d;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 3px;
  }
}
