@import '../../../../../styles/shared';

.c-agenda-list-view {
  @include mq($until: tablet) {
    margin-top: $gutter-width;
    margin-bottom: $gutter-width;
  }

  .agenda-group {
    .agenda-group-name {
      margin-top: 8px;
      padding: 10px;
      font-size: 18px;

      &.expanded_list {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  &.sort-by-stream {
    .agenda-group {
      background-image: linear-gradient(to bottom, #e5e5e5, #f4f4f4);
      background-repeat: repeat-x;

      .agenda-group-name {
        border-top: 3px solid #999999;
        border-bottom: dotted 1px #aaaaaa;
      }

      .agenda-group-unnamed {
        border-bottom: dotted 1px #aaaaaa;
      }
    }
  }

  &.sort-by-time {
    .agenda-group {
      .agenda-group-name {
        padding: 0;
      }
    }
  }
}
