@import '../../../../../styles/shared';

.c-agenda-stream-item-session,
.c-agenda-stream-item-session_break {
  overflow: hidden;
  color: #333;
  cursor: pointer;
  position: relative;

  &.c-agenda-stream-item-session,
  &.c-agenda-stream-item-session_break {
    &--scale-factor-8 {
      .c-agenda-stream-item-inner {
        line-height: 20px;
      }
    }

    &--duration-3 {
      .agenda-item-heading {
        .agenda-item-title {
          line-height: 10px;
          vertical-align: top;
        }
      }

      .c-agenda-time-status {
        vertical-align: top;
      }

      .agenda-item-time {
        line-height: 10px;
      }
    }

    &--duration-4 {
      .agenda-item-heading {
        padding-top: 7px;
      }

      .agenda-item-time {
        line-height: 15px;
      }

      .agenda-item-title {
        line-height: 15px;
      }
    }

    &--duration-5 {
      .agenda-item-heading {
        padding-top: 9px;
      }
    }
  }

  &:not(:first-child) {
    margin-top: 5px;
  }

  &:not(.disabled):hover {
    overflow: visible;
    z-index: 100 !important;

    .c-agenda-stream-item-inner {
      min-height: 100%;
      padding-bottom: 5px;
    }

    &.shortest-agenda-item {
      .agenda-item-heading {
        .agenda-item-time {
          float: none;
        }

        .agenda-item-title {
          font-size: 16px;
          line-height: 18px;
          white-space: normal;
        }
      }
    }
  }

  &.truncated {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      @include right(0);
      height: 35px;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 20%,
        rgb(255, 255, 255) 100%
      );
    }

    &:hover:after {
      background: none;
    }
  }

  &.truncated:not(.disabled):hover {
    .c-agenda-stream-item-inner {
      box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .c-agenda-stream-item-inner {
    padding: 0 10px;
    line-height: 18px;

    .agenda-item-heading {
      padding: 10px 0 0;

      .agenda-item-time {
        font-size: 13px;
        margin-bottom: 5px;
      }

      .agenda-item-title {
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 0;

        @include theme-only($DEFAULT) {
          font-family: $font-family-serif;
          font-weight: 400;

          @include course-only {
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
          }
        }
      }

      .agenda-item-free-label {
        background-color: #15cb8e;
        border-radius: 2px;
        font-size: 11px;
        font-weight: bold;
        color: #fff;
        line-height: 15px;
        @include margin-left(5px);
        padding: 2px 5px 1px;
        position: relative;
        top: -1px;
        text-transform: uppercase;

        @include theme-only($DEFAULT) {
          text-transform: none;
        }
      }
    }

    .agenda-item-description {
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 400;
      font-size: 14px;
      margin-top: 18px;
      color: #737373;
    }

    .agenda-item-speakers {
      margin-top: 18px;
      padding-left: 10px;
      padding-right: 10px;

      li {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
    }
  }

  &.shortest-agenda-item {
    &.truncated:not(:hover):after {
      content: '';
      position: absolute;
      top: 0;
      @include right(0);
      height: 100%;
      width: 60px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255) 100%
      );
    }

    .agenda-item-heading {
      overflow: hidden;
      padding-top: 5px;

      .agenda-item-time {
        float: left;
        padding-right: 5px;
      }

      .agenda-item-title {
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }

  &.disabled {
    cursor: default;

    .agenda-item-free-label {
      opacity: 0.5;
    }
  }
}

.c-agenda-stream-item-session {
  background-color: #fff;

  &:not(.disabled):hover {
    .c-agenda-stream-item-inner {
      background-color: #f7f7f7;
    }
  }

  &.disabled {
    color: #aaaaaa;
    background-color: #f3f3f3;

    &.truncated:after {
      background: linear-gradient(
        to bottom,
        rgba(231, 231, 231, 0) 0%,
        rgb(231, 231, 231) 100%
      );
    }
  }
}

.c-agenda-stream-item-session_break {
  background: #e5e5e5;

  &:not(.disabled):hover .c-agenda-stream-item-inner {
    background-color: #dcdcdc;
  }

  &.shortest-agenda-item {
    &.truncated:after {
      background: linear-gradient(
        to right,
        rgba(229, 229, 229, 0) 0%,
        rgb(229, 229, 229) 100%
      );
    }

    &:hover:after {
      background: none;
    }
  }

  &.disabled {
    background-color: #e7e7e7;
    color: #999999;

    &.truncated:after {
      background: linear-gradient(
        to right,
        rgba(231, 231, 231, 0) 0%,
        rgb(231, 231, 231) 100%
      );
    }
  }
}

.c-agenda-stream-item-space {
  cursor: default;
  background: repeating-linear-gradient(
    135deg,
    #efefef 0px,
    #efefef 5px,
    #e5e5e5 6px,
    #e5e5e5 7px
  );
  background-size: 0.5em 0.5em;
}
