@import '../../../../styles/shared';

.c-audience-text-box {
  height: calc(100% - #{$gutter-width});
  padding: 18px;
  margin-bottom: $gutter-width;
  border-radius: 0 0 7px 7px;
  border-top: 3px solid $tenant-accent-color;
  background-color: #f4f4f4;

  &__title {
    margin: 8px 0 20px;
    font-family: $font-family-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    color: #333;
  }

  &__description {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #333;

    a {
      color: #333;
    }
  }

  &__link {
    margin-top: 30px;

    a {
      font-size: 18px;
      line-height: 21px;
      color: $tenant-accent-color;
    }
  }
}
