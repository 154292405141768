@import '../../../styles/shared';

.c-sign-up-form {
  margin: 40px 0;
  color: #333;

  &__paragraph {
    margin-bottom: 20px;
    color: $form-helper-color;
  }

  .c-button__loader {
    width: 14px;
    height: 14px;

    & div {
      width: 14px;
      height: 14px;
      border-width: 3px;
    }
  }

  .c-input-label {
    color: #333;
  }

  .c-form-heading {
    margin-top: 20px;
    margin-bottom: 5px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #333;
  }

  .captcha-wrapper {
    margin: 10px 0 20px;

    .captcha-error {
      margin-top: 10px;
      @include margin-left(18px);
      font-size: 12px;
      color: $form-error-color;
    }
  }
}

@include theme-only($DEFAULT) {
  .c-sign-up-form {
    .c-form-heading {
      font-family: $font-family-serif;
      font-size: 32px;
      font-weight: normal;
      line-height: 48px;
      color: $tenant-primary-color;
    }

    .c-sign-up-form__paragraph {
      color: #333333;
      font-size: 16px;
      line-height: 26px;
    }

    .c-input-label {
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }

    .c-form-field {
      font-family: $font-family-sans-serif;
      font-weight: normal;
      color: rgb(51, 51, 51);

      &.select {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAASCAYAAACJgPRIAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACaADAAQAAAABAAAAEgAAAAAbuh0IAAABDklEQVQoFa2SIY6FMBBAZ5cNCE5AguEAKAQGgSF4JGeA4JFcADAYcCBRCG6BQYBAYTGEYIAQuml3P/ndn++2ou28eW1nkgL6M6IoQsdxUBSeo6ZpkKIoqCiKZ4w+4Xdc1wVhGJIoyzJYluWRgluq6xr6vieJdV0hTVNa2rYNkiS5Id6UZQnjOBJGbsrzHKZpoqTzPCGOY8K+8CyKIvi+T0mPYN93+MBtPMC79S78nYD5/0mk8CAIYBiGlxd5nicdEsk0Taiq6kVyHAc4jvupSVVV0DSNkgRBANu2CbsL9zwPGIa5Rdd1gWVZWpIkCSzLIlCWZTAM4z5AfZV5npGu66htW+qrUBLOdF1HCTj4Bpj3xuLV6K9ZAAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 12px);
        background-position-y: center;
      }

      &.error {
        @include padding-left(10px);
      }

      &:-ms-input-placeholder,
      &.select::-ms-value {
        color: #333333 !important;
      }

      &.select:visited::-ms-value {
        color: #333333 !important;
      }

      &.password {
        &::-ms-clear,
        &::-ms-reveal {
          display: none;
        }
      }
    }

    .valid .c-form-field.select {
      color: #333333;
    }

    @supports (-ms-ime-align: auto) {
      .c-form-field.select {
        color: #999;
      }
    }

    .ic-submit-button {
      outline: none;
      border: none;
      display: block;
      background: #cc31c2;
      color: white;
      width: 200px;
      height: 40px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      font-family: $font-family-serif;
      text-decoration: none;
      border-radius: 20px;
      padding: 10px;
    }

    .c-form-helper {
      color: #333333;
      font-size: 14px;
      line-height: 26px;
    }

    .c-button.c-button--accent {
      width: 200px;
    }
  }

  .ie,
  .edge {
    .c-form-field.select {
      @include padding-right(20px);
    }
  }
}
