@import '../../../styles/shared';

.lead-gen-login-form {
  &__header {
    font-size: 28px;
    line-height: 28px;
    font-weight: 300;
    margin: 0 0 13px;
    color: #333;

    &--article {
      font-size: 22px;
    }
  }

  &__error {
    color: #c9474d;
    font-size: 16px;
    //@include text-align(right);
    text-align: right;
    line-height: 16px;
  }

  &__forgot-link {
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
    @include mq($until: tablet) {
      margin: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__link-article {
    color: #8f48c2;
  }
}

@include theme-only($DEFAULT) {
  .lead-gen-container__form-article {
    .lead-gen-register-form {
      font-family: $font-family-sans-serif;
    }

    h3 {
      font: 600 18px/28px $font-family-sans-serif;
      color: $tenant-primary-color;
    }

    label {
      font: normal 16px/22px $font-family-sans-serif;
      color: #333;
    }

    input {
      border-radius: 3px;
    }

    .lead-gen-login-form__forgot-link {
      line-height: 19px;
      font-family: $font-family-sans-serif;
    }

    .c-button__lead-gen-type-1 {
      height: 40px;
      width: 200px;
      border-radius: 20px;
      background-color: $tenant-accent-color;
      border-color: $tenant-accent-color;
      color: white;
      font-family: $font-family-serif;
      padding: 6px 0 4px !important;
      text-transform: initial !important;
      font-size: 18px;

      @media screen and (max-width: $tablet-breakpoint) {
        &.lead-gen-login-button {
          margin: 20px 0 0 0;
        }
      }

      &:hover {
        background-color: $tenant-accent-color;
        border-color: $tenant-accent-color;
        color: white;
      }
    }
  }

  .lead-gen-form__subtitle {
    font: normal 16px/28px $font-family-sans-serif;
    color: #333;
  }
}
