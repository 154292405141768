@import '../../../../styles/shared';

.c-article-home {
  padding-top: 55px;
  color: $tenant-primary-color;

  .container {
    max-width: $extended-layout-width;
  }

  &__title {
    margin-bottom: 25px;
    font-family: $font-family-serif;
    font-size: 42px;
    line-height: 48px;
    color: $tenant-primary-color;
  }

  &__image {
    margin-bottom: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    margin-bottom: 30px;

    h2 {
      margin: 30px 0;
      font-family: $font-family-serif;
      font-size: 22px;
      line-height: 30px;
      color: $tenant-primary-color;
    }

    h3 {
      margin: 30px 0 12px;
      font-family: $font-family-sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: $tenant-primary-color;
    }

    h4 {
      margin-block-start: 1.33em;
      margin-block-end: 1.33em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      font-size: 16px;
      font-weight: 700;
    }

    .formatted-text > p {
      font-family: $font-family-sans-serif;
      font-size: 18px;
      line-height: 28px;
      color: #333;
    }

    a {
      color: $tenant-accent-color;
    }

    blockquote {
      margin: 44px 0;
      padding-left: 20px;
      font-family: $font-family-sans-serif;
      font-size: 26px;
      font-weight: 300;
      line-height: 36px;
      border-left: 1px solid #a6bcc6;
      color: #333;
    }

    img.alignleft {
      float: left;
      margin: 5px 10px 10px 0;
    }

    img.alignright {
      float: right;
      margin: 5px 0 10px 10px;
    }

    img.aligncenter {
      margin: 32px auto;
    }

    img {
      height: auto;
      display: block;
      margin: 32px 0;
      max-width: 100%;
    }

    ul,
    ol {
      li {
        font-weight: 300;
      }
    }

    table {
      width: 100%;

      td,
      th {
        padding: 5px;
      }
    }

    sub {
      font-size: 75%;
    }

    @include mq($until: tablet) {
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__featured-event {
    margin-bottom: 30px;
    border-radius: 5px;
    border: 1px solid #cccccc;

    &-body {
      padding: 20px;
    }
  }

  &__sidebar {
    padding-left: 30px;
    margin-top: -20px;
  }

  &__lead-gen-wrapper {
    clear: both;
  }
}

.c-article-metadata {
  margin-top: 25px;
}
