@import '../../../../styles/shared';

$nav-height: 55px;

.c-audience-nav {
  border-bottom: 1px solid #fff;
  background-color: #022244;

  .container {
    max-width: $extended-layout-width;
  }

  nav {
    ul {
      li {
        display: inline-block;
        position: relative;

        &:not(:first-child) {
          margin-left: 60px;

          @include mq($until: tablet) {
            margin-left: 30px;
          }

          @include mq($until: 360px) {
            margin-left: 28px;
          }

          @include mq($until: $mobile-breakpoint) {
            margin-left: 15px;
          }

          &::before {
            position: absolute;
            top: ($nav-height - 32px) * 0.5;
            left: -30px;
            content: '';
            height: 32px;
            border-left: 1px solid #fff;
            opacity: 0.34;

            @include mq($until: tablet) {
              left: -15px;
            }

            @include mq($until: 360px) {
              left: -14px;
            }

            @include mq($until: $mobile-breakpoint) {
              left: -8px;
            }
          }
        }

        a {
          display: block;
          height: $nav-height;
          font-family: $font-family-serif;
          font-size: 18px;
          font-weight: bold;
          line-height: $nav-height + 3px;
          vertical-align: middle;
          text-align: center;
          text-decoration: none;
          color: #fff;

          @include mq($until: tablet) {
            font-size: 14px;
          }

          i {
            position: relative;
            top: 9px;
            font-size: 30px;

            @include mq($from: tablet) {
              right: -4px;
            }
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
