@import '../../../../styles/shared';

.person-content-list {
  &__item {
    padding: 20px;
    color: #000;

    &:not(:last-child) {
      border-bottom: 1px solid;
    }

    &-title {
      margin-bottom: 1em;
    }

    &-link {
      font-weight: 700;
    }

    &-link {
      @include text-align(right);
      text-decoration: none;
      color: #000;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &-icon {
      @include margin-left(5px);
      [dir='rtl'] & {
        transform: rotate(180deg);
      }
      font-size: 12px;
    }
  }
}
