@import '../../../../styles/shared';

.c-audience-compact-list {
  height: 100%;
  border: 1px solid #cccccc;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  overflow: hidden;

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__item {
    flex: 1;
    padding: 12px 16px;
    text-decoration: none;

    &:not(:first-child) {
      border-top: 1px solid #cccccc;
    }

    &:hover {
      background: #f7f7f7;
    }
  }

  &__topic {
    margin-bottom: 2px;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    color: #4d798d;
  }

  &__title {
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #333;
  }
}
