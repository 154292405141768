@import '../../../styles/shared';

.organisation-speaker {
  padding: 25px 20px;
  margin: 0;
  border-bottom: 2px solid;
  font-size: 0;

  &__photo {
    width: 60px;
    height: 60px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    @include margin-right(20px);
    vertical-align: middle;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__info {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      font-size: 16px;
      margin: 0;
      line-height: 22px;

      &:first-child {
        font-weight: 600;
      }

      &:nth-child(2) {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
}
