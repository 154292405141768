@import '../../../../styles/shared';

.c-virtual-event-proposition-heading {
  &__title {
    color: #3a3a3a;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-weight: 800;
    font-size: 22px;
    line-height: 25px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include mq($until: tablet) {
      margin-bottom: 16px;
    }

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    color: #666666;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
