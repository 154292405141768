@import '../../../styles/shared';

.c-vat-message {
  @include text-align(right);
  font-size: 12px;
  font-weight: 300;
  margin-top: 1px;
  color: #999;

  &__carbon {
    font-style: italic;
  }

  &--total-price-vat {
    @include text-align(left);
  }

  &--mobile-left-aligned {
    @include mq($until: tablet) {
      @include text-align(left);
    }
  }

  @include mq($until: tablet) {
    white-space: nowrap;
  }
}
