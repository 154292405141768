@import '../../../../../styles/shared';

.c-lead-gen-consent-statements {
  color: $form-helper-color;
  font-size: 12px;
  line-height: 16px;

  &__link-article {
    color: #8f48c2;
  }

  &__use-data {
    margin-bottom: 10px;
  }
}
