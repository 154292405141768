@import '../../../styles/shared';

$sidebar-mobile-width: 340px;
$sidebar-transition-time: 0.3s;

.c-mobile-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10000;

  @include mq($from: tablet) {
    visibility: hidden !important;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);

    [dir='ltr'] & {
      transition: left 0.1s ease, right 0.1s ease, opacity 0.5s ease;
    }

    [dir='rtl'] & {
      transition: right 0.1s ease, left 0.1s ease, opacity 0.5s ease;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: $sidebar-mobile-width;
    max-width: 100vw;
    overflow: auto;
    background: #fff;
    [dir='ltr'] & {
      transition: left $sidebar-transition-time ease,
        right $sidebar-transition-time ease;
    }

    [dir='rtl'] & {
      transition: right $sidebar-transition-time ease,
        left $sidebar-transition-time ease;
    }
  }

  &__closer {
    position: absolute;
    top: 8px;
    @include right(0);
    padding: 10px 20px;
    z-index: 1;

    &::after {
      content: 'Close';
    }
  }

  &__title {
    position: absolute;
    top: 14px;
    @include left(0);
    @include padding-left(20px);
    font-size: 24px;
    font-weight: 300;
    color: #000;
  }

  &--primary &__overlay {
    @include left(100vw);
    @include right(-100vw);
  }

  &--secondary &__overlay {
    @include left(-100vw);
    @include right(100vw);
  }

  &--primary &__content {
    @include left(100vw);
    @include right(-100vw);
    @include margin-left(calc(100vw - #{$sidebar-mobile-width}));

    @media screen and (max-width: #{$sidebar-mobile-width}) {
      @include margin-left(0);
    }
  }

  &--secondary &__content {
    @include left(-100vw);
    @include right(100vw);
    @include margin-right(calc(100vw - #{$sidebar-mobile-width}));

    @media screen and (max-width: #{$sidebar-mobile-width}) {
      @include margin-right(0);
    }
  }
}

.primary-sidebar-opened,
.secondary-sidebar-opened {
  @include mq($until: tablet) {
    overflow: hidden;
  }
}

.primary-sidebar-opened {
  .c-mobile-sidebar--primary {
    .c-mobile-sidebar__content {
      left: 0;
      right: 0;
    }

    .c-mobile-sidebar__overlay {
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
}

.secondary-sidebar-opened {
  .c-mobile-sidebar--secondary {
    .c-mobile-sidebar__content {
      left: 0;
      right: 0;
    }

    .c-mobile-sidebar__overlay {
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
}
