.c-agenda-time-status-on-demand {
  font-size: 13px;

  &--bold {
    font-size: 16px;
    font-weight: 700;

    .c-agenda-time-status-on-demand__text {
      font-weight: initial;
    }
  }
}
