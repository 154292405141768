@import '../../../../../styles/shared';

.c-payment-journey-form-free-text {
  margin-bottom: 15px;
  font-size: 13px;

  p + p {
    margin-top: 15px;
  }
}
