//TODO: move this variables to global theme level after branding updates
@import '../../../../styles/shared';

:root {
  /**
   * Payment Typography variant h1
   */
  --payment-typography-h1-font-family: 'Barlow Condensed Extra Bold', sans-serif;
  --payment-typography-h1-font-weight: 800;
  --payment-typography-h1-font-size: 30px;
  --payment-typography-h1-line-height: 32px;

  @include mq($from: desktop) {
    --payment-typography-h1-font-size: 36px;
    --payment-typography-h1-line-height: 32px;
  }

  /**
   * Payment Typography variant h2
   */
  --payment-typography-h2-font-family: 'Barlow Condensed Extra Bold', sans-serif;
  --payment-typography-h2-font-weight: 800;
  --payment-typography-h2-font-size: 22px;
  --payment-typography-h2-line-height: 22px;

  @include mq($from: desktop) {
    --payment-typography-h2-font-size: 32px;
    --payment-typography-h2-line-height: 28px;
  }

  /**
   * Payment Typography variant h3
   */
  --payment-typography-h3-font-family: 'Roboto', sans-serif;
  --payment-typography-h3-font-weight: 400;
  --payment-typography-h3-font-size: 24px;
  --payment-typography-h3-line-height: 28px;

  @include mq($from: desktop) {
    --payment-typography-h3-font-size: 24px;
    --payment-typography-h3-line-height: 29px;
  }

  /**
   * Payment Typography variant h4
   */
  --payment-typography-h4-font-family: 'Barlow Bold', sans-serif;
  --payment-typography-h4-font-weight: 700;
  --payment-typography-h4-font-size: 18px;
  --payment-typography-h4-line-height: 23px;

  @include mq($from: desktop) {
    --payment-typography-h4-font-size: 22px;
    --payment-typography-h4-line-height: 26px;
  }

  /**
   * Payment Typography variant h5
   */
  --payment-typography-h5-font-family: 'Barlow Bold', sans-serif;
  --payment-typography-h5-font-weight: 700;
  --payment-typography-h5-font-size: 16px;
  --payment-typography-h5-line-height: 20px;

  @include mq($from: desktop) {
    --payment-typography-h5-font-size: 18px;
    --payment-typography-h5-line-height: 24px;
  }

  /**
   * Payment Typography variant h6
   */
  --payment-typography-h6-font-family: 'Barlow Condensed Extra Bold', sans-serif;
  --payment-typography-h6-font-weight: 800;
  --payment-typography-h6-font-size: 16px;
  --payment-typography-h6-line-height: 20px;

  @include mq($from: desktop) {
    --payment-typography-h6-font-size: 18px;
    --payment-typography-h6-line-height: 20px;
  }

  /**
   * Payment Typography variant subtitle1
   */
  --payment-typography-subtitle1-font-family: 'Roboto', sans-serif;
  --payment-typography-subtitle1-font-weight: 700;
  --payment-typography-subtitle1-font-size: 24px;
  --payment-typography-subtitle1-line-height: 28px;

  @include mq($from: desktop) {
    --payment-typography-subtitle1-font-size: 24px;
    --payment-typography-subtitle1-line-height: 28px;
  }

  /**
   * Payment Typography variant subtitle2
   */
  --payment-typography-subtitle2-font-family: 'Roboto', sans-serif;
  --payment-typography-subtitle2-font-weight: 700;
  --payment-typography-subtitle2-font-size: 14px;
  --payment-typography-subtitle2-line-height: 18px;

  @include mq($from: desktop) {
    --payment-typography-subtitle2-font-size: 16px;
    --payment-typography-subtitle2-line-height: 20px;
  }

  /**
   * Payment Typography variant caption1
   */
  --payment-typography-caption1-font-family: 'Roboto', sans-serif;
  --payment-typography-caption1-font-weight: 700;
  --payment-typography-caption1-font-size: 12px;
  --payment-typography-caption1-line-height: 18px;

  @include mq($from: desktop) {
    --payment-typography-caption1-font-size: 13px;
    --payment-typography-caption1-line-height: 18px;
  }

  /**
   * Payment Typography variant caption2
   */
  --payment-typography-caption2-font-family: 'Roboto', sans-serif;
  --payment-typography-caption2-font-weight: 400;
  --payment-typography-caption2-font-size: 10px;
  --payment-typography-caption2-line-height: 14px;

  @include mq($from: desktop) {
    --payment-typography-caption2-font-size: 11px;
    --payment-typography-caption2-line-height: 15px;
  }

  /**
   * Payment Typography variant superText
   */
  --payment-typography-super-text-font-family: 'Barlow Condensed Extra Bold',
    sans-serif;
  --payment-typography-super-text-font-weight: 800;
  --payment-typography-super-text-font-size: 70px;
  --payment-typography-super-text-line-height: 60px;

  @include mq($from: desktop) {
    --payment-typography-super-text-font-size: 80px;
    --payment-typography-super-text-line-height: 64px;
  }

  /**
   * Payment Typography variant featuredText
   */
  --payment-typography-featured-text-font-family: 'Roboto', sans-serif;
  --payment-typography-featured-text-font-weight: 400;
  --payment-typography-featured-text-font-size: 16px;
  --payment-typography-featured-text-line-height: 20px;

  @include mq($from: desktop) {
    --payment-typography-featured-text-font-size: 18px;
    --payment-typography-featured-text-line-height: 22px;
  }

  /**
   * Payment Typography variant body1
   */
  --payment-typography-body1-font-family: 'Roboto', sans-serif;
  --payment-typography-body1-font-weight: 400;
  --payment-typography-body1-font-size: 14px;
  --payment-typography-body1-line-height: 18px;

  @include mq($from: desktop) {
    --payment-typography-body1-font-size: 16px;
    --payment-typography-body1-line-height: 20px;
  }

  /**
   * Payment Typography variant smallText
   */
  --payment-typography-small-text-font-family: 'Roboto', sans-serif;
  --payment-typography-small-text-font-weight: 400;
  --payment-typography-small-text-font-size: 12px;
  --payment-typography-small-text-line-height: 17px;

  @include mq($from: desktop) {
    --payment-typography-small-text-font-size: 13px;
    --payment-typography-small-text-line-height: 18px;
  }

  /**
   * Payment Button regular text
   */
  --payment-typography-button-font-family: 'Barlow Condensed Extra Bold',
    sans-serif;
  --payment-typography-button-font-weight: 800;
  --payment-typography-button-font-size: 20px;
  --payment-typography-button-line-height: 24px;

  @include mq($from: desktop) {
    --payment-typography-button-font-size: 22px;
    --payment-typography-button-line-height: 24px;
  }

  /**
   * Payment Button size small text
   */
  --payment-typography-button-small-font-family: 'Barlow Condensed Extra Bold',
    sans-serif;
  --payment-typography-button-small-font-weight: 800;
  --payment-typography-button-small-font-size: 18px;
  --payment-typography-button-small--line-height: 22px;

  @include mq($from: desktop) {
    --payment-typography-button-small-font-size: 18px;
    --payment-typography-button-small-line-height: 20px;
  }
}
