@import '../../../../../styles/shared';

.c-search-result-info {
  font-family: $font-family-sans-serif;
  color: $tenant-search-text-color;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-top: 16px;

  @include mq($until: desktop) {
    margin-bottom: 15px;
  }

  @include mq($until: tablet) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    line-height: 20px;
    margin: 5px 0 18px 0;
  }

  &__text-bold {
    font-weight: 600;
  }

  &__text-results {
    margin-right: 5px;
    @include mq($until: tablet) {
      margin-right: 0;
    }
  }

  &__clear-filters {
    color: #cccccc;
    font-size: 14px;
    line-height: 19px;
    text-decoration: underline;
    margin-left: 7px;
    cursor: pointer;
    @include mq($until: tablet) {
      margin-left: 0;
    }

    &--active {
      color: $tenant-primary-color;
    }
  }

  &__colon {
    display: none;
    @include mq($until: tablet) {
      display: inline;
    }
  }
}
