.c-co-lo-agenda-list-sessions-header {
  font-size: 16px;
  margin: 20px 0 10px;
  line-height: 1.4;
  font-weight: 400;

  &__event {
    font-weight: bold;
  }
}
