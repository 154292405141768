@import '../../../../../../styles/shared';

.c-festival-footer-sponsors {
  display: flex;
  flex-direction: column;

  @include mq($from: desktop) {
    flex-direction: row;
  }

  &__category {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 0;

    @include mq($from: desktop) {
      align-items: flex-start;
      padding: 0;
    }

    &:first-child {
      flex: 1 0;
    }

    & + & {
      position: relative;
    }

    & + &::before {
      @include mq($until: desktop) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: block;
        width: 100%;
        border-top: 1px solid #fff;
        content: '';
      }
    }
  }

  &__heading {
    margin-bottom: 25px;
    font-size: 14px;

    @include mq($from: desktop) {
      margin-bottom: 5px;
    }
  }

  &__listing {
    display: flex;
    flex: 1 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include mq($from: desktop) {
      justify-content: space-between;
    }
  }

  &__logo {
    img {
      width: 100px;
      height: auto;
    }
  }
}

@include theme-only($ASIATECHX) {
  @include mq($from: desktop) {
    .c-festival-footer-sponsors {
      @include margin-left(50px);

      &__category &__heading {
        margin-bottom: 0;
        line-height: 1;
      }

      &__category:nth-child(1) {
        flex: 1 0;
        align-items: center;
      }

      &__category:nth-child(1) &__listing {
        justify-content: center;
      }

      &__category:nth-child(1) &__logo img {
        width: 180px;
      }

      &__category:nth-child(2) {
        flex: 3 0;
      }

      &__category:nth-child(2) &__heading {
        @include margin-left(20px);
      }

      &__category:nth-child(2) &__listing {
        justify-content: space-around;
      }

      &__category:nth-child(2) &__logo img {
        width: 220px;
      }

      &__category:nth-child(3) {
        flex: 0.6 0;
        align-items: flex-end;
      }

      &__category:nth-child(3) &__logo img {
        width: 80px;
        height: 80px;
      }

      &__category:nth-child(3) &__listing {
        justify-content: flex-end;
      }

      &__category:nth-child(2),
      &__category:nth-child(3) {
        @include margin-left(50px);

        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          @include left(-25px);
          display: block;
          height: 85%;
          @include border-left(1px solid #fff);
          content: '';
        }
      }
    }
  }

  @media (min-width: 980px) and (max-width: 1200px) {
    .c-festival-footer-sponsors {
      @include margin-left(40px);

      &__category:nth-child(2) &__logo img {
        width: 170px;
      }

      &__category:nth-child(2) &__heading {
        @include margin-left(47px);
      }
    }
  }

  @include mq($until: desktop) {
    .c-festival-footer-sponsors {
      &__category:nth-child(1) &__heading {
        margin-bottom: 5px;
      }

      &__category:nth-child(1) &__logo img {
        width: 150px;
        margin-bottom: 10px;
      }

      &__category:nth-child(2) &__logo img {
        width: 150px;
        margin-bottom: 20px;
      }

      &__category:nth-child(2) &__logo img:nth-child(1) {
        @include margin-right(20px);
      }
    }
  }
}
