@import '../../../../../styles/shared';

.c-search-input {
  width: 50%;
  position: relative;

  @include mq($until: desktop) {
    width: 100%;
  }

  &__element {
    width: 100%;
    height: 60px;
    padding: 15px 0 15px 15px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    font-family: $font-family-sans-serif !important;
    font-size: 24px !important;
    line-height: 33px !important;
    color: #000 !important;

    @include mq($until: tablet) {
      font-size: 16px !important;
      line-height: 22px !important;
    }

    &:focus {
      outline: none;
    }

    @include mq($until: desktop) {
      padding: 15px 0 15px 22px;
    }

    @include mq($until: tablet) {
      height: 40px;
      padding: 10px 0 10px 10px;
    }
  }

  &__icon {
    width: 64px;
    height: 60px;
    border-radius: 0 5px 5px 0;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $tenant-accent-color;
    border: 1px solid $tenant-accent-color;
    color: #fff;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include mq($until: tablet) {
      width: 40px;
      height: 40px;
      font-size: 24px;
    }
  }
}
