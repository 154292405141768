@import '../../../../../styles/shared';

.c-informa-connect-footer {
  padding-top: 35px;
  font-family: $font-family-sans-serif;
  background: $tenant-secondary-color;

  &__top {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @include mq($from: wide) {
      margin-top: 15px;
    }
  }

  &__top &__nav--top {
    @include mq($until: tablet) {
      li {
        margin: 0 !important;
      }
    }

    @include mq($until: wide) {
      width: 100%;
    }
  }

  &__brand-logo {
    width: 300px;
    min-width: 300px;

    @include mq($until: tablet) {
      text-align: center;
      width: 80%;
      min-width: auto;
      margin: 0 auto 30px;
    }

    img {
      position: relative;
      width: 100%;
      height: auto;
    }
  }

  &__social-nav {
    position: relative;
    flex-grow: 1;
    text-align: center;

    @include mq($until: tablet) {
      width: 100%;
    }

    @include mq($from: tablet) {
      @include text-align(right);
    }

    @include mq($until: wide) {
      margin-top: 15px;
    }

    @include mq($from: wide) {
      @include margin-right(50px);
    }

    li {
      display: inline-block;

      &:not(:first-child) {
        @include margin-left(25px);
      }
    }

    a {
      position: relative;
      display: block;
      width: 36px;
      height: 36px;
      background: #fff;
      border-radius: 36px;
    }

    i {
      position: absolute;
      font-size: 18px;
      top: 10px;
      left: 10px;
      color: #525a5c;

      &.fa-linkedin-in {
        left: 11px;
      }

      &.fa-facebook-f {
        left: 13px;
      }

      &.fa-instagram {
        font-size: 17px;
        top: 10px;
        left: 11px;
      }
    }
  }

  &__nav {
    @include mq($until: tablet) {
      text-align: center;
    }

    li {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 30px;
      }
    }

    &--top li {
      @include mq($until: tablet) {
        display: block;
        margin-left: 0;
      }
    }

    a {
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      text-decoration: none;
      color: #fff;
    }

    &--small {
      li:last-child {
        @include mq($until: wide) {
          margin-left: 0 !important;
        }
      }

      a {
        line-height: 21px;
      }
    }
  }

  &__copyright {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 23px;
    @include text-align(right);
    color: #fff;

    @include mq($until: tablet) {
      margin-top: 15px;
      text-align: center;
      line-height: 22px;
    }

    p {
      margin: 0;
    }
  }

  hr {
    width: 100%;
    margin: 30px 0;
    background: #fff;
  }
}
