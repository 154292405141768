@import '../../../../styles/shared';

.c-portfolio-block {
  display: block;
  position: relative;
  box-shadow: 0 0 0 1px #fff;
  transform: translateZ(0px);
  text-decoration: none;
  overflow: hidden;
  background-color: #fff;

  @include mq($until: tablet) {
    height: auto !important;
  }

  @include mq($until: tablet) {
    box-shadow: none;
  }

  &__background-gradient {
    display: none;
  }

  &__background-image,
  &__background-overlay,
  &__background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.7;
  }

  &:hover &__background-overlay,
  &:hover &__background-gradient {
    opacity: 0.5;
  }

  &__background-image {
    background: center no-repeat;
    background-size: cover;
    z-index: 1;
    opacity: 1;
  }

  &__background-gradient {
    z-index: 3;
    background: linear-gradient(to top, #000 0%, rgba(0, 0, 0, 0) 100%);
    transition: opacity 0.3s linear;
  }

  &__background-overlay {
    z-index: 2;
    transition: opacity 0.3s linear;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    padding: 40px;
    text-decoration: none;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 4;
    color: #fff;

    @include mq($until: tablet) {
      position: relative;
      padding: 40px 20px;
    }
  }

  &.featured &__content {
    position: relative;
  }

  &__badge {
    @include mq($until: tablet) {
      margin-bottom: 30px;
    }
  }

  &__data {
    display: table-cell;
    @include text-align(left);
    vertical-align: bottom;
    width: 100%;
  }

  &.featured &__data {
    @include mq($from: tablet) {
      display: flex;
    }
  }

  &.featured &__left {
    @include mq($from: tablet) {
      flex: 50%;
    }
  }

  &.featured &__right {
    @include mq($from: tablet) {
      flex: 50%;
      @include padding-left($gutter-width * 2);
    }
  }

  &__label {
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 30px;
    line-height: 1;

    &:after {
      content: '';
      position: relative;
      bottom: -5px;
      display: block;
      width: 20px;
      height: 3px;
      background: #fff;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    }
  }

  &__heading {
    font-family: 'Noto Sans', sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;

    @include mq($until: tablet) {
      font-size: 20px;
    }
  }

  &__description {
    line-height: 1em;
  }

  &__date {
    display: block;
    font-size: 18px;
    font-weight: 300;
    margin-top: 15px;
  }

  &__address {
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
  }

  &__description {
    display: block;
    margin-top: 20px;
  }

  &__value-proposition {
    display: block;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.33;
    margin-top: 10px;

    @include mq($from: tablet) {
      width: 75%;
    }
  }

  &__upcoming-event-button {
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;
    display: inline-block;
    border: 1px solid #aaaaaa;
    border-radius: 3px;
    margin-top: 30px;
    line-height: 20px;
    padding: 10px 15px;
    vertical-align: middle;

    i {
      vertical-align: top;

      &::before {
        width: 20px;
        height: 20px;
        font-size: 20px;
        @include margin-right(15px);
      }
    }
  }

  &.featured &__heading {
    font-size: 34px;
    line-height: 1.2;

    @include mq($until: tablet) {
      font-size: 20px;
    }
  }

  &.featured &__data {
    vertical-align: top;
  }

  &.featured {
    min-height: 250px;
  }
}
