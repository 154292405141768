@import '../../../styles/shared';

.modal-event-partners {
  width: auto;
  padding: 35px;

  .event-partners {
    &__heading {
      margin: 0 0 20px;
      font-size: 28px;
      font-weight: 300;
      line-height: 1.2;
    }
  }
}
