@import '../../../../../styles/shared';

.delegates-list {
  &-item {
    position: relative;
    @include padding-left(27px);
    margin-bottom: 7px;

    &__label {
      &--completed:before {
        content: '';
        position: absolute;
        @include left(5px);
        top: -2px;
        width: 9px;
        height: 18px;
        border-style: solid;
        color: $form-success-color;

        [dir='ltr'] & {
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        [dir='rtl'] & {
          border-width: 0 0 2px 2px;
          transform: rotate(45deg) scaleX(-1);
        }
      }
    }

    &__link {
      line-height: 34px;

      &--error {
        color: $form-error-color;
      }
    }
  }
}
