@import '../../../../../styles/shared';

.agenda-sponsor-wrapper {
  margin-top: 5px;
  margin-left: -10px;
}

.agenda-item-sponsor {
  display: inline-table;
  @include margin(0, 10px, 10px, 0);
  background: transparent;
  max-width: 120px;
  width: calc(50% - 10px);

  @include mq($from: desktop) {
    width: calc(33% - 10px);
  }

  &__logo {
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 10px;
  }
}
