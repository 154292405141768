@import '../../../../../../styles/shared';

.c-mobile-menu-popup {
  position: absolute;
  top: $TENANT-HEADER-HEIGHT;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: auto;
  height: 0;
  transition: height 0.2s ease;
  background: $tenant-primary-color;

  &--opened {
    height: calc(100vh - #{$TENANT-HEADER-HEIGHT});
  }

  @include mq($from: wide) {
    display: none;
  }

  .nav {
    margin-top: 35px;
    text-align: center;

    ul {
      li {
        margin-bottom: 30px;

        a {
          font-family: $font-family-sans-serif;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }

  .account-widget {
    margin: 35px 0 30px;
    padding-top: 35px;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.05);

    &__link {
      display: inline-block;
      min-width: 150px;
      padding: 9px 15px 7px;
      margin-bottom: 10px;
      font-family: $font-family-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      border: 2px solid #fff;
      border-radius: 20px;
      text-decoration: none;
      color: #fff;

      &--primary {
        border-color: $tenant-accent-color;
        background: $tenant-accent-color;
      }
    }
  }

  .browse-topics {
    $self: '.browse-topics';
    margin-top: 30px;
    text-align: center;

    &__toggle {
      display: inline-block;
      font-family: $font-family-sans-serif;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: #fff;
    }

    &__line {
      margin: 0 29px 0 1px;
      height: 2px;
      background-color: $tenant-accent-color;
    }

    &__icon {
      position: relative;
      top: -2px;
      margin-left: 5px;
      font-size: 24px;
      vertical-align: middle;

      &--open {
        display: initial;
      }

      &--close {
        display: none;
      }
    }

    &--opened #{$self}__icon--open {
      display: none;
    }

    &--opened #{$self}__icon--close {
      display: initial;
    }

    &__list {
      display: none;
      margin-top: 20px;
      margin-bottom: -40px;
      text-align: left;
    }

    &--opened #{$self}__list {
      display: block;
    }
  }
}

.c-mobile-menu-popup {
  .c-l1-topics {
    padding: 0;

    &__title {
      display: none;
    }
  }

  .c-account-widget {
    &__button {
      font-family: $font-family-sans-serif;
      font-size: 20px;
      line-height: 27px;
      color: #fff;

      i {
        top: -2px;
        margin-left: 5px;
        font-size: 16px;
      }
    }

    &__dropdown {
      position: initial;
      display: block;
      width: initial;
      background: none;
      border: none;
      margin-top: 18px;

      &::before {
        display: none;
      }

      a,
      span {
        font-family: $font-family-sans-serif;
        font-size: 20px;
        line-height: 27px;
        border: none;
        color: #a6bcc6;

        &:focus,
        &:hover {
          background: none;
        }
      }
    }
  }

  &__separator {
    border-top: 1px solid rgba(255, 255, 255, 0.05);
  }

  &__search-form {
    margin-top: 30px;
    margin-bottom: 45px;

    .c-search-form-widget {
      padding: 0 $gutter-width;
    }
  }
}
