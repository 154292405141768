@import '../../../../styles/shared';

.c-article-block-heading {
  font-family: $font-family-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  background-color: rgba(#cccccc, 0.2);
  color: $tenant-primary-color;
  padding: 10px 20px;
}

.black-and-red-view {
  .c-article-block-heading {
    border-top-color: #c51f3d;
    color: #333;
  }
}
