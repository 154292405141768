@import '../../../styles/shared';

.agenda-bottom-navigation {
  margin-bottom: 10px;

  .prev-day-btn,
  .next-day-btn {
    height: 100%;

    a {
      display: block;
      @include padding(13px, 20px, 15px, 20px);
      text-decoration: none;

      strong {
        font-size: 13px;
        line-height: 24px;
        text-transform: uppercase;
      }

      span {
        display: block;

        @include mq($until: tablet) {
          &.nav-day-title,
          &.nav-day-date {
            display: none;
          }
        }

        &.nav-day-title {
          color: #fff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.nav-day-date {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }

  .prev-day-btn {
    @include margin-right(-10px);
    [dir='ltr'] & {
      border-right-width: 1px;
      border-right-style: solid;
    }

    [dir='rtl'] & {
      border-left-width: 1px;
      border-left-style: solid;
    }
  }

  .next-day-btn {
    @include margin-left(-10px);
  }
}

.agenda-lead-generation {
  margin-bottom: -20px;
}
