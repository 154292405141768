@import '../../../../../styles/shared';

.c-payment-journey-check-group-input {
  padding: 5px 0;

  .c-payment-journey-check-input {
    margin-bottom: 5px;
  }

  &--inline {
    padding: 5px 0 12px;

    .c-payment-journey-check-input {
      @include mq($from: tablet) {
        display: inline-block;
        @include margin-right(15px);
        margin-bottom: 0;
      }
    }
  }

  &__free-text {
    margin: 5px -5px -5px;
    border-top: 1px solid #000;
  }
}
