@import '../../../styles/shared';

.order-list {
  color: #333;
  word-break: break-word;

  &__delegate-packages {
    background-color: #fff;
    padding: 10px 30px 0;
    margin-bottom: 39px;
  }

  &__title {
    margin: 20px 0 16px;

    @include mq($until: tablet) {
      margin-top: 12px;
    }

    &-type {
      font-weight: bold;
    }
  }
}
