@import '../../../../styles/shared';

.c-form-box-input {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  user-select: none;

  &--disabled &__item {
    cursor: default;
  }

  input {
    display: none;
  }

  &__body {
    display: block;
    position: relative;
    @include padding(10px, 2px, 5px, 0);
    vertical-align: top;
    cursor: pointer;
  }

  &__content {
    @include padding-left(40px);
  }

  &__label {
    display: block;
    font-size: 22px;
    line-height: 24px;

    input:disabled ~ & {
      color: #aaaaaa;
    }
  }

  &--small &__label {
    font-size: 16px;
    line-height: 22px;
  }

  &__description {
    font-weight: 300;
  }

  &--small &__description {
  }

  &--no-checkbox &__box {
    display: none;
  }

  &--no-checkbox &__content {
    @include padding-left(0);
  }

  &__box {
    display: block;
    position: absolute;
    top: 23px;
    @include left(0);

    &::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      @include left(0);
      top: 50%;
      margin-top: -12px;
      border: 1px solid #333;
      background-color: #fff;
    }

    input:checked + & {
      &::before {
        background-color: #333;
      }
    }

    input:disabled + & {
      cursor: default;

      &::before {
        background-color: #e5e5e5;
        border-color: #aaaaaa;
      }
    }
  }

  &--radio &__box {
    &::before {
      border-radius: 50%;
      margin-top: -14px;
    }

    &--small {
      &::before {
        width: 20px;
        height: 20px;
        top: 1px;
        @include left(0);
      }
    }
  }

  &--radio input:checked + &__box {
    &::before {
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      @include left(7px);
      top: -7px;
      border-radius: 50%;
      background-color: #fff;
    }

    &--small {
      &::after {
        @include left(5px);
        top: -8px;
      }
    }
  }

  &--checkbox &__box {
    &::before {
      border-radius: 2px;
      top: -2px;
    }

    &--small {
      &::before {
        width: 20px;
        height: 20px;
        top: -1px;
      }
    }
  }

  &--checkbox input:checked + &__box {
    &::before {
      border-radius: 2px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 6px;
      @include left(6px);
      top: 50%;
      margin-top: -6px;
      [dir='ltr'] & {
        transform: rotate(-45deg);
      }

      [dir='rtl'] & {
        transform: rotate(-45deg) scaleX(-1);
      }
      @include border-left(2px solid #fff);
      border-bottom: 2px solid #fff;
    }

    &--small {
      &::after {
        width: 11px;
        @include left(5px);
        margin-top: -7px;
      }
    }
  }
}

.c-select {
  width: 100%;
  height: 36px;
  border: 1px solid #333;
  border-radius: 3px;
  background: #fff;
  outline: none;
  cursor: pointer;
}
