@import '../../../../../../styles/shared';

.c-fan-expo-hq-footer-calendar {
  @include margin-left(50px);
  @include mq($until: tablet) {
    @include margin(30px, 0, 0, 0);
  }

  &__item {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-name {
    font-weight: bold;
  }
}
