@import '../../../../../../styles/shared';

.c-terms-conditions-text-box {
  color: #000;
  font-size: 14px;
  margin: 24px 0 36px 0;

  &__head {
    font-weight: bold;
    letter-spacing: 0.6px;
    line-height: 19px;
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  &__body {
    line-height: 21px;
    margin: 0;
  }
}
