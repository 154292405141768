@import '../../../../../../styles/shared';

.c-multi-level-nav-list {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.09);

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: $extended-layout-width;
    margin: 0 auto;
    padding: 0 $gutter-width;
  }

  &--level-1-fullscreen &__list,
  &--level-2-fullscreen &__list {
    max-width: none;
  }

  &--level-1 > &__list {
    flex-wrap: nowrap;
  }

  &--level-2 {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &--fixed-state {
    transition: opacity 0s;
    transition-delay: $multi-level-nav-animation-delay;
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-multi-level-nav-list {
    box-shadow: none;

    &--level-1 > &__list {
      @include mq($from: desktop) {
        padding-left: $gutter-width * 0.5 - 3px;
      }
    }

    &--level-2 > &__list {
      padding-left: $gutter-width * 0.5 - 3px;
    }

    &--level-2 {
      background-color: transparent;
    }

    &--fixed-state {
      background-color: initial;
    }
  }
}

@include theme-only($ASIATECHXSG) {
  .c-multi-level-nav-list {
    &--level-2 {
      background-color: initial;
    }
  }
}

@include one-of-themes(
  [$ONEOFAKIND,
  $NAJAHSTACK,
  $ARTTORONTO,
  $THEARTISTPROJECT,
  $INTERIORDESIGN]
) {
  .c-multi-level-nav-list {
    box-shadow: none;

    &--level-2 {
      background-color: rgba(#fff, 0.3);
    }

    &--level-2-fullscreen &__list {
      padding-left: $multi-level-nav-level-1-height +
        $multi-level-nav-underline-width + $gutter-width;
    }
  }
}
