@import '../../../styles/shared';

.c-payment-journey-table {
  & + * {
    margin-top: 15px;
  }

  &__title {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    color: #000;

    span {
      position: relative;
      top: -1px;
      @include margin-left(5px);
      line-height: 13px;
      vertical-align: middle;
    }
  }

  &--collapsible:not(&--has-errors) &__title {
    cursor: pointer;
  }

  &__text {
    font-size: 13px;
    line-height: 18px;
    color: #000;

    * + & {
      margin-top: 10px;
    }
  }

  &__body {
    overflow: hidden;

    * + & {
      margin-top: 15px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
    }

    tbody {
      border-top: 1px solid #000;
      @include border-left(1px solid #000);
    }

    tr {
      display: flex;
      flex-direction: column;

      @include mq($from: tablet) {
        flex-direction: row;
      }
    }

    td {
      display: block;
      flex: 1 1;
      @include border-right(1px solid #000);
      border-bottom: 1px solid #000;
    }
  }

  &--collapsed &__body,
  &--collapsed &__text {
    display: none;
  }

  &--collapsed &__title {
    margin-bottom: 0;
  }
}
