@import '../../../../../styles/shared';

.footer-upper {
  padding: 40px 0;
  color: #fff;

  @include mq($until: tablet) {
    padding: 25px 0;
  }

  &-hashtag {
    margin-bottom: 40px;

    @include mq($until: tablet) {
      margin-bottom: 20px;
    }

    &__link {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      font-weight: 800;
      font-size: 80px;
      line-height: 1;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;

      @include theme-only($DEFAULT) {
        font-family: 'Open Sans', sans-serif;
        font-size: 60px;
        font-weight: 400;
        text-transform: none;

        @include event-only {
          font-family: $font-family-serif;
        }
      }

      @include theme-only($FANEXPOHQ) {
        font-family: 'Proxima Nova ExCn Bl', sans-serif;
      }

      @include mq($until: tablet) {
        font-size: 48px;
        word-break: break-all;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include mq($until: tablet) {
      flex-direction: column;
    }

    &__social {
      &-list {
        width: 50%;

        @include mq($until: tablet) {
          width: 100%;
          margin-bottom: 20px;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      &-link {
        text-decoration: none;
        color: #fff;

        .fab.fa-facebook-f::before {
          position: relative;
          @include right(-3px);
          bottom: -3px;
          font-size: 18px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &-icon {
        display: inline-block;
        text-align: center;
        line-height: 20px;
        width: 20px;
        height: 20px;
        @include margin-right(10px);
        border-radius: 3px;
        background-color: #fff;
      }
    }

    &__mail-phone {
      &-list {
        width: 50%;

        @include mq($until: tablet) {
          width: 100%;
        }
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include mq($until: tablet) {
          align-items: flex-start;
        }

        &:not(:first-child) {
          margin-top: 15px;
        }

        &-title {
          font-weight: 700;
        }
      }

      &-link {
        text-decoration: none;
        color: #fff;
        direction: ltr;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.omdia_footer-upper {
    background-color: #ebebeb;
    color: #334353;

    .footer-upper-hashtag {
      &__link {
        color: #334353;
      }
    }

    .footer-upper-contacts {
      &__social-link,
      &__mail-phone-link {
        color: #334353;
      }
      &__social-icon {
        background-color: transparent;
      }
    }
  }
}
