@import '../../../styles/shared';

.c-profile-data {
  margin: 20px 0;

  .row + .row {
    margin-top: 10px;
  }

  &__heading-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;

    a {
      margin-top: 15px;
      color: $tenant-accent-color;
      text-decoration: none;
    }
  }

  &__full-name {
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 300;
    color: #56595b;
  }
}
