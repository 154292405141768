@import '../../../../styles/shared';

.lead-gen-ad-hoc-questions {
  &__title {
    font-weight: 300;
    font-size: 28px;
    color: #333;
    line-height: 28px;
    margin: 0 0 11px;
    padding-top: 24px;
    &--article {
      font-size: 22px;
    }
  }

  &__subtitle {
    color: $form-helper-color;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 13px;
  }
}

@include theme-only($DEFAULT) {
  .lead-gen-container__form-article {
    .lead-gen-checkbox-ad-hoc__item {
      .lead-gen-checkbox {
        &__label-text {
          font: normal 16px/32px $font-family-sans-serif;
          color: #333;
        }

        &__label-square {
          margin-top: 3px;
        }

        &__label-square-wrapper {
          margin-top: 3px;
        }
      }
    }
  }
}
