@import '../../../styles/shared';

.c-agenda-download-section {
  &.c-event-section {
    padding-top: 50px;
    color: #333;
  }

  &__title {
    border-bottom: 2px solid #333;
    padding-bottom: 21px;
    margin-bottom: 30px;
  }

  &__button {
    width: 100%;
    margin-bottom: 30px;
  }

  .lead-gen-teaser-portrait__image {
    margin: 0;
  }
}
