@import '../../../../../styles/shared';

.c-agenda-group-item {
  margin-top: 10px;
  overflow: hidden;
  position: relative;

  .agenda-group-title-wrapper {
    .agenda-group-title {
      padding: 5px 10px 5px;
      border-bottom: 1px dotted #aaaaaa;
      border-top: 3px solid #aaaaaa;
      position: relative;
      overflow: hidden;
      z-index: 2;
      background-color: #e5e5e5;
      font-weight: 300;
      color: #333;
      @include linear-gradient(#e5e5e5, #f4f4f4);

      .agenda-group-name {
        @include float(left);
        @include margin-right(200px);
        font-size: 14px;
      }

      .agenda-group-amount-description {
        @include float(right);
        width: 180px;
        @include text-align(right);
        font-size: 12px;
        font-weight: 500;
        color: #000;
      }
    }
  }

  .agenda-stream-titles-wrapper {
    background-color: #efefef;
    position: relative;
    z-index: 1;
    top: 0;

    .slick-active > div > div {
      height: 100%;
    }

    .slick-slider {
      button.slick-arrow {
        position: absolute;
        top: 100%;
        background-color: transparent;
        transition: top 100ms linear;

        i {
          font-size: 40px;
          color: #000;
        }
      }
    }

    &.sticked-titles .slick-slider button.slick-arrow {
      top: 0;
      transition: top 100ms linear;
    }
  }

  .agenda-group-wrapper {
    position: relative;
    overflow: hidden;
    margin: 0 -5px;

    .slick-slider {
      .slick-arrow {
        top: 0;
        background-color: rgba(0, 0, 0, 0.12);
        height: 100%;
        width: 40px;
        z-index: 2;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        border: none;
        border-radius: 0;

        i {
          color: transparent;
        }

        &:focus,
        &:hover {
          background-color: rgba(0, 0, 0, 0.09);
          box-shadow: none;
        }

        &:before {
          display: none;
        }

        &.slick-prev {
          left: 5px;
        }

        &.slick-next {
          right: 5px;
        }

        &.slick-disabled {
          display: none !important;
        }
      }
    }
  }

  &__stream-wrapper {
    position: relative;

    &--logo {
      @include padding(5px, 80px, 5px, 0);
      .c-agenda-group-item__stream-free {
        @include right(45px);
      }

      .c-agenda-group-item__custom-label {
        @include right(85px);
      }
    }

    &--no-logo {
      padding: 5px 40px 5px 0;
      @include padding(5px, 40px, 5px, 0);
      .c-agenda-group-item__stream-free {
        @include right(0);
      }
      .c-agenda-group-item__custom-label {
        @include right(40px);
      }
    }
  }

  &__stream-free {
    position: absolute !important;
    top: 5px !important;
  }

  &__custom-label {
    position: absolute !important;
    top: 5px !important;
  }
}
