@import '../../../styles/shared';

$HEADER_HEIGHT: 70px;

.c-account-widget {
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__button {
    display: block;
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    span {
      @include padding-right(15px);
      position: relative;

      &:after {
        content: '\f0d7';
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 14px;
        line-height: 1.4;
        @include margin-left(3px);
        top: -1px;
        @include right(0);
      }
    }

    &.shortName {
      span {
        max-width: 100%;
        @include padding-right(40px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @include padding(1px, 20px, 0, 10px);
        display: inline-block;

        .search-visible & {
          width: calc(100% - 14px);
          @include float(left);
        }

        @include mq($from: $large-desktop-breakpoint) {
          width: 100%;
          @include float(left);
        }

        &:after {
          line-height: $TENANT-HEADER-HEIGHT;
          top: 0;
          @include right(10px);

          @include mq($from: $large-desktop-breakpoint) {
            @include right(0);
          }
        }
      }

      &.isMobile {
        span {
          @include padding-right(25px);

          &:after {
            line-height: inherit;
          }
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 40px;
    @include right(0);
    width: 200px;
    background: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    cursor: pointer;
    z-index: 101;

    &:before {
      content: '';
      position: absolute;
      top: -8px;
      @include right(15px);
      width: 15px;
      height: 15px;
      border-top: 1px solid #dcdcdc;
      @include border-left(1px solid #dcdcdc);
      background-color: #fff;
      [dir='ltr'] & {
        transform: rotate(45deg);
      }

      [dir='rtl'] & {
        transform: rotate(-45deg);
      }
    }

    a,
    span {
      position: relative;
      display: block;
      padding: 15px 10px;
      border-bottom: 1px solid #f4f4f4;
      text-decoration: none;
      font-size: 14px;
      line-height: 1;
      border-radius: 3px;
      color: #002144;
      z-index: 1;

      &:hover {
        background: #f7f7f7;
      }
    }
  }
}
