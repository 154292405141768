@import '../../../../styles/shared';

.lead-gen-profile {
  &__telephone {
    order: 12;
  }

  &__sponsoring {
    background: #f7f7f7;
    //@include padding(18px, 22px, 8px, 22px);
    padding: 18px 22px 8px 22px;
    margin: 12px 0 10px;
    .lead-gen-checkbox__label-text {
      font-weight: 400;
      color: #333;
    }
  }
}
