@import '../../../../styles/shared';

.v-hub-search-view {
  height: 100%;
  display: flex;

  &__filters {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;

    @include mq($until: $extended-layout-width) {
      -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
      max-width: 25%;
    }

    @include mq($until: desktop) {
      display: none;
    }
  }

  &__content {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;

    @include mq($until: $extended-layout-width) {
      -ms-flex-preferred-size: 75%;
      flex-basis: 75%;
      max-width: 75%;
    }

    @include mq($until: $desktop-breakpoint) {
      -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
