@import '../../../styles/shared';

.c-home-hero {
  position: relative;
  background-size: cover;
  background-position: center;
  background-color: $tenant-primary-color;

  @include mq($until: tablet) {
    background-position: 0 0;
  }

  @include mq($from: tablet) {
    background-position: 58% 0;
  }

  &__content {
    padding: 100px 0;

    @include mq($until: tablet) {
      padding: 40px 0;
    }
  }

  &__slogan {
    &-item {
      max-width: fit-content;
      height: 56px;
      padding: 0 25px;
      font-family: $font-family-serif;
      font-size: 36px;
      line-height: 56px;
      border-radius: 33px;
      color: #fff;

      &:not(:first-child) {
        margin-top: 14px;
      }

      &--top {
        background-color: $tenant-primary-color;
      }

      &--middle {
        margin-left: 90px;
        background-color: #fff;
        color: $tenant-primary-color;
      }

      &--bottom {
        margin-left: 30px;
        background-color: $tenant-accent-color;
      }
    }
  }

  &__value-proposition {
    margin-top: 30px;
    font-family: $font-family-sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    text-shadow: 2px 4px 0 rgba(0, 0, 0, 0.04);
    color: #fff;

    @include mq($until: tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__search {
    margin-top: 30px;
  }

  &__video {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute !important;

    overflow: hidden;
    visibility: hidden;
    opacity: 0;

    &.visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 750ms ease;
    }

    &-overlay {
      position: relative;
      z-index: 1;

      &--VIDEO {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.ie {
  .c-home-hero {
    &__slogan {
      &-item {
        line-height: 65px;

        &--top {
          width: 250px;
        }

        &--middle {
          width: 192px;
        }

        &--bottom {
          width: 190px;
        }
      }
    }
  }
}
