@import '../../../../styles/shared';

.c-article-more-items {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-family: $font-family-sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: none;
  transition: background 0.3s ease;
  background: rgba(#cccccc, 0.2);
  color: $tenant-accent-color;

  &:hover {
    background: #f7f7f7;
  }

  i.material-icons {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    text-align: center;
    border-radius: 20px;
    background: $tenant-accent-color;
    color: #fff;
  }
}

.black-and-red-view {
  .c-article-more-items {
    color: #333;

    i {
      color: #c51f3d;
    }
  }
}
