@import '../../../styles/shared';

$transitionTime: 0.2s;

.c-cta {
  display: inline-block;
  font-family: 'Barlow Condensed Extra Bold', sans-serif;
  font-size: 25px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: all $transitionTime linear;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(255, 255, 255, 1);
  outline: none;
  cursor: pointer;
  appearance: none;
  text-transform: uppercase;

  &:hover:not(&--loading) {
    text-decoration: none;
    box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.4);
    border-color: rgba(255, 255, 255, 0.4);
  }

  &:disabled {
    cursor: not-allowed;
  }

  @include mq($until: tablet) {
    width: 100%;
  }

  &--auto-width-mobile {
    @include mq($until: tablet) {
      width: auto;
    }
  }

  &--primary {
    color: #fff;
  }

  &--secondary {
    color: #fff;
  }

  &--accent {
    color: #fff;
  }

  &--hollow {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.2);
    transition: border 0.2s, box-shadow $transitionTime, color $transitionTime,
      background-color $transitionTime;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
      color: rgba(0, 0, 0, 0.8);
    }
  }

  &--tiny {
    font-size: 17px;
    padding: 6px 10px 4px;
  }

  &--small {
    font-size: 18px;
    padding: 10px 10px 7px;
  }

  &--submit,
  &--medium {
    font-size: 24px;
    padding: 10px 18px 10px;

    @include mq($until: wide) {
      font-size: 18px;
    }
  }

  &--submit {
    padding-right: 30px;
    padding-left: 30px;

    @include mq($from: tablet) {
      min-width: 200px;
    }
  }

  &--large {
    font-size: 32px;
    padding: 16px 20px 16px;

    @include mq($until: tablet) {
      font-size: 26px;
    }
  }

  &--no-wrap {
    white-space: nowrap;
  }

  &--loading {
    &,
    &:hover {
      color: #e5e5e5;
      background-color: #aaaaaa;
      cursor: default;
    }
  }

  &--disabled {
    pointer-events: none;
    color: #898989;
    background-color: #ababab;
  }
}

.l-hub {
  .c-cta {
    background-color: $tenant-primary-color;

    &--loading {
      &,
      &:hover {
        color: #e5e5e5;
        background-color: #aaaaaa;
      }
    }
  }
}

@include theme-only($DEFAULT) {
  .c-cta {
    font-weight: 400;
    text-transform: none;
    border: none;

    @include event-only() {
      font-family: $font-family-serif;
    }

    @include course-only() {
      font-family: $font-family-sans-serif;
    }

    &::after {
      position: relative;
      top: -1px;
      display: inline-block;
      content: 'east';
      font-family: 'Material Icons';
      vertical-align: middle;
      transition: transform 0.4s;

      [dir='rtl'] & {
        content: 'west';
      }
    }

    &--small,
    &--tiny {
      padding: 7px 25px 5px;
      font-size: 14px;
      border-radius: 34px;

      &::after {
        margin-left: 10px;
        font-size: 20px;
      }
    }

    &--medium,
    &--large {
      padding: 12px 30px 10px;
      font-size: 18px;
      border-radius: 48px;

      &::after {
        margin-left: 15px;
        font-size: 24px;

        [dir='rtl'] & {
          margin-left: 0;
          margin-right: 15px;
        }
      }
    }

    &:hover,
    &:hover:not(&--loading) {
      border: none;
      box-shadow: none;
      filter: brightness(85%);

      &::after {
        transform: translateX(10px);

        [dir='rtl'] & {
          transform: translateX(-10px);
        }
      }
    }
  }
}

@include theme-only($FANEXPOHQ) {
  .c-cta {
    font-family: 'Proxima Nova ExCn Rg', sans-serif;
    font-weight: bold;
    border-radius: 6px;
    border-color: #000;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    }

    @include mq($until: tablet) {
      width: auto;
    }

    &--tiny,
    &--small,
    &--medium,
    &--large {
      font-size: 21px;
      line-height: 24px;
      padding: 3px 13px 1px;

      @include mq($until: wide) {
        font-size: 21px;
      }
    }

    &--styled {
      font-size: 32px;
      line-height: 36px;
      border-radius: 8px;
      border-width: 2px;
      padding: 4px 20px 1px;
    }
  }
}

@include theme-only($MEFCC) {
  .c-cta {
    font-family: 'Barlow Condensed Regular', sans-serif;
    font-weight: bold;
    border-radius: 6px;
    border-color: #000;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    }

    @include mq($until: tablet) {
      width: auto;
    }

    &--tiny,
    &--small,
    &--medium,
    &--large {
      font-size: 21px;
      line-height: 24px;
      padding: 2px 13px;

      @include mq($until: wide) {
        font-size: 21px;
      }
    }

    &--styled {
      font-size: 32px;
      line-height: 36px;
      border-radius: 8px;
      border-width: 2px;
      padding: 4px 20px 1px;
    }
  }
}

@include theme-only($ASIATECHXSG) {
  .c-cta {
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    text-transform: none;

    &:hover {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3) !important;
    }

    &:hover:not(&--hollow) {
      background-color: #b2019a !important;
    }

    @include mq($until: tablet) {
      width: auto;
    }

    &--primary,
    &--secondary,
    &--accent {
      color: #fff !important;
    }

    &--small,
    &--medium,
    &--large {
      font-size: 18px;
    }

    &--tiny {
      padding: 7px 12px;
      font-size: 15px;
    }

    &--medium,
    &--styled {
      padding: 15px 35px 13px;
    }

    &--large {
      padding: 18px 35px 16px;
    }
  }
}

@include theme-only($ARTTORONTO) {
  .c-cta {
    font-family: 'Barlow Regular', sans-serif;
    font-weight: 400;
    color: #232323;
    font-size: 22px;
    padding: 10px 16px 12px;
    border-color: #232323 !important;
    border-radius: 23px;
    border-width: 1px;
    text-transform: capitalize;
    background-color: transparent !important;
    box-shadow: none !important;

    &:hover {
      box-shadow: none !important;
      background-color: #00ff00 !important;
    }

    &--medium,
    &--small,
    &--tiny {
      padding: 5px 30px;
      font-size: 18px;
      border-radius: 14.5px;
    }

    &--secondary {
      &:hover {
        background-color: #00ff00 !important;
      }
    }

    &--hollow {
      background-color: rgba(255, 255, 255, 0.2) !important;
      color: white;
      border-color: white !important;
      &:hover {
        border-color: white !important;
        color: white !important;
        background-color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
  // Fix is for Windows OS only because new-rail-alphabet font is different on this OS.
  // See task for more detail https://itmebusiness.atlassian.net/browse/CAAS-4458
  .windows {
    .c-cta {
      padding: 11px 16px;

      &--medium,
      &--small,
      &--tiny {
        padding: 5px 30px;
      }
    }
  }

  // It's dirty trick for this task https://itmebusiness.atlassian.net/browse/CAAS-5759
  .c-multi-level-nav-menu {
    &__book-now,
    &__book-now-mobile {
      color: #fff !important;
      border-color: #fff !important;

      &:hover {
        color: #000 !important;
        border-color: #000 !important;
      }
    }
  }
}

@include theme-only($THEARTISTPROJECT) {
  .c-cta {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    color: #232323;
    font-size: 22px;
    padding: 11px 16px 11px;
    border-color: #232323 !important;
    border-radius: 23px;
    border-width: 1px;
    text-transform: capitalize;
    background-color: transparent !important;
    box-shadow: none !important;

    &:hover {
      box-shadow: none !important;
      background-color: #5bcbf5 !important;
    }

    &--medium,
    &--small,
    &--tiny {
      padding: 7px 30px 3px;
      font-size: 18px;
      border-radius: 14.5px;
    }

    &--secondary {
      &:hover {
        background-color: #5bcbf5 !important;
      }
    }

    &--hollow {
      background-color: rgba(255, 255, 255, 0.2) !important;
      color: white;
      border-color: white !important;
      &:hover {
        border-color: white !important;
        color: white !important;
        background-color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
  // Fix is for Windows OS only because new-rail-alphabet font is different on this OS.
  // See task for more detail https://itmebusiness.atlassian.net/browse/CAAS-4458
  .windows {
    .c-cta {
      padding: 11px 16px;

      &--medium,
      &--small,
      &--tiny {
        padding: 5px 30px;
      }
    }
  }

  .c-multi-level-nav-menu {
    &__book-now,
    &__book-now-mobile {
      color: #fff !important;
      border-color: #fff !important;

      &:hover {
        color: #fff !important;
      }
    }
  }
}

@include theme-only($ONEOFAKIND) {
  .c-cta {
    font-family: 'GT America Thin', sans-serif;
    font-weight: 300;
    color: #000;
    font-size: 22px;
    padding: 8px 16px;
    border-color: #000;
    border-radius: 0;
    line-height: 28px;
    border-width: 1px;
    text-transform: capitalize;
    background-color: transparent !important;
    box-shadow: none !important;

    &:hover {
      box-shadow: none !important;
      background-color: #b9d9db !important;
      border: 1px solid #000 !important;
    }

    &--medium,
    &--small,
    &--tiny {
      padding: 3px 32px;
      font-size: 18px;
      line-height: 23px;
      color: #000;
      border-color: #000;
      &:hover {
        color: #000;
        background-color: #b9d9db !important;
      }
    }

    &--secondary {
      color: #005e6e;
      border-color: #005e6e;
      &:hover {
        border-color: #005e6e !important;
      }
    }

    &--hollow {
      background-color: rgba(255, 255, 255, 0.2) !important;
      color: white;
      border-color: white;
      &:hover {
        border-color: white !important;
        color: white !important;
        background-color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }

  // It's dirty trick for this task https://itmebusiness.atlassian.net/browse/CAAS-5696
  .c-multi-level-nav-menu {
    &__book-now,
    &__book-now-mobile {
      color: #fff !important;
      border-color: #fff;

      &:hover {
        color: #000 !important;
      }
    }
  }
}

@include theme-only($NAJAHSTACK) {
  .c-cta {
    font-family: 'Proxima Nova Regular', sans-serif;
    font-weight: 300;
    color: #000;
    font-size: 22px;
    padding: 8px 16px;
    border-color: #000;
    border-radius: 0;
    line-height: 28px;
    border-width: 1px;
    text-transform: capitalize;
    background-color: transparent;
    box-shadow: none !important;

    &:hover {
      box-shadow: none !important;
      border: 1px solid #000 !important;
    }

    &--medium,
    &--small,
    &--tiny {
      padding: 3px 32px;
      font-size: 18px;
      line-height: 23px;
      color: #000;
      border-color: #000;
      &:hover {
        color: #000;
      }
    }

    &--hollow {
      background-color: rgba(255, 255, 255, 0.2) !important;
      color: white;
      border-color: white;
      &:hover {
        border-color: white !important;
        color: white !important;
        background-color: rgba(255, 255, 255, 0.5) !important;
      }
    }

    &--white {
      color: white;
      border-color: white;
      &:hover {
        border-color: white !important;
        color: white !important;
      }
    }
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-cta {
    font-family: 'Gibson SemiBold', sans-serif;
    color: white !important;
    font-size: 18px;
    padding: 11px 28px;
    border-color: #000;
    border-radius: 0;
    border-width: 0;
    text-transform: capitalize;
    background-color: #000 !important;
    box-shadow: none !important;
    font-weight: 500;
    line-height: 24px;

    &:hover {
      box-shadow: none !important;
      background-color: #bde5e1 !important;
      color: #000 !important;
    }

    &--medium,
    &--small,
    &--tiny {
      padding: 2px 38px;
      line-height: 24px;
    }

    &--secondary {
      background-color: #bb84a5 !important;
      color: #000 !important;
      &:hover {
        background-color: #bde5e1 !important;
      }
    }

    &--hollow {
      background-color: #fff !important;
      color: #000 !important;
      &:hover {
        color: #000 !important;
        background-color: #bde5e1 !important;
      }
    }
  }
  // It's dirty trick for this task https://informaukit.atlassian.net/browse/CAAS-4417
  .c-multi-level-nav-menu {
    &__book-now,
    &__book-now-mobile {
      &:hover {
        color: white !important;
        border-color: #000;
        background-color: #000 !important;
      }
    }
  }
}
