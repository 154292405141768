@import '../../../../styles/shared';

.c-speaker-block {
  display: block;
  padding: 25px;
  margin-bottom: $gutter-width;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  text-decoration: none;
  background: #fff;
  color: inherit;

  @include theme-only($DEFAULT) {
    border-radius: 16px;
  }

  @include theme-only($ASIATECHXSG) {
    color: $body-color !important;
  }

  @include mq($until: tablet) {
    margin-bottom: $gutter-width;
  }

  &__content {
    @include padding-left(105px);
  }

  &__photo {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: center no-repeat;
    background-size: cover;
  }

  &__name {
    font-weight: bold;
  }

  &__job {
    @include mq($until: tablet) {
      height: auto !important;
    }
  }

  &__company {
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;

    @include mq($until: tablet) {
      height: auto !important;
    }
  }

  &__company-hide {
    visibility: hidden;
  }
}
