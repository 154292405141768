@import '../../../../styles/shared';

.c-billing-form-field {
  user-select: none;
  position: relative;

  &.has-error {
    background-color: $form-light-error-color;

    textarea {
      background-color: #fff;
    }

    .p-one-email-field & .error {
      position: relative;
      display: block;
      top: -5px;
      padding-bottom: 0;
    }

    .label-container {
      background-color: $form-light-error-color;
    }
  }

  .label-container {
    .error,
    .warning {
      padding: 5px;
    }
  }

  label {
    display: block;
    font-size: 12px;
    padding: 5px;
    color: #000;
  }

  &__sub-label {
    padding: 0 5px 5px;
    color: $form-helper-color;
    font-size: 13px;
    line-height: 18px;
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  input,
  textarea,
  select {
    font-size: 12px;
    background-color: transparent;
    color: #000;
    width: 100%;
    height: 30px;
    padding: 5px;
    border: none;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    @include mq($until: tablet) {
      font-size: 16px;
    }
  }

  select:focus {
    @include mq($until: tablet) {
      font-size: 16px;
    }
  }

  textarea {
    width: 100% !important;
    min-height: 50px;
    margin-bottom: -6px;
  }

  select {
    option[value=''] {
      display: none;
    }
  }

  .error,
  .warning {
    padding: 5px;
    font-size: 12px;
    color: $form-error-color;
  }
}

.c-billing-form-check {
  user-select: none;

  & + & {
    margin-top: 8px;
  }

  input {
    position: relative;
    top: 1px;
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
  }

  .label {
    display: inline-block;
    font-size: 16px;
    position: relative;
    @include padding-left(8px);
    color: #000;
    cursor: pointer;
    top: -1px;
  }
}

.c-billing-form-multiple-check,
.c-billing-form-radio-group {
  &.has-error {
    background-color: $form-light-error-color;

    .label-container {
      background-color: $form-light-error-color;
    }
  }

  .label-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .error,
    .warning {
      padding: 5px;
    }
  }

  .c-billing-form-check {
    margin-top: 0;
    @include margin-left(5px);

    input {
      vertical-align: top;
      margin-top: 6px;
    }

    .label {
      font-size: 12px;
      font-weight: 400;
      padding-top: 10px;
      padding-bottom: 10px;
      top: 0;
      max-width: calc(100% - 24px);
    }
  }
}

.c-hidden-component {
  position: relative;

  label {
    display: block;
    font-size: 12px;
    padding: 5px;
    color: #000;
  }

  &.has-error .label-container {
    background-color: $form-light-error-color;
  }

  .error {
    font-size: 12px;
    color: $form-error-color;
  }
}

.c-billing-form-optional-radio {
  position: relative;
  padding-bottom: 10px;
  user-select: none;

  &.has-error {
    background-color: $form-light-error-color;
  }

  .error,
  .warning {
    padding: 5px;
    font-size: 12px;
    color: $form-error-color;
  }

  label {
    display: inline-block;
    font-size: 12px;
    padding: 5px;
    color: #000;

    &.label {
      display: block;
      margin-bottom: 5px;
    }
  }

  .input-radio {
    display: inline-block;
    @include padding-left(3px);

    input {
      @include margin-right(10px);
    }
  }

  .input-text {
    display: inline-block;
    margin: 0 15px;
    width: 200px;

    input {
      width: 100%;
      height: 30px;
      padding: 0 10px 1px 10px;
      font-size: 12px;
      border: 1px solid #aaaaaa;
      border-radius: 6px;
      background-color: #fff;
      color: #000;

      &:disabled {
        background-color: #e5e5e5;
        border-color: #aaaaaa;
        color: #aaaaaa;
      }
    }
  }
}
