@import '../../../../../styles/shared';

.c-search-content-lead-more {
  position: relative;
  width: 100%;
  height: 100px;
  border-radius: 0 0 10px 10px;
  background-color: #333;
  color: #fff;
  font-family: $font-family-serif;
  font-size: 31px;
  line-height: 42px;
  padding: 20px 0 0 20px;
  cursor: pointer;

  @include mq($until: tablet) {
    margin-bottom: 30px;
  }

  &__icon {
    width: 40px;
    height: 40px;
    position: absolute;
    background-color: $tenant-accent-color;
    color: #fff;
    font-size: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -18px;
    left: 20px;
  }
}
