@import '../../../../styles/shared';

.c-tier-save-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 0 30px;
  border-top: 3px solid transparent;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  cursor: pointer;

  @include mq($until: desktop) {
    display: block;
  }

  &__icon {
    @include margin-right(10px);
    height: 27px;

    @include mq($until: desktop) {
      @include float(left);
      font-size: 27px;
      height: auto;
    }
  }

  &__content {
    font-size: 23px;
    font-weight: 300;
    line-height: 1.4;
  }

  &__tooltip {
    width: 300px;
    opacity: 1 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

    &:after {
      display: none;
    }

    @include mq($until: tablet) {
      display: none !important;
    }
  }

  b {
    font-weight: bold;
  }
}
