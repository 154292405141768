@import '../../../styles/shared';

$modal-overlay-color: rgba(11, 11, 11, 0.8);
$modal-white-overlay-color: rgba(255, 255, 255, 0.7);

.c-co-lo-agenda-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1001;

  &__wrapper {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    min-height: 100%;
    padding: 20px 0;

    &--top {
      align-items: flex-start;
    }

    &--center {
      align-items: center;
    }
  }

  &__overlay {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $modal-overlay-color;

    .white-overlay & {
      background-color: $modal-white-overlay-color;
    }
  }

  &__container {
    position: relative;
    width: 95%;
    max-width: 768px;
    background-color: #fff;
    color: #000;

    .white-overlay & {
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.4);
    }

    @include mq($until: tablet) {
      width: 92%;
    }
  }

  &__top-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include padding(0, 15px, 0, 20px);
  }

  &__close {
    height: 21px;
    line-height: 14px;
    margin: 20px 0;
    font-size: 46px;
    font-weight: 300;
    color: #fff;
    cursor: pointer;
    overflow: hidden;
  }
}
