@import '../../../styles/shared';

$agenda-keynote-sessions-bottom: 20px;
$agenda-keynote-sessions-image: 88px;

.agenda-keynote-sessions {
  padding: 40px 0;

  .body-width > div {
    @include padding(0, 0, 0, $agenda-keynote-sessions-bottom);
    margin-left: 0;
    margin-right: 0;

    > div {
      @include padding-left(0);
      @include padding-right(20px);
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: 21px;
    font-weight: 700;

    @include mq($until: tablet) {
      font-size: 18px;
    }

    @include theme-only($ASIATECHXSG) {
      color: $body-color;
    }
  }

  &__box {
    background: #fff;
    color: #000;
    padding: $agenda-keynote-sessions-bottom;
    margin-bottom: $agenda-keynote-sessions-bottom;
    height: calc(100% - #{$agenda-keynote-sessions-bottom});
    min-height: $agenda-keynote-sessions-image + $agenda-keynote-sessions-bottom;
    cursor: pointer;

    &-image {
      @include float(left);
      width: $agenda-keynote-sessions-image;
      height: $agenda-keynote-sessions-image;
      background-position-x: center;
      background-position-y: top;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
    }

    &-content {
      @include margin-left(
        $agenda-keynote-sessions-image + $agenda-keynote-sessions-bottom
      );
      min-height: $agenda-keynote-sessions-image;

      &-speaker {
        padding-bottom: 16px;
      }
      &-title {
        padding-bottom: 16px;
        font-weight: 700;
      }
      &-company {
        padding-bottom: 16px;
        font-size: 13px;
      }
    }
  }
}
