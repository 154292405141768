@import '../../../../../styles/shared';

.c-redux-form-input {
  &__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 11px;
    font-size: 16px;
    line-height: 19px;
    color: #3a3a3a;
  }

  &--type-hidden &__label {
    padding-bottom: 0;
  }

  &__input {
    display: flex;
    flex-direction: row;

    input {
      width: 100%;
      padding: 10px 12px;
      color: #333;
      background: #fff;
      border: 1px solid #dee0e5;
      border-radius: 5px;
      outline: none;

      &:focus {
        border-color: #5e9ed6;
      }
    }

    &--with-btn input {
      [dir='ltr'] & {
        border-radius: 5px 0 0 5px;
      }

      [dir='rtl'] & {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  &__show-password-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 52px;
    padding: 0 10px;
    font-size: 12px;
    text-align: center;
    background: #fff;
    border: 1px solid #dee0e5;
    @include border-left(0);
    [dir='ltr'] & {
      border-radius: 0 5px 5px 0;
    }

    [dir='rtl'] & {
      border-radius: 5px 0 0 5px;
    }
    user-select: none;
    cursor: pointer;
  }

  &__strength-bar {
    margin-top: 6px;
  }

  &--error &__input input {
    border-color: $form-error-color;
  }

  &__error {
    font-size: 12px;
    line-height: 19px;
    color: $form-error-color;
  }
}
