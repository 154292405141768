@import '../../../styles/shared';

.c-l2-topics {
  padding: 0 0 70px;

  @include mq($until: tablet) {
    padding-bottom: 0;
  }

  .container {
    max-width: $extended-layout-width;
  }

  &__mobile-view {
    margin-top: 20px;
    padding: 10px $gutter-width;
    background-color: #dbe4e8;

    h4 {
      margin: 15px 0 5px;
      font-family: $font-family-sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #333333;
    }

    ul {
      li {
        & + li {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
