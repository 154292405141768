@import '../../../../styles/shared';

.c-l1-topic-box-inline {
  display: block;
  padding: 15px 0 13px;
  font-family: $font-family-serif;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  color: $tenant-primary-color;
}
