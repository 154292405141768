@import '../../../styles/shared';

.c-portfolio-table {
  padding: 1px 0 ($gutter-width * 2);

  .container {
    @include mq($from: tablet) {
      padding: 0 10px;
    }
  }

  &__content {
    & > .row > div {
      padding: 0;
    }
  }

  &__item {
    height: 100%;

    &--first-child {
      .c-portfolio-block__content {
        padding: 35px;
      }
    }

    @include mq($until: tablet) {
      margin-left: -$gutter-width * 0.5;
      margin-right: -$gutter-width * 0.5;
    }
  }
}
