@import '../../../../styles/shared';

.c-co-lo_agenda-grid-view {
  position: relative;
  margin-bottom: 16px;
  background-color: #efefef;
  background-image: url('../../../../assets/images/co-located-agenda-grid-bg.png');
  will-change: transform;
  transition: 0.4s ease;
}
