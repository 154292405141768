@import '../../../../styles/shared';

.c-cookie-message {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 100px;
  background-color: #333;
  transition: top 0.15s ease, opacity 0.15s ease;
  opacity: 0;
  z-index: 1002;

  @include mq($until: tablet) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 15px 30px;
  }

  &__text {
    @include padding-right(15px);
    color: #fff;

    @include mq($until: tablet) {
      padding-bottom: 10px;
    }
  }

  &__link {
    text-decoration: underline;
    color: #fff;
  }

  &__button {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
    color: #fff;

    &-icon {
      font-size: 30px;

      @include mq($until: tablet) {
        position: relative;
        @include left(-6px);
      }
    }

    &-text {
      font-size: 18px;
      font-weight: 700;

      [lang='vn'] & {
        white-space: nowrap;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 99;

    @include mq($from: tablet) {
      display: none;
    }
  }

  &__placeholder {
    transition: height 0.15s ease;
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-cookie-message {
    padding: 10px 20px;
    background-color: #757575;

    &__text {
      padding: 0;
      font-size: 14px;
      line-height: 19px;
      align-self: flex-start;
      text-align: justify;

      @include mq($from: tablet) {
        @include padding-right(40px);
      }
    }

    &__button {
      position: relative;
      bottom: -27px;
      margin-top: -17px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      align-self: flex-end;

      @include mq($until: tablet) {
        margin-top: -30px;
      }

      &-icon {
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #757575;
        border-radius: 50%;

        @include mq($until: tablet) {
          @include left(0);
        }
      }

      &-text {
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
      }
    }
  }
}
