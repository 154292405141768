@import '../../../styles/shared';

.l-site {
  display: flex;
  flex-direction: column;
  color: #333;
  background-color: #efefef;

  &__fixed-multi-level-nav {
    display: none;
    @include mq($from: $desktop-breakpoint) {
      display: block;
    }
  }

  @include theme-only($ASIATECHXSG) {
    font-family: 'Noto Sans', sans-serif;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__sticky {
    z-index: 1000;
  }

  &__sticky-wrapper {
    > div {
      transform: none !important;
    }
  }

  &__no-sections-in-preview {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #a4abae;
    border-radius: 4px;
    color: #000;

    h4 {
      font-size: 22px;
    }

    p {
      margin-top: 5px;
      color: #333;
    }
  }
}

.scroll-prevented {
  .l-site__sticky {
    @include mq($until: tablet) {
      transform: none !important;
    }
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .l-site {
    &__content {
      background-color: #fff;
    }
  }
}

.l-site--purchase {
  .l-site__content {
    display: flex;
  }
}

@include one-of-themes([$ARTTORONTO, $THEARTISTPROJECT]) {
  .l-site {
    &__fixed-multi-level-nav {
      display: none;
      @include mq($from: $desktop-breakpoint + 1) {
        display: block;
      }
    }
  }
}
