@import '../../../../styles/shared';

.c-form-message {
  font-size: 16px;
  margin-bottom: 20px;

  &.default {
    color: #56595b;
  }

  &.success {
    color: $form-success-color;
  }

  &.warning {
  }

  &.danger {
    color: $form-error-color;
  }
}
