@import '../../../styles/shared';

.c-user-photo-form {
  height: 100%;

  &__block {
    @include margin-right(60px);
    padding: 10px;
    height: 100%;
    text-align: center;
    position: relative;

    @include mq($until: tablet) {
      margin-left: 80px;
      margin-right: 80px;
    }

    .photo-form-loading {
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
      text-align: center;
      overflow: hidden;
      position: absolute;
      top: 0;
      @include left(0);
      z-index: 2;

      &:after {
        display: block;
        content: 'loading...';
        margin-top: 50%;
        color: #fff;
      }
    }

    .c-user-photo {
      margin: 20px auto 30px;
      width: 100%;
      padding-top: 100%;
      background-color: #dcdcdc;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      border: 1px solid #dcdcdc;
      position: relative;
    }

    .edit-photo-error {
      margin: 20px;
      font-size: 12px;
      color: $form-error-color;
    }

    .edit-image {
      border-radius: 50%;
      background: #999999;
      width: 42px;
      height: 42px;
      position: absolute;
      bottom: 32px;
      @include right(10px);
      border: 2px solid #efefef;
      padding: 0;
      transition-duration: 0s;

      @include mq($until: tablet) {
        @include right(20px);
        bottom: 40px;
      }

      &:before {
        color: #fff;
        font-family: 'Material Icons';
        font-size: 20px;
        line-height: 20px;
        vertical-align: middle;
        @include padding-right(5px);
        content: '\E3C9';
        position: relative;
        @include left(3px);
      }

      &:hover {
        transition-duration: 0s;
      }
    }
  }
}
