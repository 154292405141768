@import '../../../styles/shared';

$main-menu-height: 65px;
$main-menu-mobile-height: 55px;

$sidebar-width: 320px;
$navigation-height: 45px;

$navigation-font-size: 14px;

$border-color: #e5e5e5;
$sidebar-background-color: #fff;
$content-background-color: #efefef;
$content-padding: $gutter-width;

.c-filterable-content {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .container,
  .container-fluid {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    flex-grow: 1;

    & > .row {
      flex-grow: 1;
    }

    .body-width__row {
      margin: 0;
      padding: 0;
    }
  }

  .container-fluid {
    padding: 0;
  }

  .sticky-container {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    flex-grow: 1;
  }

  &__day-filter {
    padding: 10px 0;
    background: #000;

    @include mq($until: tablet) {
      display: none;
    }

    ul {
      li {
        display: inline-block;
        @include margin-right(15px);

        &.active {
          a {
            border: 1px solid #fff;
          }
        }

        a {
          display: inline-block;
          padding: 3px 10px;
          border: 1px solid transparent;
          border-bottom-color: #fff;
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }

  .navigation-item {
    .c-sidebar-filter {
      margin-top: 0;
    }
  }

  &__navigation {
    flex: 0 0 $navigation-height;
    border-bottom: 1px solid $border-color;
    background: #fff;
    z-index: 3;

    & > .container {
      @include mq($until: tablet) {
        padding: 0;
      }
    }

    &.fixed {
      top: $main-menu-height !important;

      @include mq($until: wide) {
        top: $main-menu-mobile-height !important;
      }
    }

    .sidebar-hidden &__body__column--left {
      @include mq($from: tablet) {
        flex: 0 0 auto;
        @include border-right(none);
        @include text-align(left);
      }
    }

    .sidebar-hidden &--fixed &__body__column--left {
      @include mq($from: tablet) {
        @include padding-left(0);
      }
    }

    &--fixed &__body {
      @include mq($from: tablet) {
        padding: 0;
      }
    }

    &--fluid &__body__column--left {
      max-width: $sidebar-width - $gutter-width;
      min-width: $sidebar-width - $gutter-width;
    }

    .sidebar-hidden &__body__column--left {
      min-width: auto;
    }

    &__body {
      display: flex;
      flex-direction: row;
      padding: 0 $gutter-width;

      @include mq($until: tablet) {
        justify-content: space-between;
      }

      &__column {
        &--left {
          max-width: $sidebar-width;
          min-width: $sidebar-width;
          @include text-align(right);
          padding: 0;

          @include mq($until: tablet) {
            display: flex !important;
            width: 50%;
            flex: 0 0 50%;
            @include text-align(left);
            padding: 0;
            min-width: auto !important;

            div.navigation-item {
              font-size: $navigation-font-size + 2;
              @include text-align(left);
            }
          }
        }

        &--right {
          @include text-align(right);
          padding: 0;

          @include mq($until: tablet) {
            min-width: 140px !important;
          }

          [lang='en'] & {
            min-width: 260px;
          }

          [lang='es'] & {
            min-width: 360px;
          }

          [lang='br'] &,
          [lang='pt'] & {
            min-width: 500px;
          }

          [lang='fr'] & {
            min-width: 430px;
          }

          [lang='de'] & {
            min-width: 350px;
          }

          [lang='ru'] & {
            min-width: 240px;
          }

          div.navigation-item {
            font-size: $navigation-font-size + 2;
          }

          @include mq($until: tablet) {
            width: 40%;
            flex: 0 0 40% !important;
            padding: 0;
          }
        }

        &--center {
          flex: 1 0;
          font-size: $navigation-font-size;
          @include padding(13px, 0, 10px, 20px);

          @include mq($until: tablet) {
            display: none;
          }
        }
      }

      .navigation-item {
        font-size: $navigation-font-size;
        @include text-align(right);
        vertical-align: top;
        display: inline-block;
        user-select: none;
        color: #333;

        @include mq($until: tablet) {
          font-size: 14px;
        }

        &:first-child {
          @include margin-left(0);
        }

        &--day-filter {
          display: none;

          @include mq($until: tablet) {
            @include margin-left(0);
            display: inline-block;
          }
        }

        i {
          position: relative;
          top: 5px;
          font-size: 20px;
          max-width: 20px;
          [dir='rtl'] & {
            transform: rotate(180deg);
          }
        }

        .c-navigation-dropdown {
          &__icon {
            [dir='rtl'] & {
              transform: none;
            }
          }
          &.opened &__icon {
            [dir='rtl'] & {
              transform: rotate(180deg);
            }
          }
        }

        & > div + div {
          @include margin-left(10px);
        }

        .toggle {
          display: inline-block;
          line-height: $navigation-height;
          cursor: pointer;
          user-select: none;

          &--view-type {
            i {
              font-size: 24px;
              top: 6px;
            }
          }
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
  }

  &__sidebar {
    flex: 0 0 $sidebar-width;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;
    background: $sidebar-background-color;
    padding: 0;
    max-width: none;

    @include mq($until: tablet) {
      display: none;
    }

    .c-sidebar-filter__content {
      padding-top: 0;

      &-group-list {
        padding: 0 20px;
      }
    }
  }

  &__content {
    flex: 1;
    padding: $content-padding 0;
    background: $content-background-color;
    overflow: hidden;
    position: relative;

    @include mq($until: tablet) {
      padding: 0 !important;
    }
  }

  &.sidebar-visible &__content {
    @include padding-left($content-padding);
  }

  &.sidebar-hidden .container-fluid .c-agenda-list-view,
  &.sidebar-hidden .container-fluid .c-agenda-grid-view {
    padding-left: $content-padding;
    padding-right: $content-padding;
  }
  &.sidebar-visible .container-fluid .c-agenda-list-view,
  &.sidebar-visible .container-fluid .c-agenda-grid-view {
    @include padding-right($content-padding);
  }

  &.sidebar-hidden &__sidebar {
    @include mq($from: tablet) {
      display: none;
    }
  }

  .body-width__row > .col-xs-12 {
    padding-left: 0;
    padding-right: 0;
  }

  &.sidebar-visible {
    @include mq($from: tablet) {
      [dir='ltr'] & {
        background: linear-gradient(
          to right,
          $sidebar-background-color 0%,
          $sidebar-background-color 50%,
          $content-background-color 50%,
          $content-background-color 100%
        );
      }

      [dir='rtl'] & {
        background: linear-gradient(
          to left,
          $sidebar-background-color 0%,
          $sidebar-background-color 50%,
          $content-background-color 50%,
          $content-background-color 100%
        );
      }
    }
  }

  .c-sidebar-filter {
    @include mq($until: tablet) {
      height: $navigation-height;
    }

    &__content {
      @include mq($until: tablet) {
        height: $navigation-height;

        &-search {
          margin: 0;
        }
      }
    }

    @include mq($from: tablet) {
      max-height: calc(100vh - #{$main-menu-height + $navigation-height});
      overflow: auto;
    }
  }

  .mobile-current-day-header {
    background: #e5e5e5;
    padding: 10px;
    display: none;

    @include mq($until: tablet) {
      display: block;
    }

    .heading {
      color: #333;

      &__name {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 400;
        display: block;
      }

      &__data {
        font-size: 12px;
        font-weight: 300;

        &::before {
          content: '';
        }
      }
    }
  }
}

.l-site.l-site--agenda,
.l-site.l-site--speakers {
  display: -ms-flexbox;

  @include mq($from: tablet) {
    flex-grow: 1;
    min-height: auto;
  }

  .l-site-wrapper {
    @include mq($from: tablet) {
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      flex-grow: 1;
      min-height: 100vh;
    }
  }

  .l-site__body {
    @include mq($from: tablet) {
      display: -ms-flexbox;
    }
  }

  .l-site__content {
    @include mq($from: tablet) {
      display: flex;
      display: -ms-flexbox;
    }
  }
}
