@import '../../../../../styles/shared';

.c-search-content-view-article {
  padding: 0 20px 60px 20px;
  background: linear-gradient(180deg, #e7e7e7 0%, rgba(255, 255, 255, 0) 100%);

  @include mq($until: tablet) {
    padding: 0;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;

    @include mq($until: tablet) {
      flex-wrap: nowrap;
      padding: 0 10px 30px 10px;
    }
  }

  &__preview-mode--more-four {
    justify-content: space-between;

    @include mq($until: $extended-layout-width) {
      justify-content: flex-start;
    }

    @include mq($until: tablet) {
      overflow-x: auto;
    }
  }

  &__preview-mode--less-four {
    justify-content: flex-start;

    @include mq($until: tablet) {
      overflow-x: auto;
    }
  }

  &__view-mode--less-four {
    justify-content: flex-start;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__view-mode--more-four {
    justify-content: flex-start;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }
}
