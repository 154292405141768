@import '../../../../../../styles/shared';

.c-site-lower-footer-logo {
  display: block;

  &__img {
    display: block;
    height: 55px;
  }
}
