@import '../../../../styles/shared';

.discount-message {
  padding: 10px 0;

  &__container {
    display: flex;
    padding: 10px 0;
  }

  &__icon {
    display: flex;
    align-items: center;
    max-height: 51px;
    font-size: 30px;
    color: #70cb14;
    @include margin-right(10px);

    &--error {
      color: #c9474d;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
    color: #70cb14;

    @include mq($until: tablet) {
      font-size: 18px;
      line-height: 1.2;
    }

    &--error {
      color: #c9474d;
    }
  }

  &__text {
    margin: 0;
    color: #333;

    @include mq($until: tablet) {
      padding-top: 15px;
    }

    a {
      color: #333;
    }
  }
}
