@import '../../../../styles/shared';

.c-co-lo-agenda-session-time {
  line-height: 15px;
  @include margin-right(5px);

  &--small {
    font-size: 13px;
  }

  &--medium {
    font-size: 16px;
  }
}
