@import '../../../../styles/shared';

.c-site-agenda {
  &__heading-name {
    font-weight: 700;

    @include theme-only($ASIATECHXSG) {
      color: $body-color;
    }
  }

  &__heading-data {
    font-weight: 500;

    @include theme-only($ASIATECHXSG) {
      color: $body-color;
    }

    &::before {
      content: ' - ';
    }
  }

  &__heading-search-result {
    color: rgb(51, 51, 51);
    font-size: 14px;
    font-weight: 700;
  }
}

.body-width {
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;
}
