@import '../../../../../styles/shared';

.c-co-lo-agenda-session-info {
  padding: 30px;
  margin-bottom: 20px;

  &__format {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 10px;
  }

  &__title {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.25;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  &__date {
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }

  &__formatted-date {
    @include margin-right(5px);
  }

  &__event-info {
    font-size: 14px;
    margin-top: 20px;
    @include margin-left(10px);
  }

  &__event-name {
    font-weight: bold;
    @include margin-left(5px);
  }
}

@each $index, $color in $streamColors {
  .c-co-lo-agenda-session-info[data-color-index='#{$index}'] {
    .c-co-lo-agenda-session-info__event-name {
      color: $color;
    }
  }
}
