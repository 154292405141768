@import '../../../../../styles/shared';

.lead-gen-input-pass__strength {
  margin: 15px 0 0 0;

  &-text {
    display: inline-block;
    //@include margin(5px, 8px, 5px, 0);
    margin: 5px 8px 5px 0;
    color: $form-helper-color;
    font-size: 12px;
    line-height: 14px;

    span {
      font-weight: 600;
    }
  }

  &-list {
    display: block;
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  &-item {
    //@include float(left);
    //@include margin(0, 0, 8px, 0);
    float: left;
    margin: 0 0 8px 0;
    padding: 0;
    width: 50%;
    font-size: 12px;
    line-height: 18px;

    &:before {
      color: #626262;
      font-family: 'Material Icons';
      font-size: 12px;
      line-height: 13px;
      vertical-align: middle;
      //@include padding-right(5px);
      padding-right: 5px;
    }

    &.invalid {
      color: #626262;

      &:before {
        content: '\E5CD';
        color: #626262;
      }
    }

    &.valid {
      color: #31b03f;

      &:before {
        content: '\E5CA';
        color: #31b03f;
      }
    }
  }

  @media screen and (min-width: $tablet-breakpoint) {
    &-list {
      display: inline-block;
      vertical-align: text-bottom;
    }

    &-item {
      width: auto;
      //@include padding-left(15px);
      padding-left: 15px;
      margin: 0;

      &:first-of-type {
        //@include padding-left(0);
        padding-left: 0;
      }
    }
  }
}

@include theme-only($KNECT365) {
  .lead-gen-input-pass__strength-text span {
    font-weight: 500;
  }
}
