@import '../../../../styles/shared';

.c-base-section-wrapper {
  position: relative;
  background: center no-repeat;
  background-size: cover;

  &--zero {
    padding: 0;
  }

  &--tiny {
    padding: 20px 0;
  }

  &--small {
    padding: 40px 0;
  }

  &--medium {
    padding: 40px 0;

    @include mq($from: tablet) {
      padding: 60px 0;

      @include one-of-themes([$ONEOFAKIND, $ARTTORONTO, $NAJAHSTACK]) {
        padding: 80px 0;
      }
    }
  }

  &--large {
    padding: 40px 0;

    @include one-of-themes([$FANEXPOHQ, $MEFCC]) {
      padding: 65px 0;
    }

    @include mq($from: tablet) {
      padding: 60px 0;

      @include one-of-themes([$FANEXPOHQ, $MEFCC]) {
        padding: 80px 0;
      }
    }
  }

  &--huge {
    padding: 65px 0;

    @include mq($from: tablet) {
      padding: 100px 0;
    }
  }
}
