@import '../../../styles/shared';

$linkedin-icon-color: #3079c1;
$twitter-icon-color: #00b9f9;
$facebook-icon-color: #3c5a96;

.c-person-content-section {
  &__wrapper {
    margin-top: $gutter-width;
    margin-bottom: $gutter-width;

    @include mq($until: tablet) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  @include mq($from: tablet) {
    .separate_blocks {
      .person-content-table:nth-child(n + 2) {
        margin-top: 10px;
      }
    }
  }

  &__column {
    margin-top: $gutter-width;

    &:last-child {
      margin-bottom: $gutter-width;
    }
  }

  &__container {
    padding: 30px 20px;
  }

  &__contacts {
    &-item:not(:first-child) {
      margin-top: 15px;
    }

    &-icon {
      @include margin(0, 35px, 0, 10px);
      width: 15px;

      &.fa-linkedin-in {
        color: $linkedin-icon-color;
      }

      &.fa-twitter {
        color: $twitter-icon-color;
      }

      &.fa-facebook-f {
        color: $facebook-icon-color;
      }

      &.fa-globe {
        color: $facebook-icon-color;
      }

      &.fa-at {
        color: #626262;
      }

      body:not(.ie) &.fa-instagram {
        background: linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
