@import '../../../../../../styles/shared';

.c-search-content-agenda {
  width: 49%;
  margin-bottom: 20px;
  text-decoration: none;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 0 0 10px 10px;

  &__default {
    background-color: #f5f5f5;
    height: 24px;
    margin-bottom: 20px;

    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 100%;
    }
  }

  &--preview-mode {
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }

    @include mq($until: $extended-layout-width) {
      width: 100%;

      &:nth-child(3) {
        margin-bottom: 20px;
      }
    }

    @include mq($until: tablet) {
      min-width: 226px;
      margin: 0 20px 0 0;
      &:last-child,
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }

  &--view-mode {
    @include mq($until: $extended-layout-width) {
      width: 100%;
    }

    @include mq($until: tablet) {
      flex-direction: column;
      min-width: 228px;
      margin: 0 0 20px 0;
      &:last-child,
      &:nth-child(3) {
        margin-bottom: 20px;
      }
    }
  }

  &__title {
    display: inline-block;
    color: $tenant-search-text-color;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 20px;
    text-decoration: none;
  }

  &__event {
    color: $tenant-search-text-color;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    line-height: 24px;

    &-name {
      margin-left: 5px;
      color: $tenant-search-text-color;
      text-decoration: underline;

      em {
        background-color: rgba(204, 49, 194, 0.2);
        color: #000;
      }
    }
  }
}
