@import '../../../../styles/shared';
$main-menu-height: 70px;
$navigation-height: 45px;

.c-co-lo-agenda-search {
  position: relative;

  &__input {
    display: block;
    width: 100%;
    height: 55px;
    @include padding(0, 46px, 0, $gutter-width);
    font-size: 15px;
    background: #f7f7f7;
    border: 0;
    outline: none;
    color: #56595b;
    @include mq($until: tablet) {
      height: 45px;
    }
  }

  &__icon {
    position: absolute;
    font-size: 26px;
    top: 15px;
    @include right(30px);

    @include mq($from: tablet) {
      @include right(10px);
    }

    @include mq($until: tablet) {
      position: absolute;
      top: 10px;
      @include right(15px);
    }
  }

  .close {
    cursor: pointer;
  }
}
