@import '../../../../../styles/shared';

.lead-gen-input-pass__progress {
  margin-top: 15px;

  &-text {
    margin: 5px 0;
    color: $form-helper-color;
    font-size: 14px;
  }

  &-wrapper {
    height: 10px;
    background: #f7f7f7;
    border: 1px solid #dee0e5;

    .bad {
      background: #c9474d;
      width: 33%;
    }

    .average {
      background: #f5aa2f;
      width: 66%;
    }

    .excellent {
      background: #31b03f;
      width: 100%;
    }
  }

  &-element {
    height: 10px;
    transition: background-color 1s, width 1s;
  }
}
