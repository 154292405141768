@import '../../../../styles/shared';

.c-co-lo-agenda-list-session {
  position: relative;
  outline: none;
  margin: 0 0 5px;
  @include padding(8px, 50px, 30px, 10px);
  [dir='ltr'] & {
    border-left-width: 2px;
    border-left-style: solid;
  }

  [dir='rtl'] & {
    border-right-width: 2px;
    border-right-style: solid;
  }
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  background: #fff;
  color: #333;
  cursor: pointer;

  &:hover {
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
    overflow: visible;
    height: auto;
    z-index: 3;
  }

  &--not-highlighted {
    &:hover {
      background-color: #e7e7e7;
    }
  }

  &--networking {
    .c-co-lo-agenda-list-session__background {
      background-color: #e5e5e5;
    }

    &:hover {
      .c-co-lo-agenda-list-session__background {
        background-color: #e7e7e7;
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &__event {
    font-size: 16px;
    margin-bottom: 5px;
  }

  &__time-badge-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 15px;
    line-height: 17px;
    font-weight: 300;
  }

  &__title,
  &__speakers {
    em {
      font-style: normal;
      background-color: #f5ca39;
    }
  }
}

@each $index, $color in $streamColors {
  .c-co-lo-agenda-list-session[data-color-index='#{$index}'] {
    border-color: $color;
  }

  .c-co-lo-agenda-list-session--highlighted[data-color-index='#{$index}'] {
    &:hover {
      .c-co-lo-agenda-list-session__background {
        opacity: 0.2;
      }
    }

    .c-co-lo-agenda-list-session__background {
      background-color: $color;
      opacity: 0.1;
    }
  }
}
