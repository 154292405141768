@import '../../../../../../styles/shared';

.c-festival-footer-navigation {
  ul {
    display: flex;
    flex-direction: column;

    @include mq($from: tablet) {
      flex-direction: row;
    }
  }

  li {
    margin-bottom: 7px;
    text-align: center;

    @include mq($from: tablet) {
      margin-bottom: 0;
    }

    + li {
      @include mq($from: tablet) {
        @include margin-left(10px);
      }
    }
  }

  &__scroll-top-top {
    font-weight: bold;
    cursor: pointer;

    i {
      position: relative;
      top: 1px;
      @include margin-left(6px);
    }
  }

  a,
  &__scroll-top-top {
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    text-decoration: none;
    color: #fff;

    @include mq($from: tablet) {
      font-size: 14px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
