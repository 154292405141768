@import '../../../styles/shared';

.c-booking-button {
  &--menu-type {
    display: flex;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    color: black;
    border-radius: 3px;
    font-size: 26px;
    font-weight: 800;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    padding: 2px 22px 0;
    height: 50px;
    line-height: 1;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include mq($until: tablet) {
      height: 30px;
    }
  }

  &__text {
    @include mq($until: desktop-plus) {
      display: none;
    }
  }

  &__text-short {
    @include mq($from: desktop-plus) {
      display: none;
    }
  }
}
