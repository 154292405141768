@import '../../../styles/shared';

$ribbon-height: 36px;
$border-color: #a5acaf;

.c-informa-ribbon {
  position: relative;
  height: $ribbon-height;
  z-index: 1001;

  &__wrapper {
    position: relative;
    padding: 40px 0 0;
    background-color: #525a5c;
    top: $ribbon-height;
    transform: translateY(-100%);
    transition: transform 0.4s ease;
    z-index: 1001;
  }

  &--expanded &__wrapper {
    transform: translateY(-$ribbon-height);
  }

  &__paragraph {
    font-size: 13px;
    line-height: 18px;
    color: #fff;

    &--right {
      margin-top: 15px;

      @include mq($from: tablet) {
        margin-top: 0;
        @include padding-left(25px);
        @include border-left(1px solid $border-color);
      }
    }
  }

  &__nav {
    margin-top: 14px;

    ul {
      display: flex;

      @include mq($until: tablet) {
        flex-direction: column;
      }

      li {
        @include mq($from: tablet) {
          position: relative;
          @include padding-right(20px);

          &:nth-child(n + 3)::before {
            position: absolute;
            height: 100%;
            content: '';
            border-left: 1px solid $border-color;
            @include left(-10px);
          }
        }
      }
    }

    a {
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;
      color: #fff;
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }

    li:first-child a {
      font-weight: bold;
      text-transform: none;
    }
  }

  &__active-logo {
    width: 92px;
    height: 16px;
    margin: 40px auto 10px;
    background: url(../../../assets/images/informa-ribbon-logo.gif) top
      no-repeat;
    cursor: pointer;
  }

  &--expanded &__active-logo {
    background-position: bottom;
  }
}
