@import '../../../../styles/shared';

.c-form-field {
  &.text,
  &.email,
  &.number,
  &.password {
    border: 1px solid $form-border-color;
    display: block;
    width: 100%;
    padding: 10px 12px;
    border-radius: 5px;
    color: #333;
  }

  &.select {
    border: 1px solid $form-border-color;
    background-color: #fff;
    display: block;
    width: 100%;
    padding: 10px 12px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.4;
    height: auto;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  &.file {
    position: relative;

    input[type='file'] {
      width: 0;
      height: 0;
      border: none;
      background: none;
      position: absolute;
      top: 0;
      @include left(-999px);
    }
  }

  &:disabled {
    background: $form-disabled-color;
  }
}

.c-input-label {
  display: block;
  margin-bottom: 10px;
  color: #002144;
}

.c-input-error {
  display: none;
  position: absolute;
  top: 4px;
  @include right(0);
  font-size: 12px;
}

.c-form.error .error .c-form-field {
  border: 1px solid $form-error-color;
}

.c-form.error .valid .c-form-field {
}

.c-form.error .error .c-input-error {
  display: block;
  color: $form-error-color;
}

.c-form.error .valid .c-input-error {
  display: block;
}
