@import '../../../../../../styles/shared';

.c-search-content-topic-hub {
  display: flex;
  width: 49%;
  text-decoration: none;
  border: 1px solid #cccccc;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
  margin-bottom: 20px;

  &__preview-mode {
    &:nth-child(3),
    &:nth-child(4) {
      margin-bottom: 0;
    }

    @include mq($until: $extended-layout-width) {
      width: 100%;
      &:last-child,
      &:nth-child(3) {
        margin-bottom: 20px;
      }
    }

    @include mq($until: tablet) {
      flex-direction: column;
      min-width: 228px;
      margin: 0 20px 0 0;
      &:last-child,
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }

  &__view-mode {
    @include mq($until: $extended-layout-width) {
      width: 100%;
    }

    @include mq($until: tablet) {
      flex-direction: column;
      min-width: 228px;
      margin: 0 0 20px 0;
    }
  }

  &__image {
    width: 30%;
    min-height: 148px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-color: #e5e5e5;
    border-radius: 0 0 0 10px;

    @include mq($until: tablet) {
      height: 182px;
      width: 100%;
      border-radius: 0;
    }
  }

  &__image-default {
    background-size: auto 120px;

    @include mq($until: tablet) {
      background-size: auto 150px;
    }
  }

  &__description {
    width: 70%;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0 0 10px 0;

    @include mq($until: tablet) {
      width: 100%;
      border-radius: 0 0 10px 10px;
    }
  }

  &__description-default {
    background-color: #f5f5f5;
    height: 24px;
    margin-bottom: 10px;

    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 100%;
      margin-bottom: 20px;
    }

    &:nth-child(3) {
      width: 50%;
    }
  }

  &__title {
    color: $tenant-search-text-color;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 11px;
  }

  &__value-proposition {
    color: #3a3a3a;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    line-height: 22px;
  }
}
