/**
  Imports theme's variables based on process.env.THEME.
  Used only in development mode.
 */
@import '~theme-directory/variables';

@import 'mixins';

@mixin theme-only($theme) {
  @if $theme-name == $theme {
    @content;
  }
}

@mixin one-of-themes($list) {
  $index: index($list, $theme-name);

  @if $index != null {
    @content;
  }
}

@mixin theme-except($theme) {
  @if $theme-name != $theme {
    @content;
  }
}

@mixin one-of-themes-except($list) {
  $index: index($list, $theme-name);

  @if $index == null {
    @content;
  }
}

@mixin hub-dark-theme() {
  :global(.hub-module-dark-theme) {
    @content;
  }
}

@mixin hub-light-theme() {
  :global(.hub-module-light-theme) {
    @content;
  }
}

@mixin event-only() {
  .l-site-event & {
    @content;
  }
}

@mixin course-only() {
  .l-site-course & {
    @content;
  }
}

@mixin animated-linear-gradient($from, $to, $duration: 0.4s) {
  position: relative;
  z-index: 1;
  background-image: $from;
  background-size: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background: $to;
    border-radius: inherit;
    opacity: 0;
    transition: opacity $duration;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

@mixin retina-image($image, $width, $height) {
  background: url($image + '.png') center no-repeat;

  @media screen and (-webkit-min-device-pixel-ratio: 2),
    screen and (min--moz-device-pixel-ratio: 2),
    screen and (-o-min-device-pixel-ratio: 2/1),
    screen and (min-device-pixel-ratio: 2),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
    background: url($image + '@2x.png') center no-repeat;
    background-size: $width $height;
  }
}

@mixin use-gradient($image, $width, $height, $top, $right, $bottom, $left) {
  @include retina-image($image, $width, $height);

  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
}

@mixin right-jigsaw($width, $height, $radius, $color) {
  &::before {
    position: absolute;
    bottom: 0;
    content: '';
    display: block;
    width: calc(100% - $width);
    height: $height;
    background: $color;
    border-top-right-radius: $radius;
    z-index: 1;
  }

  &::after {
    position: absolute;
    bottom: 0;
    right: calc($width - $radius);
    content: '';
    display: block;
    width: $radius;
    height: $radius;
    background: radial-gradient(
      100% 100% at right top,
      transparent 98%,
      $color
    );
    z-index: 1;
  }
}

@mixin left-jigsaw($width, $height, $radius, $offset, $color) {
  &::before {
    position: absolute;
    bottom: calc(($height - ($height - $offset) * 2) * -1);
    right: 0;
    content: '';
    display: block;
    width: calc(100% - $width);
    height: $height;
    background: $color;
    border-bottom-left-radius: $radius;
    z-index: 1;
  }

  &::after {
    position: absolute;
    bottom: calc(($radius - ($height - $offset) * 2) * -1);
    left: calc($width - $radius);
    content: '';
    display: block;
    width: $radius;
    height: $radius;
    background: radial-gradient(
      100% 100% at left bottom,
      transparent 98%,
      $color
    );
    z-index: 1;
  }
}
