@import '../../../../../../styles/shared';

.c-option-list {
  &__heading {
    font-weight: bold;
    margin-bottom: 10px;

    &.has-error {
      color: $form-error-color;
    }
  }

  &__content {
    padding-bottom: 10px;
  }

  &__separator {
    background: linear-gradient(
        to right,
        #dee0e5 55%,
        rgba(255, 255, 255, 0) 0%
      )
      top left repeat-x;
    background-size: 18px 1px;
    margin-bottom: 15px;
    height: 1px;
  }
}
