@import '../../../../styles/shared';

.c-lead-gen-subscribe-description {
  &__heading {
    &-icon {
      min-width: 100px;
      float: left;
      position: relative;

      img {
        margin-top: -1px;
        width: 65px;
        height: auto;
      }
    }

    &-text {
      float: right;
      width: calc(100% - 100px);
      font-family: $font-family-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .minimised & {
      margin-bottom: 0;
      display: flex;

      &-icon {
        min-width: 42px;
        height: 30px;

        img {
          width: 30px;
        }
      }

      h3 {
        display: none;
        font-size: 16px;
        line-height: 16px;
        align-self: center;
        margin-bottom: 0;
      }
    }
  }

  &__description {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #a6bcc6;
    clear: both;
    margin: 10px 0 20px 0;

    .minimised & {
      display: none;
    }
  }
}

@include mq($from: tablet) {
  .c-lead-gen-subscribe-description {
    &__heading {
      .minimised & {
        &-icon {
          min-width: 64px;
          height: 46px;
          position: initial;

          img {
            position: initial;
            width: 46px;
          }
        }

        h3 {
          display: initial;
        }
      }
    }
  }
}

@include mq($from: desktop) {
  .c-lead-gen-subscribe-description {
    &__description {
      width: calc(100% - 100px);
      float: right;
      clear: none;
      margin: 0;
    }
  }
}
