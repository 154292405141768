@import '../../../../../styles/shared';

.c-co-lo-agenda-grid-header-event {
  display: inline-block;
  background-color: #f5f5f5;

  &__wrapper {
    position: relative;
    @include padding(0, 110px, 0, 10px);
    font-size: 18px;
    font-weight: 300;
    line-height: 34px;
    @include text-align(left);
    border-bottom-width: 3px;
    border-bottom-style: solid;
    background: #e4e4e4;
    [dir='ltr'] & {
      transition: padding-left 0.3s ease;
    }

    [dir='rtl'] & {
      transition: padding-right 0.3s ease;
    }
  }

  &__name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 300;
    line-height: 34px;
    @include text-align(left);
  }

  &__link {
    position: absolute;
    top: 1px;
    @include right(10px);
    font-size: 13px;
    font-weight: bold;
    text-transform: lowercase;
    cursor: pointer;
    text-decoration: none;
    color: #000;

    i {
      position: relative;
      top: 3px;
      @include left(3px);
      font-size: 15px;
      [dir='rtl'] & {
        transform: rotate(180deg);
      }
    }
  }

  &__streams {
    display: flex;
  }

  &__stream {
    position: relative;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
    @include text-align(left);
    vertical-align: top;
    padding: 5px;
    background: #f5f5f5;
    color: #333;
    overflow: scroll;

    &--with-logo {
      @include padding(5px, 40px, 5px, 5px);
    }
  }
}

@each $index, $color in $streamColors {
  .c-co-lo-agenda-grid-header-event__wrapper[data-color-index='#{$index}'] {
    border-color: $color;
  }
}
