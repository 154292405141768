@import '../../../styles/shared';

$bar-height: 50px;

.agenda-days {
  padding-top: 10px;
  min-height: $bar-height;
}

.c-agenda-day-selectors.mobile-menu {
  background: #fff;
  margin-top: 50px;

  & > .container {
    padding: 0;
  }

  .agenda-download {
    display: none;
  }

  ul {
    @include padding-left(0);
    height: initial;

    li {
      background-color: #efefef;
      border-bottom: 1px solid #dcdcdc;
      padding: 15px;

      a {
        color: #333;
        text-decoration: none;

        time {
          font-size: 14px;
          font-weight: 600;
          display: block;
        }

        span {
          font-weight: 300;
        }
      }
    }
  }
}

.c-agenda-day-selectors:not(.mobile-menu) {
  @include mq($until: tablet) {
    &:not(.mobile-bar) {
      display: none;
    }
  }

  .agenda-day-selector {
    display: inline-block;
    @include margin(0, 15px, 10px, 0);
    line-height: 30px;

    a {
      padding: 3px 10px;
      border: 1px solid transparent;
      color: #fff;
      border-bottom-color: #fff;
      white-space: nowrap;
      text-decoration: none;
    }

    @include mq($until: tablet) {
      display: none;
    }
  }
}

.agenda-download {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;

  a {
    text-decoration-line: none;

    .fa {
      @include margin-left(10px);
      @include margin-right(5px);
    }
  }

  &.event {
    @include float(right);

    a {
      font-size: 14px;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.course {
    @include float(left);

    a {
      position: relative;
      top: -1px;
      @include margin-right(15px);
      @include padding(6px, 9px, 5px, 9px);
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      font-weight: 800;
      font-size: 16px;
      text-transform: uppercase;
      border-radius: 2px;

      @include theme-only($FANEXPOHQ) {
        font-family: 'Proxima Nova ExCn Bl', sans-serif;
      }
    }
  }
}
