@import '../../../../styles/shared';

.lead-gen-teaser-article {
  &__whitepaper {
    display: flex;
    align-items: flex-start;
    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__whitepaper-img {
    max-width: 180px;
    //@include margin(20px, 20px, 0, 0);
    margin: 20px 20px 0 0;
    @include mq($until: tablet) {
      margin: 0 auto;
    }
  }

  &__title {
    padding-bottom: 30px;
    font-family: 'Noto Sans', sans-serif;
    font-size: 34px;
    line-height: 36px;
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid #efefef;

    @include mq($until: tablet) {
      font-size: 24px;
      line-height: 1.15;
    }
  }

  &__subtitle {
    margin: 20px 0;
    font-weight: 300;
    font-size: 22px;
    line-height: 1.2;
    color: #333;

    @include mq($until: tablet) {
      font-size: 20px;
      line-height: 1.15;
    }
  }

  &__body {
    margin: 1em 0;
    color: #333;

    a:not(.whitepaper-download):not(.share-icon) {
      color: #bd10e0;
    }

    h1 {
      font-family: 'Noto Sans', sans-serif;
      font-size: 32px;
      font-weight: bold;
      color: #002144;
      margin-bottom: 0;
      line-height: 1.13;
      text-transform: none;
    }

    h2 {
      font-size: 22px;
      font-weight: 400;
      color: #002144;
      letter-spacing: 0;
      line-height: 1.2;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      margin: 18px 0;
    }

    article {
      margin-bottom: 40px;
      font-weight: 300;
      font-size: 18px;
      line-height: 1.6;
    }

    p {
      margin-bottom: 16px;
    }

    ul,
    ol {
      font-size: 16px;
      line-height: 1.5;
      list-style: initial;
      margin-top: 1em;
      margin-bottom: 1em;
      //@include padding-left(18px);
      padding-left: 18px;
      font-weight: 500;
      > br {
        display: none;
      }
      li {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    li {
      margin-bottom: 16px;
    }

    blockquote {
      font-weight: 300;
      font-size: 1.625em;
      line-height: 1.5;
      //@include border-left(2px solid #a6bcc6);
      //@include padding-left(20px);
      //@include margin(26px, 40px, 26px, 0);
      border-left: 2px solid #a6bcc6;
      padding-left: 20px;
      margin: 26px 40px 26px 0;
    }

    img {
      height: auto;
      margin: 2em 0;
      max-width: 100%;
      width: auto; // IE11 fix
      &.alignleft {
        //@include float(left);
        //@include margin(5px, 10px, 10px, 0);
        float: left;
        margin: 5px 10px 10px 0;
      }
      &.alignright {
        //@include float(right);
        //@include margin(5px, 0, 10px, 10px);
        float: right;
        margin: 5px 0 10px 10px;
      }
      &.aligncenter {
        margin: 2em auto;
      }
    }

    figure {
      width: 100%;
      margin: 2em 0;
    }

    figure img {
      max-width: 100%;
      height: auto;
      display: block;
      margin-bottom: 1em;
    }

    figcaption {
      font-size: 14px;
      color: #586876;
      margin: 1em 0;
    }

    .info-header {
      text-transform: uppercase;
      font-size: 12px;
      margin: 0;
    }

    .author-info,
    .date-info {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      margin: 0;
    }

    .fileDownloadText {
      font-size: 14px;
      color: #542650;
      //@include padding-left(10px);
      //@include padding-right(20px);
      padding-left: 10px;
      padding-right: 20px;
    }

    .individual-download-container {
      display: inline-block;
      margin-bottom: 10px;
    }

    .fa-file-pdf-o {
      font-size: 20px;
      color: #542650;
    }
  }
}

@include theme-only($DEFAULT) {
  .lead-gen-teaser-article {
    h1 {
      font: normal 42px/48px $font-family-serif;
      color: $tenant-primary-color;
      border-bottom: initial;
      padding-bottom: 28px;

      &.lead-gen-teaser-article__title {
        border-bottom: 1px solid #dee0e5;
        margin-bottom: 30px;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-family-serif;
    }

    p {
      color: #333;
      font-family: $font-family-sans-serif;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }
}
