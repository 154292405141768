@import '../../../styles/shared';

$transitionTime: 0.2s;

.c-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 18px 10px;
  font-family: 'Barlow Condensed Extra Bold', sans-serif;
  font-size: 25px;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  line-height: 1;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: all $transitionTime linear;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 0.8);
  outline: none;
  cursor: pointer;

  @include mq($until: tablet) {
    width: 100%;
  }

  @include theme-only($FANEXPOHQ) {
    font-family: $font-family-button;
  }

  &:hover:not(:disabled) {
    text-decoration: none;
    border-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.4);
  }

  &:disabled {
    background-color: #f1f2f4 !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    color: #dee0e5;
    box-shadow: none;
    transition: none;
    cursor: default;
  }

  & + & {
    @include mq($from: tablet) {
      @include margin-left(10px);
      margin-top: 0;
    }
  }

  &--submit {
    font-size: 16px;
    font-weight: 600;
    padding: 16px 20px;
  }

  &--tiny {
    font-size: 14px;
    padding: 8px 20px 6px;
  }

  &--minuscule {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
  }

  &--small {
    font-size: 17px;
    padding: 7px 10px 3px;
  }

  &--medium {
    font-size: 18px;
    padding: 10px 10px 7px;
  }

  &--large {
    font-size: 32px;
    padding: 16px 20px 16px;

    @include mq($until: tablet) {
      font-size: 26px;
    }

    @include one-of-themes([$FANEXPOHQ, $MEFCC]) {
      padding: 17px 20px 15px;
    }
  }

  &--submit-medium {
    @extend .c-button--submit;
    padding-left: 65px;
    padding-right: 65px;
  }

  &--submit-wide {
    @extend .c-button--submit;
    padding-left: 110px;
    padding-right: 110px;
  }

  &--full {
    @extend .c-button--submit;
    width: 100%;
  }

  &--popup {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    width: auto;
    padding: 10px 20px 9px;
    margin: 0;
  }

  &--transparent {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 0.2);
    transition: border 0.2s, box-shadow $transitionTime, color $transitionTime,
      background-color $transitionTime;

    &:hover:not(:disabled) {
      background-color: rgba(255, 255, 255, 1);
      color: rgba(0, 0, 0, 0.8);
    }
  }

  &--white {
    color: #000;
    border: 1px solid rgba(255, 255, 255, 1);
    transition: border 0.2s, box-shadow $transitionTime, color $transitionTime,
      background-color $transitionTime;
  }

  &--success {
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
    background-color: #70d77b;
    color: #002144;
    @extend .c-button--medium;
    padding: 18px 20px 14px;

    &:hover:not(:disabled) {
      border-color: #82c289;
    }
  }

  &--primary {
    background-color: #542650;
    color: #fff;

    &:hover:not(:disabled) {
      background-color: #542650;
    }

    &:disabled {
      background-color: darken(#542650, 10%);
    }
  }

  &--secondary {
    background-color: #002144;
    color: #fff;

    &:hover:not(:disabled) {
      background-color: #002144;
    }

    &:disabled {
      background-color: darken(#002144, 10%);
    }
  }

  &--accent {
    background-color: #31b03f;
    color: #fff;

    &:hover:not(:disabled) {
      background-color: darken(#31b03f, 10%);
    }

    &:disabled {
      background-color: darken(#31b03f, 10%);
    }
  }

  &--classic {
    background-color: #f1f2f4;
    color: #333;

    &:hover:not(:disabled) {
      background-color: darken(#f1f2f4, 10%);
    }

    &:disabled {
      background-color: darken(#f1f2f4, 10%);
    }
  }

  &--classic-without-shadow {
    @extend .c-button--classic;
    box-shadow: none;
    text-transform: initial !important;

    &:hover:not(:disabled) {
      box-shadow: none;
    }
  }

  &__loader {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    @include margin-right(5px);
    margin-bottom: 2px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      border: 4px solid;
      border-radius: 50%;
      animation: button-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #e5e5e5 transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }

    @keyframes button-loader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__lead-gen {
    box-shadow: none !important;
    margin-top: 20px;
    height: 46px;

    &-type-1 {
      padding: 16px 0 !important;
      font-weight: 700;
      text-transform: capitalize !important;
    }

    &-type-2 {
      font-weight: 800;
      text-transform: uppercase !important;
      color: #333;
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      font-size: 25px;

      @include theme-only($DEFAULT) {
        font-weight: 600;
        text-transform: none !important;
      }

      @include theme-only($FANEXPOHQ) {
        font-family: 'Proxima Nova ExCn Bl', sans-serif;
      }

      .c-spinner span {
        border-color: #333 transparent transparent transparent;
      }
    }

    &-confirm-type-2 {
      padding: 0 !important;
      color: #333;
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      font-size: 25px;
      font-weight: 800;
      line-height: 44px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background-color: #e5e5e5;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

      @include theme-only($FANEXPOHQ) {
        font-family: 'Proxima Nova ExCn Bl', sans-serif;
      }
    }
  }

  &.trademark {
    text-transform: none !important;
  }

  @include theme-only($DEFAULT) {
    font-weight: 400;
    text-transform: none !important;

    @include event-only() {
      font-family: $font-family-serif;
    }

    @include course-only() {
      font-family: 'Open Sans', sans-serif;
    }
  }
}

.platform-win {
  .c-button {
    &--tiny {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    &--small {
      padding-top: 6px;
      padding-bottom: 4px;
    }

    &--medium {
      padding-top: 9px;
      padding-bottom: 8px;
    }

    &--large {
      padding-top: 15px;
      padding-bottom: 17px;
    }

    &--submit {
      padding-top: 17px;
      padding-bottom: 15px;
    }
  }
}
