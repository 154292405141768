@import '../../../../../../styles/shared';

$top-header-height: 106px;

.drop-down-menu {
  display: flex;
  width: 100%;
  max-height: calc(100vh - #{$top-header-height});
  background: #fff;

  &__wrapper {
    display: flex;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &__subitems {
      display: flex;
      flex-grow: 1;
      background: #fff;
    }
  }
}

@include mq($until: tablet) {
  .ios .drop-down-menu__wrapper .verticals-list {
    padding-bottom: $top-header-height;
  }
}
