@import '../../../../../styles/shared';

$HEADER_HEIGHT: 70px;
$BUTTON_BORDER_RADIUS: 3px;

.c-hub-header {
  background: #fff;
  border-top: 1px solid #e5e5e5;
  height: $HEADER_HEIGHT;
  position: relative;
  width: 100%;
  z-index: 15;

  @include mq($until: tablet) {
    height: auto;
  }

  &__menu-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: $HEADER_HEIGHT;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;

    .tenant-logo-header {
      font-size: 30px;
      padding: 5px;
      margin-top: 4px;
      margin-left: 15px;
      line-height: 1;
      text-decoration: none;

      @include mq($until: tablet) {
        font-size: 21px;
        margin-left: 10px;
      }

      &__name {
        color: #542650;
      }

      &__name-secondary {
        color: #002144;
      }

      &__portfolio-name {
        font-size: 16px;
        color: #56595b;

        @include mq($until: tablet) {
          font-size: 11px;
        }
      }
    }

    .informa-tech-logo-header {
      margin-left: 15px;
      margin-top: 15px;
      filter: invert(1);

      img {
        width: auto;
        height: 39px;
        margin-top: 4px;
        margin-bottom: 10px;
        vertical-align: initial;

        @include mq($until: tablet) {
          width: auto;
          height: 24px;
        }
      }
    }
  }

  .button {
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid #e5e5e5;
    border-radius: $BUTTON_BORDER_RADIUS;
    color: #542650;
    cursor: pointer;

    &--transparent {
      background: #fff;

      &:hover {
        border-color: #542650;
      }
    }

    &--success {
      background-color: darken(#70d77b, 20);
      border-color: darken(#70d77b, 20);
      color: #fff;

      &:hover {
        background-color: darken(#70d77b, 30);
        border-color: darken(#70d77b, 30);
      }
    }

    @include mq($until: tablet) {
      &--mobile-hidden {
        display: none;
      }
    }
  }

  .registration {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $HEADER_HEIGHT;

    &-button {
      display: inline-block;
      padding: 8px 20px;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      white-space: nowrap;
      transition: all 0.3s linear;

      @include mq($until: tablet) {
        padding: 8px 10px;
      }
    }
  }

  .menu-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: $BUTTON_BORDER_RADIUS;
    min-height: 36px;
    padding: 5px 9px;

    &__text {
      font-size: 14px;
      padding: 0 2px;
      margin-left: 9px;
    }

    .material-icons {
      font-size: 24px;
    }
  }

  .menu-container {
    max-height: 0;
    margin-left: -$gutter-width;
    margin-right: -$gutter-width;
    overflow: hidden;
    transition: max-height 400ms ease-in;
  }

  &--menu-opened {
    .menu-container {
      max-height: 100vh;
      overflow: hidden;
      transition: max-height 400ms ease-in;
    }
  }
}

.hub-menu {
  &__overlay {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;

    &-enter {
      opacity: 0.01;
    }

    &-enter.hub-menu__overlay-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }

    &-exit {
      opacity: 1;
    }

    &-exit.hub-menu__overlay-exit-active {
      opacity: 0.01;
      transition: opacity 300ms ease-in;
    }
  }
}
