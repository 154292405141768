@import '../../../styles/shared';

.c-server-error-section {
  color: #000;

  h2 {
    margin-bottom: 10px;
  }

  &__listing {
    margin-top: 30px;
    @include padding-left(40px);
    list-style: disc;

    li {
      ul {
        margin-top: 20px;
        list-style: circle;
      }
    }

    li + li {
      margin-top: 20px;
    }
  }

  &__cta {
    margin-top: 40px;
  }
}

@include one-of-themes-except([$FANEXPOHQ, $MEFCC]) {
  .c-base-section-wrapper--server-error {
    flex: 1 0;
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-server-error-section {
    h2 {
      font-size: 34px;
    }
  }
}
