.c-co-lo-agenda-grid-event-info {
  padding: 30px;

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__event-name {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.25;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    margin-bottom: 30px;
  }
}
