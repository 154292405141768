@import '../../../../../../styles/shared';

.c-multi-level-nav-item {
  &__overlay {
    position: absolute;
    top: $multi-level-nav-level-1-height-mobile +
      $multi-level-nav-underline-width;
    left: 0;
    right: 0;
    max-height: 0;
    overflow: hidden;
    z-index: 2;
    opacity: 0;
    transition: all 0s;
    transition-delay: $multi-level-nav-animation-delay;

    @include mq($from: wide) {
      top: $multi-level-nav-level-1-height + $multi-level-nav-underline-width;
    }
  }

  &--level-1:hover &__overlay {
    max-height: 500px;
    overflow: visible;
    opacity: 1;

    &::before {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      content: '';
      display: block;
      height: $multi-level-nav-level-2-height;
      z-index: 100;
    }
  }

  &--level-1:hover {
    .c-multi-level-nav-btn__one-line-arrow {
      transform: rotateX(180deg);
    }
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-multi-level-nav-item {
    &__overlay {
      top: initial;
      background-color: $multi-level-nav-level-2-bg-color !important;
    }

    &__body {
      max-width: $extended-layout-width;
      margin: 0 auto;
      padding: 0 $gutter-width * 0.5;
    }

    &__overlay &__body {
      @include padding-left(0);
    }
  }
}
