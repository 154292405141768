@import '../../../../styles/shared';

.c-search-filter {
  font-family: $font-family-sans-serif;
  margin: 13px 0;
  width: 100%;

  @include mq($until: desktop) {
    width: 48%;
  }

  @include mq($until: tablet) {
    width: 100%;
  }

  &__header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    color: $tenant-secondary-color;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.04px;
    line-height: 20px;
  }

  &__icon {
    font-size: 26px;
    cursor: pointer;
  }

  &__list {
    &--show {
      display: block;
    }

    &--hide {
      display: none;
    }
  }

  &__item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-less-more {
      color: $tenant-accent-color;
      font-size: 14px;
      line-height: 19px;
      text-decoration: underline;
      cursor: pointer;

      &--hidden {
        visibility: hidden;
      }
    }

    &-clear {
      font-size: 14px;
      line-height: 19px;
      color: #cccccc;
      margin-right: 8px;
      text-decoration: underline;
      cursor: pointer;

      &--active {
        color: $tenant-primary-color;
      }
    }
  }
}
