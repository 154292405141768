@import '../../../styles/shared';

.lead-gen-teaser {
  @include mq($from: desktop) {
    //@include padding-right(30px);
    padding-right: 30px;

    &--article {
      //@include padding-right(40px);
      padding-right: 40px;
    }
  }
}
