@import '../../../../styles/shared';

.c-session-info {
  @mixin draw-arrow($property) {
    content: '';
    position: absolute;
    top: 32px;
    width: 8px;
    height: 8px;
    @include border-left(1px solid #333);
    border-bottom: 1px solid #333;
    [dir='ltr'] & {
      -webkit-transform: rotate($property);
      -ms-transform: rotate($property);
      transform: rotate($property);
    }

    [dir='rtl'] & {
      -webkit-transform: rotate(-$property);
      -ms-transform: rotate(-$property);
      transform: rotate(-$property);
    }
  }

  %arrow-properties {
    text-transform: uppercase;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    margin-bottom: 3px;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  background-color: #fff;
  position: relative;

  @include theme-only($DEFAULT) {
    border-radius: 16px;
  }

  &__header {
    position: relative;
    padding: 15px 20px;
    min-height: 70px;

    &-close {
      position: absolute;
      @include right(20px);
      top: 14px;
      font-size: 46px;
      font-weight: 300;
      line-height: 34px;
      color: #fff;
      cursor: pointer;
      overflow: hidden;

      @include theme-only($ASIATECHXSG) {
        color: $body-color;
      }
    }

    @include theme-only($DEFAULT) {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
    }
  }

  &__body {
    padding: 30px 20px;

    @include mq($until: tablet) {
      padding: 15px;
    }

    &-text {
      padding: 0 20px 40px;

      @include mq($until: tablet) {
        padding: 0 0 40px;
      }
    }

    &-format {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
    }

    &-title {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.25;
      overflow: hidden;

      @include mq($until: tablet) {
        font-size: 20px;
      }

      .agenda-item-label {
        border-radius: 2px;
        font-size: 12px;
        font-weight: bold;
        line-height: 17px;
        @include margin-left(12px);
        @include padding(3px, 9px, 2px, 9px);
        position: relative;
        top: -3px;
        text-transform: uppercase;

        &__free {
          background-color: #15cb8e;
          color: #fff;
        }

        @include theme-only($DEFAULT) {
          text-transform: none;
        }
      }

      @include theme-only($DEFAULT) {
        @include event-only {
          font-family: $font-family-serif;
        }

        @include course-only {
          font-family: 'Open Sans', sans-serif;
        }
      }
    }

    &-description {
      margin-bottom: 30px;

      .formatted-text {
        ul,
        ol {
          li {
            margin: 8px 0;
          }
        }
      }
    }

    &-virtual-event {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      margin: 0 -20px;
      background: linear-gradient(180deg, #efefef 0%, #fff 100%);
      padding: 30px 40px 0;

      @include mq($until: tablet) {
        margin: 0 -15px;
        padding: 30px 15px 10px;
      }

      a:not(.c-cta) {
        text-decoration: none;
        border-bottom: 1px solid #000;
      }
    }

    &-booking-btn {
      padding-right: 50px;
      padding-left: 50px;
    }

    &-stream {
      font-size: 16px;
      line-height: 1.63;
    }

    &-time {
      font-size: 16px;
      font-weight: 300;
    }
  }

  .slick-slider {
    [dir='rtl'] & {
      display: flex;
      flex-direction: row-reverse;
    }

    .slick-arrow {
      position: initial;
      display: inline-block;
      transform: initial;
      width: 50%;
      border: initial;
      background-color: $form-disabled-color;
      padding: 25px 40px 70px;

      @include mq($until: tablet) {
        padding: 25px 40px 55px 40px;
      }

      &.slick-disabled > div {
        visibility: hidden;
      }

      &:before {
        display: none;
      }

      &:not(.slick-disabled):hover {
        background-color: #dee0e5;
        -webkit-transition: background-color, 300ms;
        -moz-transition: background-color, 300ms;
        -o-transition: background-color, 300ms;
        transition: background-color, 300ms;
      }

      &:hover {
        outline: initial;
        color: initial;
        border: none;
        box-shadow: none;
        text-decoration: none;
      }

      &.slick-next {
        @include text-align(right);
      }

      &.slick-prev {
        @include text-align(left);
      }

      .title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;

        @include mq($until: tablet) {
          display: none;
        }
      }

      .arrow-prev {
        @extend %arrow-properties;

        &:before {
          @include draw-arrow(45deg);
          @include left(20px);
        }
      }

      .arrow-next {
        @extend %arrow-properties;

        &:before {
          @include draw-arrow(-135deg);
          @include right(20px);
        }
      }
    }

    .slick-list {
      display: none;
    }
  }
}
