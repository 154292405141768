@import '../../../styles/shared';

.c-advertising {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .image-section + .c-advertising:has(+ .image-section) {
    .adBox {
      padding-bottom: 40px;

      @include mq($from: tablet) {
        padding-bottom: 60px;
      }
    }
  }
}
