@import '../../../../../styles/shared';

.c-festival-footer {
  padding: 50px 0 30px;
  color: #fff;
  background-color: #110e41;

  hr {
    height: 1px;
    margin: 20px 0;
    background-color: #fff;
    border: 0;
  }

  &__top {
    display: flex;
    flex-direction: column;

    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__bottom {
    flex-direction: column-reverse;

    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__festival-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 15px;
    text-align: center;

    @include mq($from: desktop) {
      @include text-align(left);
    }
  }

  &__sponsors {
    flex: 1 0;
    margin: 0 0 10px;

    @include mq($from: desktop) {
      margin: -20px 0 0;
    }
  }

  &__hash-tag,
  &__short-date,
  &__custom-dates-text {
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
  }

  &__navigation {
    display: flex;
    justify-content: center;
    margin: 10px 0;

    @include mq($from: desktop) {
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%;
      margin: 0;
    }
  }

  &__social-network {
    margin-bottom: 0;

    @include mq($from: mobile) {
      margin-bottom: 36px;
    }
  }

  &__register-interest {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;

    @include mq($until: desktop) {
      justify-content: center;
    }

    @include mq($until: tablet) {
      a {
        width: 100%;
      }
    }
  }
}
