@import '../../../styles/shared';

.c-industry-heading {
  margin-top: 0;

  @include mq($until: tablet) {
    border-top: 5px solid $tenant-primary-color;
  }

  @include mq($from: tablet) {
    margin-top: -160px;
  }

  @include mq($from: wide) {
    margin-top: -95px;
  }

  .container {
    max-width: $extended-layout-width;
  }

  &__content {
    padding-top: 30px;
    background-color: #fff;

    @include mq($from: tablet) {
      padding: 25px 40px 15px;
      border-top: 5px solid $tenant-primary-color;
    }
  }

  &__name {
    margin-bottom: 5px;
    font-family: $font-family-serif;
    font-size: 42px;
    line-height: 48px;
    color: $tenant-primary-color;

    @include mq($until: tablet) {
      font-size: 32px;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }

  &__description {
    font-family: $font-family-sans-serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 34px;
    color: $tenant-primary-color;

    @include mq($until: tablet) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__cite {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    line-height: 23px;
    padding: 10px 0 10px 30px;
    color: $tenant-primary-color;

    @include mq($until: desktop) {
      padding-left: 0;
      padding-top: 35px;
      margin-top: 35px;
      border-left: none;
      border-top: 1px solid $tenant-border-separator-color;
    }

    @include mq($until: tablet) {
      padding-top: 20px;
      margin-top: 20px;
    }

    strong {
      font-weight: 600;
    }

    em {
      font-style: normal;
      font-weight: 300;
    }
  }

  &__separator {
    @include mq($from: desktop) {
      border-left: 1px solid $tenant-border-separator-color;
    }
  }
}
