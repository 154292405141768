@import '../../../../../../styles/shared';

.c-browse-topics-popup {
  position: absolute;
  top: $TENANT-HEADER-HEIGHT;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  overflow-y: auto;
  transition: height 0.2s ease;
  background: rgba($tenant-primary-color, 0.95);

  &--opened {
    height: calc(100vh - #{$TENANT-HEADER-HEIGHT});
  }

  &--closed {
    height: 0;
  }
}

.c-browse-topics-popup {
  .container {
    max-width: $extended-layout-width;
  }

  .c-l1-topics {
    &__title {
      color: #fff;

      @include mq($until: tablet) {
        display: flex;
      }

      i {
        @include mq($until: tablet) {
          display: block;
          margin-left: 20px;
        }
      }
    }
  }
}
