@import '../../../../styles/shared';

.c-payment-form-block {
  color: #333;

  &__heading {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  &__body {
    @include padding(20px, 27px, 20px, 22px);
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    @include border-left(5px solid transparent);

    &:not(.checked):not(:hover),
    .disabled & {
      [dir='ltr'] & {
        border-left-color: transparent !important;
      }

      [dir='rtl'] & {
        border-right-color: transparent !important;
      }
    }
  }
}
