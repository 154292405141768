@import '../../../../../styles/shared';

.c-password-strength-bar {
  margin-top: 6px;

  &__text {
    display: inline-block;
    @include margin-right(15px);
    font-size: 12px;
    line-height: 14px;
    color: $form-helper-color;

    strong {
      font-weight: 700;
    }
  }

  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      @include margin-right(15px);
      padding: 0;
      font-size: 12px;
      line-height: 14px;

      @include mq($until: tablet) {
        width: 40%;
      }

      &::before {
        @include padding-right(5px);
        font-family: 'Material Icons';
        font-size: 12px;
        line-height: 13px;
        vertical-align: middle;
        color: #626262;
      }

      &.valid {
        color: #31b03f;

        &:before {
          content: '\E5CA';
          color: #31b03f;
        }
      }

      &.invalid {
        color: #626262;

        &:before {
          content: '\E5CD';
          color: #626262;
        }
      }
    }
  }
}
