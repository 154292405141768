@import '../../../../styles/shared';

.confirmation-message {
  padding: 10px 20px;
  background: #fff;
  border-bottom: 1px solid #efefef;

  &__container {
    display: flex;
    padding: 10px 0;
  }

  &__icon {
    display: flex;
    align-items: center;
    max-height: 51px;
    font-size: 26px;
    color: #15cb8e;
    margin-right: 10px;

    &--error {
      color: #c9474d;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__text {
    font-size: 21px;
    line-height: 21px;
    color: #15cb8e;

    @include mq($until: tablet) {
      font-size: 18px;
      line-height: 1.2;
    }

    &--error {
      color: #c9474d;
    }
  }
}
