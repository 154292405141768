@import '../../../../../../../styles/shared';

.c-option-list-multiple-heading {
  font-weight: bold;
  margin-bottom: 10px;

  &--error {
    color: $form-error-color;
  }
}
