@import '../../../../../../../styles/shared';

$line-background-color: #dee0e5;

.hub-menu-loading-block {
  width: 100%;
  padding: 10px;

  .group {
    display: flex;
    flex-direction: row;
    margin: 0 10px 10px;

    &:first-child {
      margin: 47px 10px 62px;
    }

    &-item {
      flex: 1;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }

      @include mq($until: mobile) {
        &:not(:first-child) {
          display: none;
        }
      }

      @include mq($until: desktop) {
        &:nth-child(3),
        &:nth-child(4) {
          display: none;
        }
      }

      @include mq($until: wide) {
        display: block;

        &:last-child {
          display: none;
        }
      }
    }
  }

  .loading {
    position: relative;
    height: 75px;
    border-top: 2px solid $line-background-color;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 13px;
      background: $line-background-color;
    }

    &::before {
      width: 163px;
      top: 23px;
    }

    &::after {
      width: 89px;
      top: 43px;
    }
  }
}
