@import '../../../../../styles/shared';

.search-content-view-no-results {
  padding: 15px 25px;

  @include mq($until: tablet) {
    padding: 15px;
  }

  &__title {
    color: $tenant-primary-color;
    font-family: $font-family-serif;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 20px;
  }

  &__description {
    color: #333;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  &__question {
    color: #2c2f78;
    font-family: $font-family-serif;
    font-size: 20px;
    line-height: 49px;
    margin-bottom: 10px;
  }

  &__browse-by-topic {
    cursor: pointer;
    color: $tenant-accent-color;
    font-family: $font-family-sans-serif;
    font-size: 22px;
    line-height: 30px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    text-decoration: underline;
  }
}
