@import '../../../../styles/shared';

.lead-gen-teaser-badge-container {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;

  .material-icons {
    font-size: 28px;
    color: #333;
  }

  &--round {
    border-radius: 50%;
  }
}
