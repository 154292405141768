@import '../../../../styles/shared';

.c-co-lo-agenda-toggle-data-view {
  color: rgb(51, 51, 51);
  font-weight: 400;
  cursor: pointer;
  position: relative;
  @include margin-right(20px);

  &__text {
    font-size: 16px;
  }

  &__icon {
    top: -2px;
    @include right(-25px);
    font-size: 24px;
    position: absolute;
  }
}
