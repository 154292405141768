@import '../../../styles/shared';

.event-partners {
  font-size: initial;
  font-weight: initial;

  &__heading {
    margin: 40px 0 15px 0;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-size: 52px;
    font-weight: 800;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__body {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 35px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px;

    @include mq($until: tablet) {
      flex-direction: column;
      @include padding-left(10px);
    }
  }

  &__organisation-name {
    @include mq($from: tablet) {
      border-bottom: 1px solid #dcdcdc;
      min-height: 85px;
      @include padding(10px, 10px, 5px, 0);
      width: 33%;
    }
  }

  a.event-partners__link {
    color: #bd10e0;
  }
}
