@import '../../../../styles/shared';

.c-filter-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 300;
  @include padding(10px, 2px, 10px, 40px);
  margin: 0;
  cursor: pointer;
  @include mq($until: tablet) {
    background-color: #efefef;
    border-bottom: 1px solid #dcdcdc;
    @include padding(15px, 20px, 15px, 60px);
  }

  &--with-logo {
    @include padding-right(50px);
  }

  &:first-child {
    @include mq($until: tablet) {
      border-top: 1px solid #dcdcdc;
    }
  }

  input {
    position: absolute;
    @include left(-9999px);
  }

  span {
    position: relative;
    font-size: 16px;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      @include left(-40px);
      top: 50%;
      margin-top: -13px;
      border: 1px solid #56595b;
      border-radius: 2px;
      background-color: #fff;
    }
  }

  input:checked + span {
    &::before {
      background-color: #56595b;
    }

    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 6px;
      @include left(-34px);
      top: 50%;
      margin-top: -5px;
      [dir='ltr'] & {
        transform: rotate(-45deg);
      }

      [dir='rtl'] & {
        transform: rotate(-45deg) scaleX(-1);
      }
      @include border-left(2px solid #fff);
      border-bottom: 2px solid #fff;
    }
  }
}

@each $index, $color in $streamColors {
  .c-filterable-content .c-filter-checkbox[data-color-index='#{$index}'] {
    input + span {
      &::before {
        border-color: $color;
      }
    }

    input:checked + span {
      &::before {
        background-color: $color;
        border-color: $color;
      }

      &::after {
        background-color: $color;
      }
    }
  }
}
