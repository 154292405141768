@import '../../../../styles/shared';

.c-strength-password-container {
  margin-bottom: 15px;

  ul {
    display: inline-block;
    width: 100%;
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;

    li {
      @include float(left);
      @include margin(0, 0, 8px, 0);
      padding: 0;
      font-size: 12px;
      line-height: 18px;
      width: 50%;

      &:before {
        color: #626262;
        font-family: 'Material Icons';
        font-size: 12px;
        line-height: 13px;
        vertical-align: middle;
        padding-right: 5px;
      }
    }

    @media screen and (min-width: $tablet-breakpoint) {
      display: inline-block;
      vertical-align: text-bottom;
      width: auto;

      li {
        width: auto;
        @include padding-left(15px);
        margin: 0;

        &:first-of-type {
          @include padding-left(0);
        }
      }
    }
  }

  .invalid {
    color: #626262;

    &:before {
      content: '\E5CD';
      color: #626262;
    }
  }

  .valid {
    color: #31b03f;

    &:before {
      content: '\E5CA';
      color: #31b03f;
    }
  }

  .password-helper {
    display: inline-block;
    @include margin-right(15px);
    margin-bottom: 6px;

    p {
      font-size: 12px;
      line-height: 14px;

      span {
        font-weight: 600;
      }
    }
  }
}

.show-password-btn {
  position: absolute;
  bottom: 1px;
  @include right(1px);
  outline: none;
  height: 42px;
  line-height: 42px;
  border-radius: 0 6px 6px 0;
  background: transparent;
  [dir='ltr'] & {
    border-width: 0 0 0 1px;
    border-left-color: #dee0e5;
    border-left-style: solid;
  }

  [dir='rtl'] & {
    border-width: 0 1px 0 0;
    border-right-color: #dee0e5;
    border-right-style: solid;
  }
  padding: 0 10px;
  width: 55px;
  font-size: 12px;
  cursor: pointer;
}

@include theme-only($KNECT365) {
  .c-strength-password-container {
    ul li {
      line-height: 14px;
    }

    .password-helper p span {
      font-weight: 500;
    }
  }
}
