@import '../../../../../styles/shared';

.c-co-lo-agenda-grid-session {
  position: absolute;
  overflow: hidden;
  background-color: #fff;
  outline: 5px solid #efefef;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  transition: padding-left 0.3s ease;

  &:hover {
    padding-bottom: 30px !important;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
    overflow: visible;
    height: auto !important;
    z-index: 3;

    .c-co-lo-agenda-grid-session__shadow {
      display: none;
    }
    .c-co-lo-agenda-grid-session__simple-title {
      display: none;
    }
    .c-co-lo-agenda-grid-session__title--short {
      display: block;
    }
    .c-co-lo-agenda-grid-session__badges-wrap--short {
      display: block;
    }
  }

  &--not-highlighted {
    &:hover {
      background-color: #e7e7e7;
    }
  }

  &--networking {
    .c-co-lo-agenda-grid-session__background {
      background-color: #e5e5e5;
    }
    .c-co-lo-agenda-grid-session__shadow {
      display: none;
    }

    &:hover {
      .c-co-lo-agenda-grid-session__background {
        background-color: #e7e7e7;
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &__time-badge-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
  }

  &__title,
  &__simple-title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
  }

  &__title {
    &--short {
      display: none;
    }
  }

  &__simple-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 48%;
    display: none;
    &--short {
      display: block;
    }
  }

  &__badges-wrap {
    &--short {
      display: none;
    }
  }

  &__shadow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    background: linear-gradient(transparent, #fff);
    z-index: 1;
  }
}

@each $index, $color in $streamColors {
  .c-co-lo-agenda-grid-session--highlighted[data-color-index='#{$index}'] {
    &:hover {
      .c-co-lo-agenda-grid-session__background {
        opacity: 0.2;
      }
    }

    .c-co-lo-agenda-grid-session__background {
      background-color: $color;
      opacity: 0.1;
    }

    .c-co-lo-agenda-grid-session__shadow {
      display: none;
    }
  }
}
