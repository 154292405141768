.c-co-lo-agenda-heading {
  font-size: 14px;
  font-weight: 700;

  &__day {
    font-weight: 700;
  }

  &__date {
    font-weight: 500;
    &::before {
      content: ' - ';
    }
  }
}
