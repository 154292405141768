@import '../../../styles/shared';

.c-modal-session-info {
  &__agenda-wrapper {
    align-items: flex-start;

    .c-modal__container {
      background: transparent;

      &--wide {
        max-width: 1000px !important;
      }
    }
  }
}
