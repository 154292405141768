.c-agenda-time-status-schedule {
  &--profile {
    font-size: 16px;
    font-weight: 700;
  }
  &__duration {
    font-weight: 300;
    padding-left: 5px;
  }
}
