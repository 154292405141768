@import '../../../styles/shared';

.c-co-lo-agenda-menu-bar {
  padding: 10px 20px;
  border-bottom: 1px solid #e5e5e5;
  background: #fff;
  position: relative;

  &--sticky {
    position: fixed;
    z-index: 6;
    width: 100%;
  }

  &:after {
    position: absolute;
    content: '';
    height: 20px;
    bottom: -20px;
    background: $body-bg;
  }

  &--closed-filters {
    &:after {
      width: 100%;
      @include left(0);
    }
  }

  &--opened-filters {
    &:after {
      width: 83.33333333%;
      @include left(16.6666666%);

      @include mq($until: desktop) {
        @include left(33.33333333%);
        width: 66.666666%;
      }
    }
  }

  &__desktop-wrapper {
    @include mq($until: tablet) {
      display: none;
    }
  }

  &__toggle-filter-wrapper {
    display: flex;

    &--close {
      justify-content: flex-start;
    }

    &--open {
      justify-content: flex-end;
    }
  }

  &__heading-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__list-view-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
