@import '../../../styles/shared';

.c-spinner {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
  @include margin-right(5px);
  margin-bottom: 0;

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    border: 4px solid;
    border-radius: 50%;
    animation: button-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #e5e5e5 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes button-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
