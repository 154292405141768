@import '../../../../styles/shared';

.lead-gen-radio-btn {
  margin: 10px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include mq($until: tablet) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__title {
    color: #333;
    font-size: 16px;
    line-height: 24px;
  }

  &__error {
    color: #c9474d;
    font-size: 12px;
  }

  &__item {
    display: flex;
    width: 50%;
    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &__native-radio {
    display: none;
  }

  &__label {
    height: 39px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  &__custom-radio {
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid $form-border-color;
    border-radius: 10px;
    margin-right: 16px;

    &--active {
      border: 6px solid #a1a1a1;
    }

    &--error {
      border: 1px solid #c9474d;
    }
  }

  &__text {
    color: #333;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
}
