@import '../../../../styles/shared';

.c-contact-details-form {
  &__copy-billing {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__indicates-required {
    margin: 10px 0;
    font-size: 13px;
  }

  &__select {
    margin-bottom: 10px;

    label {
      @include padding-left(0);
    }

    select {
      @include padding-left(10px);
      border: 1px solid #333;
      border-radius: 0;
    }

    .c-billing-form-field.has-error,
    .c-billing-form-field.active {
      background: none;
    }
  }

  table {
    margin-top: 20px;
  }
}
