@import '../../../styles/shared';

$sb-padding: 20px;

.c-sidebar-filter {
  background: #fff;

  @include mq($until: tablet) {
    margin-top: 80px;
  }

  &__content {
    @include mq($from: tablet) {
      padding-bottom: $sb-padding;
      padding-top: 80px;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;
      padding: 12px $sb-padding 15px;
      margin: 0;

      @include mq($from: tablet) {
        font-size: 28px;
        font-weight: 300;
        line-height: 1.2;
        margin: 20px 0 0;
        padding: 0;
      }
    }

    &-group {
      .closed &-title {
        &::before {
          [dir='ltr'] & {
            transform: rotate(225deg);
          }

          [dir='rtl'] & {
            transform: rotate(-225deg);
          }
        }
      }

      .closed &-list {
        height: 0 !important;
      }

      &-title {
        position: relative;
        font-size: 18px;
        font-weight: bold;
        margin: 15px 0;
        padding: 0 $sb-padding;
        color: #000;

        > div {
          padding: 0;
          cursor: pointer;
        }

        .btn-clear {
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;

          span {
            position: absolute;
            bottom: 3px;
            @include right($sb-padding);
          }
        }
      }

      &-list {
        overflow: hidden;
      }
    }

    &-search {
      &--fluid {
        input {
          border-top: none;
          border-right: none;
        }
      }
    }

    &-input {
      position: relative;
      display: flex;
      align-items: center;

      input {
        display: block;
        width: 100%;
        height: 55px;
        @include padding(0, 46px, 0, $gutter-width);
        font-size: 16px;
        background: #f7f7f7;
        border: 0;
        outline: none;
        color: #56595b;
      }

      @include mq($until: tablet) {
        input {
          height: 45px;
        }

        .material-icons {
          position: absolute;
          top: 10px;
          @include right(15px);
        }
      }

      i {
        position: absolute;
        font-size: 26px;
        top: 15px;
        @include right(30px);

        &.close {
          cursor: pointer;
        }

        @include mq($from: tablet) {
          @include right(10px);
        }
      }
    }
  }
}
