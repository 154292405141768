@import '../../../styles/shared';

.order-success-message {
  padding: 30px 15px 15px;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background-color: #fff;

  &__title {
    max-width: 440px;
    margin-bottom: 40px;
    font-weight: 800;
    font-size: 52px;
    line-height: 1;

    @include mq($until: tablet) {
      margin-bottom: 20px;
      font-size: 36px;
    }

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: 'Aleo', serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__p1-subtitle {
    max-width: 440px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 800;
    line-height: 1;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: 'Aleo', serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__description {
    b {
      font-weight: bold;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__reference-title,
  &__delegate-email {
    font-weight: bold;
  }

  &__reference-number {
    margin: 0;
  }

  &__confirmation-email {
    margin-top: 1em;
  }

  &__delegate-emails-block {
    margin-bottom: 1em;
  }

  &__link {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__check-email {
    color: #56595b;
  }
}
