@import '../../../styles/shared';

.c-search-form-widget {
  &__proposition {
    margin-bottom: 20px;
    font-family: $font-family-sans-serif;
    font-size: 22px;
    line-height: 30px;
    color: #fff;

    @include mq($until: tablet) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__field {
    position: relative;

    @include mq($until: desktop) {
      max-width: 480px;
    }
  }

  &__input {
    width: 100%;
    height: 60px;
    padding: 12px 70px 13px 15px;
    font-family: $font-family-sans-serif;
    font-size: 24px;
    line-height: 33px;
    border: 1px solid #dee0e5;
    border-radius: 5px;
    background-color: #fff;
    color: #333;

    @include mq($until: tablet) {
      height: 40px;
      font-size: 16px;
      line-height: 22px;
    }

    &:focus {
      outline: none;
      background: #efefef;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: -1px;
    width: 60px;
    height: 60px;
    border-radius: 0 5px 5px 0;
    background: $tenant-accent-color;

    @include mq($until: tablet) {
      width: 40px;
      height: 40px;
    }

    i {
      position: relative;
      top: 15px;
      left: 15px;
      font-size: 30px;
      color: #fff;

      @include mq($until: tablet) {
        top: 7px;
        left: 7px;
        font-size: 28px;
      }
    }
  }

  &__link {
    display: inline-block;
    margin-top: 20px;
    user-select: none;

    font-family: $font-family-sans-serif;
    font-size: 22px;
    line-height: 30px;
    color: #fff;

    span {
      border-bottom: 2px solid $tenant-accent-color;
    }

    @include mq($until: tablet) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__icon {
    margin-left: 10px;
    padding-right: 1px;
    width: 30px;
    height: 30px;
    font-size: 22px;
    line-height: 30px;
    border-radius: 30px;
    vertical-align: middle;
    text-align: center;
    background: $tenant-accent-color;
    color: #fff;
  }
}
