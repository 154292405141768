@import '../../../styles/shared';

.c-agenda-stream-logo {
  position: absolute;
  width: 40px;
  height: 30px;
  top: 5px;
  @include right(0);

  &--filter {
    top: 50%;
    transform: translate(0, -50%);
  }
}
