@import '../../../styles/shared';

.c-svg-embed {
  svg {
    position: absolute;
    @include left(0);
    top: 0;
    width: 100%;
    height: 100%;
  }

  &--transparent {
    .cls-1 {
      fill: rgba(255, 255, 255, 0.15);
    }

    .cls-2 {
      fill: rgba(255, 255, 255, 0.25);
    }
  }
}
