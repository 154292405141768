@import '../../../../../../styles/shared';

.c-multi-level-nav-home-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  min-width: $multi-level-nav-level-1-height-mobile;
  color: $multi-level-nav-level-1-text-color;
  border-bottom: $multi-level-nav-underline-width solid transparent;
  transition: all $multi-level-nav-animation-duration ease;
  cursor: pointer;

  @include mq($from: wide) {
    min-width: $multi-level-nav-level-1-height +
      $multi-level-nav-underline-width;
  }

  @include mq($until: desktop) {
    width: 100%;
    @include margin-left(0);
    @include text-align(left);
  }

  &:not(&--one-line) {
    @include mq($until: desktop) {
      border-bottom-color: #fff;
    }
  }

  &:hover:not(&--one-line) {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:not(&--active):hover {
    border-bottom-color: $multi-level-nav-level-1-text-color;
  }

  &__icon {
    font-size: 24px;

    @include mq($until: desktop) {
      display: none;
    }
  }

  &--one-line &__text {
    padding: 0;
    font-size: 22px;
    line-height: 44px;
  }

  &__text {
    padding: 15px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;

    @include mq($from: tablet) {
      padding: 18px 0;
      font-size: 22px;
    }

    @include mq($from: desktop) {
      display: none;
    }
  }
  &__nav-logo {
    max-width: 50px;
    max-height: 50px;
    margin: auto;
    @include mq($until: desktop) {
      display: none;
    }
  }
}

@include one-of-themes([$ONEOFAKIND, $NAJAHSTACK]) {
  .c-multi-level-nav-home-btn {
    border-bottom: none;
    @include mq($from: desktop) {
      border-top: $multi-level-nav-underline-width solid transparent;
    }

    &--one-line &__text {
      font-family: 'GT America Thin', sans-serif;
      font-size: 32px;
      font-weight: 100;
      line-height: 40px;
    }
  }
}

@include theme-only($NAJAHSTACK) {
  .c-multi-level-nav-home-btn {
    &--one-line &__text {
      font-family: 'Proxima Nova Regular', sans-serif;
    }
  }
}

@include theme-only($THEARTISTPROJECT) {
  .c-multi-level-nav-home-btn {
    border-bottom: none;
    @include mq($from: $desktop-breakpoint + 1) {
      border-top: $multi-level-nav-underline-width solid transparent;
    }

    &--one-line &__text {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 32px;
      font-weight: 300;
      line-height: 40px;
    }

    &__icon {
      @include mq($until: $desktop-breakpoint) {
        display: none;
      }
    }

    &__text {
      display: block;

      @include mq($from: $desktop-breakpoint + 1) {
        display: none;
      }
    }
  }
}

@include theme-only($ARTTORONTO) {
  .c-multi-level-nav-home-btn {
    border-bottom: none;
    @include mq($from: $desktop-breakpoint + 1) {
      border-top: $multi-level-nav-underline-width solid transparent;
    }

    &--one-line &__text {
      font-family: 'Barlow Regular', sans-serif;
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
    }

    &__icon {
      @include mq($until: $desktop-breakpoint) {
        display: none;
      }
    }

    &__text {
      display: block;

      @include mq($from: $desktop-breakpoint + 1) {
        display: none;
      }
    }
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-multi-level-nav-home-btn {
    border-bottom: none;
    @include mq($from: desktop) {
      border-top: $multi-level-nav-underline-width solid transparent;
    }

    &--one-line &__text {
      font-family: 'Gibson SemiBold', sans-serif;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
    }
  }
}
