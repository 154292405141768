@import '../../../../styles/shared';

.lead-gen-input {
  margin: 10px 0;

  &__label {
    display: flex;
    justify-content: space-between;
    height: 34px;
    padding-bottom: 8px;
    &-name {
      font-size: 16px;
      color: #333;
      line-height: 19px;
    }
    &-error {
      color: #c9474d;
      font-size: 12px;
    }
    &-pre-req {
      color: #ee7a04;
      font-size: 12px;
    }
  }

  &__text,
  &__password {
    border: 1px solid $form-border-color;
    display: block;
    width: 100%;
    padding: 10px 12px;
    border-radius: 5px;
    color: #333;

    &:focus {
      outline: none;
    }
  }

  &__error {
    border-color: #c9474d;
  }

  &__pre-req {
    border-color: rgba(238, 122, 4, 0.2);
    background-color: rgba(238, 122, 4, 0.1);
  }

  &__password-wrap {
    position: relative;
  }

  &__password-show {
    position: absolute;
    bottom: 1px;
    //@include right(1px);
    right: 1px;
    outline: none;
    height: 42px;
    line-height: 42px;
    border-radius: 0 6px 6px 0;
    background: #fff;
    border-width: 0 0 0 1px;
    border-left-color: $form-border-color;
    border-left-style: solid;
    //[dir='ltr'] & {
    //  border-left-color: $form-border-color;
    //  border-left-style: solid;
    //}
    //
    //[dir='rtl'] & {
    //  border-right-color: $form-border-color;
    //  border-right-style: solid;
    //}
    padding: 0 10px;
    width: 55px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
  }
}
