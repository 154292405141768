@import '../../../../../styles/shared';

.c-ticket-count {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: $multi-level-nav-level-1-height-mobile;
  height: $multi-level-nav-level-1-height-mobile;
  text-decoration: none;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  transition: all $multi-level-nav-animation-duration;
  color: #fff;

  @include mq($from: wide) {
    width: $multi-level-nav-level-1-height;
    height: $multi-level-nav-level-1-height;
  }

  &:hover {
    border-bottom-color: $multi-level-nav-level-1-text-color;
  }

  &:not(&--one-line):hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__icon {
    position: relative;
    top: 1px;
    font-size: 26px;
  }

  &__counter {
    position: absolute;
    top: 8px;
    @include right(0);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-weight: 800;
    font-size: 12px;
    line-height: 12px;
    color: #000;
    border-radius: 50%;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include mq($from: tablet) {
      top: 6px;
      @include right(6px);
    }

    @include mq($from: desktop) {
      top: 7px;
      @include right(7px);
    }

    @include mq($from: wide) {
      top: 10px;
      @include right(10px);
    }
  }
}
