@import '../../../../../../../styles/shared';

$menu-max-width: 1220px;

.events-list-table {
  padding-top: 20px;
  font-weight: 400;
  line-height: 1;
  background-color: #fff;
  color: #333;

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 800px;

    @include mq($until: $menu-max-width) {
      width: 840px;
    }

    @include mq($until: tablet) {
      flex-wrap: nowrap;
      width: 100%;
    }

    &--one {
      width: 200px;
    }

    &--two {
      width: 400px;
    }

    &--three {
      width: 600px;
    }
  }

  &__events &__header,
  &__learning &__header {
    margin-top: 15px;
  }

  &__header {
    margin: 0 0 15px 20px;
    font-size: 22px;
    font-weight: 300;
    text-transform: capitalize;
  }

  &__item {
    position: relative;
    width: 180px;
    min-width: 180px;
    margin-left: 20px;
    margin-bottom: 10px;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #dee0e5;
    }

    &-line {
      position: relative;
      top: -15px;
      display: block;
      height: 2px;
      width: 25px;
      background-color: #542650;
      transition: width 0.3s;
    }

    &:hover &-line {
      width: 100%;
    }

    &-link {
      display: block;
      margin-top: 13px;
      text-decoration: none;
      color: inherit;
    }

    &-title {
      margin: 0;
      font-family: 'Noto Sans', sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: #542650;
      line-height: 1.25;
    }

    &-date,
    &-description {
      font-size: 12px;
      margin-top: 15px;
    }

    &-city,
    &-button {
      font-size: 14px;
      font-weight: 600;
      margin-top: 8px;
    }

    &-more {
      box-sizing: content-box;
      position: absolute;
      top: 0;
      right: 0;
      width: 70px;
      min-width: 70px;
      bottom: 0;
      margin-bottom: 0;

      @include mq($until: tablet) {
        position: static;
        padding-right: 40px;
      }
    }

    &-more &-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  &__icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    display: block;
    margin-top: 5px;
    color: #542650;
  }

  .scroll-window {
    overflow-x: auto;

    &--has-more-link {
      margin-right: 90px;

      @include mq($until: tablet) {
        margin-right: 0;
      }

      .events-list-table__list:after {
        @include mq($until: $menu-max-width) {
          content: '';
          position: absolute;
          right: 90px;
          width: 40px;
          top: 0;
          bottom: 0;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgb(255, 255, 255) 100%
          );
        }

        @include mq($until: tablet) {
          right: 0;
        }
      }
    }

    &-outer {
      position: relative;
      width: calc(100vw - 330px);
      max-width: 890px;

      @include mq($until: tablet) {
        width: 100vw;
      }
    }
  }
}

.loading-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 1.5;
  background: #fff;
  color: #000;

  @include mq($until: tablet) {
    padding: 10px 0;
  }

  &__icon {
    margin-right: 10px;
  }
}
