@import '../../../../styles/shared';

.c-co-lo-agenda-grid-scroll-btn {
  display: block;
  @include right(16px);
  position: absolute;
  top: 20px;
  bottom: 16px;
  width: 50px;
  text-align: center;
  background-color: #cccccc;
  opacity: 0.4;
  z-index: 5;
  cursor: pointer;
  user-select: none;

  &__arrow {
    position: fixed;
    top: 55%;
    width: 50px;
    z-index: 3;
    color: #666;

    i {
      font-size: 36px;
    }
  }

  &__left {
    left: 16px;
  }

  &__right {
    right: 16px;
  }
}
