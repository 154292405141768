@import '../../../styles/shared';

.modal-vip-code {
  padding: 30px 20px;

  @include mq($until: tablet) {
    padding: 15px;
  }

  &__title {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.2;
  }

  &__input-row {
    margin: 30px 0;

    @include mq($until: tablet) {
      margin: 20px 0;
    }
  }

  &__input {
    width: 100%;
    height: 30px;
    @include padding(0, 10px, 1px, 10px);
    font-size: 12px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;

    &:not(:focus) {
      outline: none;
    }

    &--with-error {
      background-color: $form-light-error-color;
      border-color: #c9474d;
    }
  }

  &__error-message {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.4;
    @include text-align(right);
    color: #c9474d;
  }

  &__buttons-row {
    display: flex;
    justify-content: flex-end;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  &__cancel,
  &__apply {
    padding: 18px 20px 14px !important;
  }
}
