@import '../../../../styles/shared';

.package-price {
  &__original {
    margin: 0;
    font-size: 12px;
    text-decoration: line-through;
  }

  &__final {
    margin: 0;

    &--with-discount {
      color: $form-error-color;
    }
  }
}
