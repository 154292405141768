@import '../../../styles/shared';

.c-modal__container {
  max-width: 900px;
  border-radius: 5px;
  background: linear-gradient(to top right, #e7e7e7, #fff);
  z-index: 1;

  @include mq($until: tablet) {
    margin: 0 20px;
  }
}

.modal-ma {
  @include padding(50px, 45px, 60px, 50px);
  border-radius: 5px;
  color: #fff;
  @include mq($until: tablet) {
    @include padding(30px, 20px, 40px, 20px);
  }

  &.radio {
    @include padding(50px, 60px, 50px, 80px);
    @include mq($until: tablet) {
      padding: 30px 20px 40px 20px;
    }

    .choice-close {
      margin-top: 0;
      @include mq($until: tablet) {
        margin-top: 20px;
      }
    }
  }

  .header-text {
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-weight: 800;
    font-size: 40px;
    padding-bottom: 20px;
    line-height: 45px;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include mq($until: tablet) {
      font-size: 28px;
      padding-bottom: 10px;
      line-height: 32px;
    }
  }

  .intro-text {
    padding-bottom: 40px;

    @include mq($until: tablet) {
      padding-bottom: 20px;
    }
  }

  .content {
    padding-bottom: 20px;

    &.radio {
      @include mq($until: tablet) {
        padding-bottom: 10px;
      }
    }

    &.text {
      display: flex;

      .button + .button {
        @include margin-left(10px);

        @include mq($until: tablet) {
          margin: 10px 0 0 0;
        }
      }

      @include mq($until: tablet) {
        display: block;
      }
    }

    .radio-custom-wrapper {
      padding-bottom: 20px;
      @include padding-left(30px);

      .radio {
        display: none;

        &-custom {
          border-radius: 50%;
          display: inline-block;
          vertical-align: top;
          width: 20px;
          height: 20px;
          border: 2px solid #fff;
          position: relative;
          cursor: pointer;
          @include margin-left(-30px);
        }

        &-prompt {
          display: inline-block;
          @include margin-left(15px);
          font-size: 16px;
          line-height: normal;
          font-weight: bold;
        }

        &:checked + .radio-custom::before {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          right: 2px;
          bottom: 2px;
          left: 2px;
          background: #fff;
          border-radius: 50%;
        }
      }
    }
  }

  .button {
    border-radius: 5px;
    border: 1px solid #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    color: #000;
    text-decoration: inherit;
    background-color: rgba(255, 255, 255, 0.15);

    &:hover {
      box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.39);
    }
  }

  .apply-prompt {
    vertical-align: top;
    width: calc(33.333333333333336%);
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-size: 24px;
    min-height: 90px;
    text-align: left;
    margin-top: 0;
    font-weight: 800;
    line-height: 26px;
    padding: 15px 20px;
    border: 1px solid #fff;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include mq($until: tablet) {
      width: 100%;
      display: inline-flex;
    }
  }

  .submit-prompt {
    display: block;
    text-align: center;
    height: 60px;
    font-size: 24px;
    line-height: 60px;
    font-family: 'Barlow Condensed Extra Bold', sans-serif;
    font-weight: 800;
    color: #fff;

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    &.inactive {
      color: rgba(255, 255, 255, 0.25);
    }

    > span {
      text-transform: uppercase;
      font-weight: 700;
      width: 100%;
      margin: auto;
    }
  }

  .choice-close {
    font-weight: 500;
    font-size: 18px;
    position: relative;
    height: 100%;
    margin-right: -10px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;

    @include mq($until: tablet) {
      margin-top: 10px;
    }

    .popup-close-btn {
      color: #fff;
      cursor: pointer;
      span {
        float: left;
      }
    }

    .popup-close {
      display: block;
      height: 22px;
      width: 30px;
      padding-top: 2px;
      line-height: 14px;
      font-size: 46px;
      font-weight: 300;
      cursor: pointer;
      overflow: hidden;
      margin-left: 10px;

      @include mq($until: tablet) {
        font-size: 36px;
        width: 25px;
      }
    }
  }
}
