@import '../../../styles/shared';

.c-agenda-time-zone {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  @include mq($until: tablet) {
    display: block;
    font-size: 12px;
    font-weight: 300;
  }

  &--modal {
    color: #000;
    font-size: 16px;
    font-weight: 300;
  }

  &__hyphen {
    @include mq($until: tablet) {
      display: none;
    }
  }
}
