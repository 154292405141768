@import '../../../styles/shared';

.c-create-password-section {
  margin: 20px 0;
  border-radius: 3px;
  color: #000;

  &.c-event-section a {
    color: #337ab7;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .c-form-help-msg {
    p {
      margin-top: 33px;
    }
  }
}

.c-create-password-banner {
  background-color: #fff;
  border-bottom: 1px solid $form-border-color;
  border-radius: 3px;
  padding: 25px 20px;

  .account-activated-section {
    display: table;

    .account-activated-success-icon {
      display: table-cell;
      vertical-align: middle;
      color: #70d77b;

      .check-circle {
        padding: 4px;
        border: 2px solid;
        border-radius: 50%;
        width: 40px;
        height: 40px;

        i {
          padding-top: 7px;
          padding-left: 6px;
        }
      }
    }

    .account-activated-msg {
      display: table-cell;
      vertical-align: middle;
      padding-left: 20px;

      h2 {
        padding-top: 5px;
      }
    }
  }

  h2 {
    color: #002144;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    text-transform: inherit;
  }

  .header-left {
    float: left;
    width: 80%;
  }

  .header-right {
    line-height: 1;
    float: right;
    width: 20%;

    & > * {
      float: right;
    }
  }
}
