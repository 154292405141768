@import '../../../../../styles/shared';

.c-hamburger {
  position: relative;
  display: inline-block;
  width: 26px;
  min-width: 26px;
  font-size: 42px;
  color: #fff;
  cursor: pointer;

  &--left {
    @include margin-right(21px);
  }

  &--right {
    @include margin-left(21px);
  }

  &--active {
    top: -1px;
    @include padding-left(4px);
  }

  &__word {
    display: inline-block;
    margin-top: 3px;
    @include margin-left(-10px);
    font-size: 16px;
    font-weight: 500;
    color: $multi-level-nav-level-1-text-color;

    @include theme-only($ASIATECHXSG) {
      margin-top: 0;
    }

    &::after {
      position: absolute;
      top: 0;
      [dir='ltr'] & {
        left: 115px;
      }
      [dir='rtl'] & {
        right: 130px;
      }
      bottom: 0;
      content: '';
      display: block;
      width: 1px;
      background-color: rgba($multi-level-nav-level-1-text-color, 0.2);

      @include nav-height;
    }
  }

  &__line {
    position: relative;
    display: block;
    height: 2px;
    border-radius: 2px;
    background-color: $multi-level-nav-level-1-text-color;

    &:not(:first-child) {
      margin-top: 6px;
    }
  }

  &--active &__line:nth-child(1) {
    display: none;
  }

  &--active &__line:nth-child(2) {
    top: 2px;
    width: 19px;
    margin-top: 0;
    transform: rotate(45deg);
  }

  &--active &__line:nth-child(3) {
    width: 19px;
    margin-top: 0;
    transform: rotate(-45deg);
  }
}

@include theme-only($ONEOFAKIND) {
  .c-hamburger {
    &__word {
      font-family: 'GT America Thin', sans-serif;
      font-size: 18px;
      font-weight: 100;
      line-height: 23px;

      &::after {
        display: none;
      }
    }
  }
}

@include theme-only($THEARTISTPROJECT) {
  .c-hamburger {
    &__word {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 18px;
      font-weight: 300;
      line-height: 23px;

      &::after {
        display: none;
      }
    }
  }
}

@include theme-only($ARTTORONTO) {
  .c-hamburger {
    &__word {
      font-family: 'Barlow Regular', sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;

      &::after {
        display: none;
      }
    }
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-hamburger {
    &__word {
      font-family: 'Gibson', sans-serif;
      font-size: 18px;
      line-height: 23px;

      &::after {
        display: none;
      }
    }
  }
}

@include theme-only($NAJAHSTACK) {
  .c-hamburger {
    &__word {
      font-size: 18px;
      font-weight: 100;
      font-family: 'Proxima Nova Regular', sans-serif;
      color: $multi-level-nav-level-1-text-color;

      &::after {
        display: none;
      }
    }
    &__line {
      background-color: $multi-level-nav-level-1-text-color;
    }
  }
}
