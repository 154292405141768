@import '../../../styles/shared';

.basket-header {
  padding: 28px 0;

  @include mq($until: tablet) {
    padding-bottom: 16px;
  }

  &__title {
    font-size: 28px;
    font-weight: 300;
    line-height: 35px;

    @include mq($until: tablet) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &__links-column {
    display: flex;
    justify-content: flex-end;

    @include mq($until: tablet) {
      justify-content: flex-start;
    }
  }

  &__links-list {
    display: inline-flex;
    flex-direction: row;
    line-height: 35px;

    @include mq($until: tablet) {
      flex-direction: column;
      padding-top: 2px;
      line-height: initial;
    }
  }

  &__link {
    padding: 0 11px;
  }

  &__links-item {
    @include mq($until: tablet) {
      padding-top: 10px;
    }

    &:last-child .basket-header__link {
      @include padding-right(0);
    }
  }
}
