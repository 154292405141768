@import '../../../../styles/shared';

.c-base-section-cta {
  margin-top: 20px;

  .c-cta {
    margin-top: 15px;

    &:not(:last-child) {
      // fix for cta with long text
      @include mq($from: tablet) {
        @include margin-right(15px);
      }
    }
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-base-section-cta {
    .c-cta {
      margin-top: 10px;
      @include margin-right(10px);

      &:last-child {
        @include margin-right(0);
      }
    }
  }
}
