@import 'variables';
@import 'sass-mq/mq';

@include mq-add-breakpoint(mobile, $mobile-breakpoint);
@include mq-add-breakpoint(tablet, $tablet-breakpoint);
@include mq-add-breakpoint(desktop, $desktop-breakpoint);
@include mq-add-breakpoint(wide, $large-desktop-breakpoint);

@include mq-add-breakpoint(tablet-plus, $tablet-plus-breakpoint);
@include mq-add-breakpoint(desktop-medium, $desktop-medium-breakpoint);
@include mq-add-breakpoint(desktop-plus, $desktop-plus-breakpoint);
@include mq-add-breakpoint(extended-layout, $extended-layout-width);
@include mq-add-breakpoint(ultra-wide, $ultra-wide-breakpoint);

@mixin linear-gradient($from, $to) {
  background-image: -moz-linear-gradient($from, $to);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($from), to($to));
  background-image: -o-linear-gradient($from, $to);
  background-image: linear-gradient(to bottom, $from, $to);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$from, endColorstr=$to, GradientType=0);
}

@mixin overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

@mixin nav-height() {
  height: $multi-level-nav-level-1-height-mobile;

  @include mq($from: wide) {
    height: $multi-level-nav-level-1-height;
  }
}

@mixin margin($top, $right, $bottom, $left) {
  [dir='ltr'] & {
    margin: $top $right $bottom $left;
  }

  [dir='rtl'] & {
    margin: $top $left $bottom $right;
  }
}

@mixin margin-left($value) {
  [dir='ltr'] & {
    margin-left: $value;
  }

  [dir='rtl'] & {
    margin-right: $value;
  }
}

@mixin margin-right($value) {
  [dir='ltr'] & {
    margin-right: $value;
  }

  [dir='rtl'] & {
    margin-left: $value;
  }
}

@mixin padding($top, $right, $bottom, $left) {
  [dir='ltr'] & {
    padding: $top $right $bottom $left;
  }

  [dir='rtl'] & {
    padding: $top $left $bottom $right;
  }
}

@mixin padding-left($value) {
  [dir='ltr'] & {
    padding-left: $value;
  }

  [dir='rtl'] & {
    padding-right: $value;
  }
}

@mixin padding-right($value) {
  [dir='ltr'] & {
    padding-right: $value;
  }

  [dir='rtl'] & {
    padding-left: $value;
  }
}

@mixin text-align($value) {
  @if $value == left {
    [dir='ltr'] & {
      text-align: left;
    }

    [dir='rtl'] & {
      text-align: right;
    }
  } @else if $value == right {
    [dir='ltr'] & {
      text-align: right;
    }

    [dir='rtl'] & {
      text-align: left;
    }
  } @else {
    text-align: $value;
  }
}

@mixin float($value) {
  @if $value == left {
    [dir='ltr'] & {
      float: left !important;
    }

    [dir='rtl'] & {
      float: right !important;
    }
  } @else if $value == right {
    [dir='ltr'] & {
      float: right !important;
    }

    [dir='rtl'] & {
      float: left !important;
    }
  } @else {
    float: $value !important;
  }
}

@mixin left($value) {
  [dir='ltr'] & {
    left: $value;
  }

  [dir='rtl'] & {
    right: $value;
  }
}

@mixin right($value) {
  [dir='ltr'] & {
    right: $value;
  }

  [dir='rtl'] & {
    left: $value;
  }
}

@mixin border-left($value) {
  [dir='ltr'] & {
    border-left: $value;
  }

  [dir='rtl'] & {
    border-right: $value;
  }
}

@mixin border-right($value) {
  [dir='ltr'] & {
    border-right: $value;
  }

  [dir='rtl'] & {
    border-left: $value;
  }
}
