@import '../../../styles/shared';

.c-vip-code-notification-sticky-wrapper {
  position: relative;
  z-index: 10000;

  .c-vip-code-notification {
    background: #fff;
    position: absolute;
    top: 80px;
    @include right(10px);
    font-size: 12px;
    max-width: 400px;
    border-radius: 3px;
    opacity: 0.95;

    .table {
      display: table;
      padding: 15px 10px;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.18);
      border-radius: 3px;

      .icon {
        display: table-cell;
        vertical-align: top;
      }

      .success {
        color: #70cb14;
        padding-top: 0.3em;
        @include padding-right(0.5em);
        font-size: 1.9em;
        line-height: 1.1em;
      }

      .close {
        @include padding(0, 0, 0, 10px);

        .icon-close {
          font-size: 18px;
          cursor: pointer;
        }
      }

      p {
        margin: 0 0 0.25em 0;
      }

      .title {
        font-weight: 700;
        color: #000;
      }
    }
  }

  @include mq($until: tablet) {
    .c-vip-code-notification-wrapper {
      position: fixed;
      top: auto !important;
      bottom: 0;
      max-width: initial;
      width: 100%;

      .c-vip-code-notification {
        position: absolute;
        top: auto;
        bottom: 10px;
        left: 10px;
        right: 10px;
        max-width: initial;

        .table {
          width: 100%;
        }
      }
    }
  }
}
