@import '../../../../styles/shared';

.c-event-section {
  position: relative;
  padding: 60px 0;
  background-color: inherit;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  color: #fff;

  @include mq($until: tablet) {
    padding: 40px 0;
  }

  &--color-black {
    color: #000;
  }

  &--less-padding {
    padding: 60px 0;
  }

  &--small-padding {
    padding: 20px 0;
  }

  &--without-padding {
    padding: 0;

    .container-fluid {
      @include mq($until: tablet) {
        padding: 0 ($gutter-width * 0.5);
      }
    }
  }

  &__heading {
    margin: 0 0 40px;
    font-weight: 700;
    font-size: 40px;
    line-height: 1;

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }

    @include mq($until: tablet) {
      font-size: 32px;
      margin-bottom: 30px;
    }

    @include theme-only($ASIATECHXSG) {
      font-size: 38px;
    }

    &--small-margin {
      margin-bottom: 20px;
    }

    &--tiny-margin {
      margin-bottom: 10px;
    }

    &--without-margin {
      margin: 0;
    }
  }

  &__body {
    margin: 0 0 20px;

    @include mq($until: tablet) {
      font-size: 18px;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__map {
    position: relative;
    height: 320px;

    @include mq($from: tablet) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: auto;
    }
  }

  &__box {
    position: relative;
    padding: 30px;
    overflow: hidden;
    z-index: 2;
    color: #fff;

    @include mq($until: tablet) {
      margin-left: -$gutter-width;
      margin-right: -$gutter-width;
    }
  }

  &__cta {
    margin-top: 40px;

    .c-button {
      margin-top: 0;

      &--with-margin {
        margin-top: 10px;
      }
    }

    &.less {
      margin-top: 26px;
    }

    &--without-margin {
      margin: 0;
    }
  }

  &__image {
    max-width: 100%;
  }

  &__body,
  &__item {
    ul {
      list-style: disc;
      @include padding-left(40px);

      li {
        ul {
          margin-top: 20px;
          list-style: circle;
        }
      }

      li + li {
        margin-top: 20px;
      }
    }
  }

  &__items {
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    & + & {
      margin-top: 40px;
    }
  }

  &__column {
    flex-grow: 1;
    width: 33%;
    padding: 0 10px;

    & + & {
      @include mq($until: tablet) {
        margin-top: 40px;
      }
    }

    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &__item {
    position: relative;
    height: 100%;

    @include mq($until: tablet) {
      height: initial;
    }
  }

  &__button {
    margin-top: 10px;
  }

  &--theme {
    &-light {
      color: #000;

      a:not(.c-button):not(.c-cta) {
        color: #000;
      }
    }

    &-brand_dark {
      .c-custom-text-section__item {
        a:not(.c-button):not(.c-cta) {
          color: #000;
        }
      }
    }

    &-dark {
      color: #fff;

      a:not(.c-button):not(.c-cta) {
        color: #fff;
      }
    }

    &-brand {
      color: #fff;

      a:not(.c-button):not(.c-cta) {
        color: #fff;
      }
    }
  }
}
