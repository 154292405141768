@import '../../../styles/shared';

.c-search-filters {
  height: 100%;
  background-color: #fff;
  padding: 20px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-flow: wrap;
  }

  &__action-buttons {
    @include mq($from: desktop) {
      display: none;
    }
  }
}
