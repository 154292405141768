@import '../../../../../../styles/shared';

.c-terms-conditions-checkbox {
  color: #000;
  display: flex;
  margin-bottom: 16px;

  &__input {
    cursor: pointer;
    height: 13px;
    width: 13px;
    min-width: 13px;
  }

  &__label {
    @include margin-left(6px);
    cursor: pointer;
  }

  &__link {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}
