@import '../../../../styles/shared';

$block-margin: 40px;

.c-l2-topic-box {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 20px;
  height: calc(100% - #{$block-margin});
  margin-top: $block-margin;
  text-decoration: none;
  border-left: 1px solid $tenant-border-separator-color;
  background-color: #e5e5e5;
  transition: background-color 0.4s ease;

  &:hover {
    border-left: 1px solid $tenant-accent-color;
    background-color: rgba(204, 204, 204, 0.2);
  }

  &--has-brands {
    @include mq($from: desktop) {
      padding-bottom: 60px;
    }
  }

  &__name {
    display: flex;
    flex-direction: row;
    font-family: $font-family-serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 25px;
    color: $tenant-primary-color;

    span {
      flex-grow: 1;
    }
  }

  &--primary &__name {
    font-size: 28px;
    line-height: 34px;

    @include mq($until: desktop) {
      font-size: 26px;
    }
  }

  &__icon {
    position: relative;
    top: 5px;
    width: 17px;
    height: 17px;
    min-width: 17px;
    margin-left: 5px;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    border-radius: 50%;
    background: $tenant-primary-color;
    color: #fff;

    &--external {
      font-size: 20px;
      background: transparent;
      color: $tenant-primary-color;
    }
  }

  &--primary &__icon {
    top: 5px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    font-size: 18px;
    line-height: 24px;
  }

  &--primary &__icon--external {
    font-size: 24px;
  }

  &__description {
    margin-top: 10px;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: #000;
  }

  &__featured-brands {
    margin-top: 20px;

    @include mq($from: desktop) {
      display: flex;
      flex-direction: row;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50px;
      margin-top: 0;
    }

    &-label {
      font-size: 14px;
      line-height: 16px;
      color: $tenant-primary-color;

      @include mq($from: desktop) {
        display: none;
      }

      @include mq($from: 1345px) {
        display: inline-block;
        padding: 17px 0 17px 20px;
        min-width: 125px;
      }
    }

    &-list {
      margin-top: 10px;

      @include mq($from: desktop) {
        flex-grow: 1;
        margin: 9px 20px;
        overflow: hidden;
      }
    }

    &-item {
      margin-right: 20px;
      display: inline-block;
      font-family: 'Noto Sans', sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
      color: $tenant-primary-color;

      @include mq($from: desktop) {
        margin-right: 0;
      }

      & + & {
        margin-top: 10px;

        @include mq($from: desktop) {
          margin-top: 0;
          margin-left: 20px;
        }
      }

      img {
        max-height: 32px;
        vertical-align: middle;
      }
    }
  }

  &:hover &__icon {
    background-color: #fff;
    color: $tenant-accent-color;
  }

  &:hover &__icon--external {
    background-color: transparent;
    color: $tenant-accent-color;
  }
}
