@import '../../../../styles/shared';

.c-form-heading {
  margin-bottom: 15px;
  font-weight: 300;

  &.default {
    font-size: 16px;
  }

  &.small {
  }

  &.medium {
  }

  &.large {
    font-size: 22px;
    margin-top: 10px;
  }
}
