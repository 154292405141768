@import '../../../../../../styles/shared';

.c-multi-level-nav-btn {
  &__one-line {
    display: flex;
    align-items: center;

    &-arrow {
      margin-left: 3px;
      margin-right: -7px;
      transition: transform;
      transition-delay: $multi-level-nav-animation-delay;

      @include mq($until: desktop) {
        display: none;
      }

      @include one-of-themes-except([$FANEXPOHQ]) {
        margin-top: 2px;
      }
    }
  }
}

@include one-of-themes-except([$FANEXPOHQ, $MEFCC]) {
  .c-multi-level-nav-btn {
    transition: all $multi-level-nav-animation-duration ease;

    &__upper {
      font-size: 13px;
      font-weight: 700;
      line-height: 17px;

      @include mq($from: wide) {
        font-size: 15px;
        line-height: 21px;
      }
    }

    &__lower {
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;

      @include mq($from: wide) {
        font-size: 15px;
        line-height: 21px;
      }
    }

    &__one-line {
      font-size: 22px;
      font-weight: 500;
      line-height: 44px;

      @include theme-only($DEFAULT) {
        font-weight: 600;
      }

      @include mq($from: tablet) {
        line-height: 56px;
      }

      @include mq($from: desktop) {
        font-size: 14px;
        line-height: 16px;
      }

      @include mq($from: wide) {
        font-size: 18px;
        line-height: 21px;
      }
    }

    &--level-2 &__one-line {
      font-size: 14px;
      font-weight: 400;

      @include mq($from: wide) {
        font-size: 16px;
        font-weight: 500;
      }
    }

    &--multi-line {
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &--active {
      border-bottom: $multi-level-nav-underline-width solid;
    }

    &--group-active {
      border-bottom: $multi-level-nav-underline-width solid
        $multi-level-nav-level-1-text-color !important;
    }

    &:not(&--active) {
      border-bottom: $multi-level-nav-underline-width solid transparent !important;

      &:hover {
        border-bottom-color: $multi-level-nav-level-1-text-color !important;
      }
    }

    &__link {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 15px;
      font-size: 28px;
      font-weight: bold;
      line-height: 33px;
      text-decoration: none;
      vertical-align: middle;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      @include mq($from: desktop) {
        flex-direction: column;
        justify-content: center;
      }
    }

    &--level-1 > &__body &__link {
      color: $multi-level-nav-level-1-text-color;

      @include nav-height;
    }

    &--level-2 > &__body &__link {
      color: $multi-level-nav-level-2-text-color;

      @include mq($from: desktop) {
        height: $multi-level-nav-level-2-height;
      }
    }
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  $space-between-items: 26px;

  .c-multi-level-nav-btn {
    &__body {
      position: relative;
      display: block;
      padding: 0 $space-between-items * 0.5;

      &::before {
        @include mq($from: desktop) {
          position: absolute;
          right: 0;
          left: 0;
          display: none;
          height: 32px;
          border: 2px solid transparent;
          content: '';
          cursor: pointer;
        }
      }
    }

    &__upper {
      @include mq($until: desktop) {
        margin-right: 5px;
      }
    }

    &--active > &__body::before {
      display: block;
    }

    &--level-1-active > &__body::before {
      @include mq($from: desktop) {
        top: calc(#{$multi-level-nav-level-1-height} / 2 - 16px);
        border-color: $multi-level-nav-level-1-text-color;
      }
    }

    &--level-2-active > &__body::before {
      @include mq($from: desktop) {
        top: calc(#{$multi-level-nav-level-2-height} / 2 - 16px);
        border-color: $multi-level-nav-level-2-text-color;
      }
    }

    &--multi-line-active > &__body::before {
      @include mq($from: desktop) {
        top: calc(#{$multi-level-nav-level-1-height} / 2 - 27px);
        height: 53px;
      }
    }

    &__link {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-family: 'Proxima Nova ExCn Rg', sans-serif;
      font-size: 28px;
      font-weight: bold;
      line-height: 33px;
      text-decoration: none;
      text-transform: uppercase;
      vertical-align: middle;
      white-space: nowrap;
      cursor: pointer;

      @include theme-only($MEFCC) {
        font-family: 'Barlow Condensed Regular', sans-serif;
      }

      margin-left: -$space-between-items * 0.5;
      margin-right: -$space-between-items * 0.5;
      padding-left: $space-between-items * 0.5;
      padding-right: $space-between-items * 0.5;

      &::before,
      &::after {
        display: block;
        margin-top: 2px;
        border-bottom: 2px solid transparent;
        content: '';
      }

      &:hover {
        text-decoration: none;
      }

      @include mq($from: tablet) {
        font-size: 19px;
      }

      @include mq($from: desktop) {
        line-height: 23px;
      }

      @include mq($from: tablet, $until: desktop) {
        line-height: 27px;
      }

      @include mq($from: desktop) {
        flex-direction: column;
        justify-content: center;
      }
    }

    &--multi-line-active &__link {
      &::before,
      &::after {
        margin-top: 1px;
      }
    }

    &--level-1 > &__body &__link {
      color: $multi-level-nav-level-1-text-color;

      @include mq($from: desktop) {
        height: $multi-level-nav-level-1-height;
      }
    }

    &--level-2 > &__body &__link {
      color: $multi-level-nav-level-2-text-color;

      @include mq($from: desktop) {
        height: $multi-level-nav-level-2-height;
      }
    }

    &--level-1:not(&--level-1-active):hover > &__body &__link::after {
      @include mq($from: desktop) {
        border-color: $multi-level-nav-level-1-text-color;
      }
    }

    &--level-2:not(&--level-2-active):hover > &__body &__link::after {
      @include mq($from: desktop) {
        border-color: $multi-level-nav-level-2-text-color;
      }
    }
  }
}

@include theme-only($ASIATECHXSG) {
  .c-multi-level-nav-btn {
    &--level-1 &__one-line,
    &--level-2 &__one-line {
      font-weight: 700;

      @include mq($from: desktop) {
        font-size: 14px;
      }
    }
  }
}

@include theme-only($ONEOFAKIND) {
  .c-multi-level-nav-btn {
    &--level-1 &__one-line,
    &--level-2 &__one-line {
      color: $body-color;
    }
  }
}

@include one-of-themes([$ONEOFAKIND, $NAJAHSTACK]) {
  .c-multi-level-nav-btn {
    &--level-1 &__one-line,
    &--level-2 &__one-line {
      font-family: 'GT America Thin', sans-serif;
      font-size: 18px;
      line-height: 23px;
      font-weight: 100;

      @include mq($until: desktop) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &__link {
      padding: 0 16px;
    }

    &--active {
      border-bottom: none;

      @include mq($until: desktop) {
        border-top: none !important;
      }
    }

    &--group-active {
      border-bottom: none !important;

      @include mq($until: desktop) {
        border-top: none !important;
      }
    }

    &:not(&--active) {
      border-bottom: none !important;

      &:hover {
        border-bottom-color: transparent !important;

        @include mq($until: desktop) {
          border-top-color: transparent !important;
        }
      }
    }
  }
}

@include theme-only($NAJAHSTACK) {
  .c-multi-level-nav-btn {
    &--level-1 &__one-line,
    &--level-2 &__one-line {
      font-family: 'Proxima Nova Regular', sans-serif;
    }
    &__link--group-level-1 {
      &:after {
        content: '';
        border-top: 6px solid $multi-level-nav-level-1-text-color;
      }
    }
  }
}

@include one-of-themes([$ARTTORONTO, $THEARTISTPROJECT]) {
  .c-multi-level-nav-btn {
    &--level-1 &__one-line,
    &--level-2 &__one-line {
      @include theme-only($ARTTORONTO) {
        font-family: 'Barlow Regular', sans-serif;
        font-weight: 400;
        color: $body-color;
      }
      @include theme-only($THEARTISTPROJECT) {
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
      }
      font-size: 16px;
      line-height: 28px;

      @include mq($until: $desktop-breakpoint) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &__link {
      padding: 0 16px;
    }

    &--active {
      border-bottom: none;

      @include mq($until: $desktop-breakpoint) {
        border-top: none !important;
      }
    }

    &--group-active {
      border-bottom: none !important;

      @include mq($until: $desktop-breakpoint) {
        border-top: none !important;
      }
    }

    &:not(&--active) {
      border-bottom: none !important;

      &:hover {
        border-bottom-color: transparent !important;

        @include mq($until: $desktop-breakpoint) {
          border-top-color: transparent !important;
        }
      }
    }
  }
}

@include theme-only($INTERIORDESIGN) {
  .c-multi-level-nav-btn {
    &--level-1 &__one-line {
      font-family: 'Gibson SemiBold', sans-serif;
      font-size: 18px;
      line-height: 22px;
      color: $body-color;

      @include mq($until: desktop) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &--level-2 &__one-line {
      font-family: 'Gibson', sans-serif;
      font-size: 18px;
      line-height: 21px;
      color: $body-color;

      @include mq($until: desktop) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &__link {
      padding: 0 16px;
    }

    &--active {
      border-bottom: none;

      @include mq($until: desktop) {
        border-top: none !important;
      }
    }

    &--group-active {
      border-bottom: none !important;

      @include mq($until: desktop) {
        border-top: none !important;
      }
    }

    &:not(&--active) {
      border-bottom: none !important;

      &:hover {
        border-bottom-color: transparent !important;

        @include mq($until: desktop) {
          border-top-color: transparent !important;
        }
      }
    }

    &__link--group-level-1 {
      position: relative;
      padding: 0 32px 0 16px;

      &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 47%;
        width: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #000;
        clear: both;
      }
    }
  }
}
