@import '../../../../../../styles/shared';

.c-search-content-media-site {
  width: 18%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  text-decoration: none;
  margin-right: 25px;
  color: $tenant-search-text-color;
  font-size: 16px;
  line-height: 20px;

  &__default {
    background-color: #f5f5f5;
    height: 24px;
  }

  @include mq($until: $extended-layout-width) {
    width: 30%;
  }

  @include mq($until: tablet) {
    min-width: 227px;
  }

  &__preview-mode--more-four {
    margin: 0;

    @include mq($until: $extended-layout-width) {
      margin: 0 20px 20px 0;
      &:nth-child(4) {
        margin: 0 20px 0 0;
      }
    }

    @include mq($until: tablet) {
      margin: 0 20px 0 0;
    }
  }

  &__preview-mode--less-four {
    margin: 0 20px 0 0;

    @include mq($until: $extended-layout-width) {
      margin: 0 20px 20px 0;
      &:nth-child(4) {
        margin: 0 20px 0 0;
      }
    }

    @include mq($until: tablet) {
      margin: 0 20px 0 0;
    }
  }

  &__view-mode--more-four {
    margin: 0 20px 40px 0;
    @include mq($until: $extended-layout-width) {
      margin: 0 20px 20px 0;
    }

    @include mq($until: tablet) {
      margin: 0 0 20px 0;
      width: 100%;
    }
  }

  &__view-mode--less-four {
    margin: 0 20px 0 0;

    @include mq($until: tablet) {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }

  &__image {
    height: auto;
    width: 100%;
    margin-bottom: 16px;
  }
}
