@import '../../../../../styles/shared';

.c-search-content-view-page {
  padding: 0 20px 60px 20px;
  background: linear-gradient(180deg, #e7e7e7 0%, rgba(255, 255, 255, 0) 100%);

  @include mq($until: tablet) {
    padding: 0 0 20px 0;
  }

  &__body {
    display: flex;

    @include mq($until: $extended-layout-width) {
      flex-direction: column;
    }
  }

  &__body-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 25px;
  }

  &__body-wrapper-mobile {
    display: flex;
  }

  &__preview-mode {
    .c-search-content-view-page__body-wrapper {
      width: 80%;

      @include mq($until: $extended-layout-width) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
      }
    }

    .c-search-content-view-page__body-wrapper-mobile {
      @include mq($until: tablet) {
        flex-direction: row;
        overflow-x: auto;
        padding: 0 10px 20px 10px;
      }
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__view-mode {
    .c-search-content-view-page__body-wrapper {
      width: 100%;
      padding-right: 0;

      @include mq($until: tablet) {
        flex-direction: column;
        padding: 0 10px 20px 10px;
      }
    }

    @include mq($until: tablet) {
      display: block;
    }
  }
}
