@import '../../../../../styles/shared';

.c-product-item {
  .c-payment-form-block {
    &__body {
      box-shadow: none;
    }
  }

  &:last-child {
    .c-payment-form-block {
      border-top: 1px solid #dcdcdc;

      &__body {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  &:first-child {
    .c-payment-form-block {
      border-top: none;

      &__body {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &:not(:first-child):not(:last-child) {
    .c-payment-form-block {
      border-top: 1px solid #dcdcdc;

      &__body {
        border-radius: 0;
      }
    }
  }
}

.c-product-item {
  &.disabled {
    opacity: 0.5;
  }

  &__content {
    min-height: 55px;

    &.has-description {
      min-height: 50px;
    }

    &.has-vat-rate {
      min-height: 75px;
    }

    &.has-description.has-vat-rate {
      min-height: 70px;
    }

    &.none-item {
      min-height: auto !important;
    }

    @include mq($until: tablet) {
      min-height: auto !important;
    }
  }

  &__label {
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
  }

  &__description {
    font-size: 14px;
    font-weight: 300;
    margin-top: 3px;
    margin-bottom: 5px;

    p {
      margin: 0;
    }
  }

  &__more-info {
    display: block;
    margin-top: 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.4;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__price {
    font-size: 25px;
    line-height: 27px;
    font-weight: 300;
    @include text-align(right);

    @include mq($until: tablet) {
      @include text-align(left);
      margin-top: 10px;
    }
  }

  &__tiered-saving {
    font-size: 14px;
    font-weight: 300;
    color: #333;
    @include margin-left(40px);
    padding-top: 20px;
    margin-top: 10px;
    background: linear-gradient(
        to right,
        #dee0e5 55%,
        rgba(255, 255, 255, 0) 0%
      )
      top left repeat-x;
    background-size: 18px 1px;

    b {
      font-weight: bold;
    }

    @include mq($until: tablet) {
      @include margin-left(0);
    }

    &--full {
      @include margin-left(0);
    }
  }

  &__option-list {
    margin-top: 10px;
    @include padding-left(40px);

    &--full {
      @include padding-left(0);
    }

    @include mq($until: tablet) {
      @include padding-left(0);
    }
  }

  &__option-flag {
    position: absolute;
    bottom: 12px;
    @include right(1px);
    font-weight: 900;
    font-size: 12px;
    @include text-align(right);
    color: #aaaaaa;

    @include mq($until: tablet) {
      position: initial;
      @include text-align(left);
      margin-top: 10px;
    }

    &.relative {
      position: relative;
      bottom: 0;
      margin-top: 5px;
    }

    &.mt-10 {
      margin-top: 10px;
    }

    .icon {
      display: inline-block;
      @include margin-left(7px);
    }
  }
}
