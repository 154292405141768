@import '../../../../styles/shared';

.c-article-main-event {
  position: relative;
  display: flex;
  flex-direction: column;

  .container {
    max-width: $extended-layout-width;
  }

  &__background,
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;
  }

  &__overlay {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.05) 100%
    );
    opacity: 1;
    z-index: 2;
    transition: opacity 0.3s linear;
  }

  &__link:hover &__overlay {
    opacity: 0.8;
  }

  &__info {
    position: relative;
    z-index: 3;
  }

  &__link {
    position: relative;
    flex-direction: column;
    min-height: 250px;
    padding: 35px 0 110px;
    text-decoration: none;
    color: #fff;

    @include mq($until: tablet) {
      padding: 25px 0 30px;
    }
  }

  &__badge {
    position: absolute;
    top: -35px;
    padding: 4px 10px 6px;
    border-radius: 0 0 5px 5px;
    background-color: #4735cd;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    text-transform: uppercase;
    color: #fff;

    @include mq($until: tablet) {
      top: -25px;
    }
  }

  &__title {
    margin: 75px 0 20px;
    font-family: $font-family-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 46px;
    color: #fff;

    @include mq($until: tablet) {
      font-size: 22px;
      margin-top: 40px;
    }
  }

  &__date-and-city {
    margin-bottom: 20px;
    font-family: $font-family-sans-serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
  }

  &__value-proposition {
    font-family: $font-family-sans-serif;
    font-size: 22px;
    line-height: 22px;
    color: #fff;
  }

  &__fake-button {
    display: inline-block;
    margin-top: 40px;
    padding: 9px 35px 7px;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    border-radius: 20px;
    background: $tenant-accent-color;
    color: #fff;
  }
}
