@import '../../../../styles/shared';

.c-payment-journey-file-field {
  padding: 5px 0 10px;

  &__nav {
    display: flex;
  }

  &__upload-btn {
    display: inline-block;
    @include margin(0, 10px, 0, 0);
    font-size: 13px;
    line-height: 18px;
    color: #000;
    text-decoration: underline;
    cursor: pointer;

    span {
      position: relative;
      top: -1px;
      @include margin-right(3px);
      vertical-align: middle;
      font-size: 16px;
    }
  }

  input[type='file']:disabled + &__nav &__upload-btn {
    color: #667;
    cursor: not-allowed;
  }

  &__placeholder {
    font-size: 13px;
    line-height: 18px;
    color: #667;
  }

  &__files {
    margin-top: 12px;

    li {
      margin-top: 8px;
      font-size: 13px;
      line-height: 18px;
      color: #000;

      span {
        position: relative;
        top: -2px;
        @include margin(0, 3px, 0, 0);
        vertical-align: middle;
        font-size: 20px;

        &:last-child {
          @include margin(0, 0, 0, 3px);
          top: -1px;
          font-size: 16px;
          cursor: pointer;
        }
      }

      small {
        font-size: 12px;
        color: #667;
      }
    }
  }

  input[type='file'] {
    display: none;
  }
}
