@import '../../../../styles/shared';

.c-co-lo-agenda-day-selector {
  &__item {
    display: inline-block;
    @include margin-right(15px);
    line-height: 30px;
    cursor: pointer;
  }

  &__link {
    padding: 4px 10px;
    border: 1px solid transparent;
    color: #fff;
    border-bottom-color: #fff;
    white-space: nowrap;
    text-decoration: none;
    font-size: 16px;
  }
}
// scss styles for mobile view
.c-co-lo-agenda-day-selector-mobile {
  @include mq($until: tablet) {
    margin-top: 60px;

    .c-co-lo-agenda-day-selector {
      &__list {
        display: flex;
        flex-direction: column;
      }

      &__item {
        background-color: $body-bg;
        border-bottom: 1px solid #dcdcdc;
        margin: 0;
      }

      &__link {
        color: rgb(51, 51, 51);
        border: none;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        line-height: 22px;

        time {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
