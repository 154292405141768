@import '../../../../../styles/shared';

$angle: 36deg;
$rtlAngle: 144deg;

.c-chevron-icon {
  position: relative;
  display: inline-block;
  width: 22.5px;
  height: 10.5px;
  padding-top: 3px;
  vertical-align: middle;

  &--top {
    padding-top: 4px;
    transform: rotateZ(180deg);
  }

  &--right {
    transform: rotateZ(-90deg);
  }

  &--bottom {
    transform: rotateZ(0deg);
  }

  &--left {
    transform: rotateZ(90deg);
  }

  &__line {
    position: relative;
    display: block;
    width: 13px;
    height: 3px;
    background-color: #fff;

    &:first-child {
      [dir='ltr'] & {
        transform: rotateZ($angle);
      }

      [dir='rtl'] & {
        transform: rotateZ($rtlAngle);
      }
    }

    &:last-child {
      @include left(9px);
      top: -3px;
      [dir='ltr'] & {
        transform: rotateZ(-$angle);
      }

      [dir='rtl'] & {
        transform: rotateZ(-$rtlAngle);
      }
    }
  }
}
