@import '../../../styles/shared';

.c-order-receipt-distance-learning {
  margin-bottom: $gutter-width;
  padding: 30px 15px 15px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;

  &__title {
    max-width: 640px;
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 52px;
    line-height: 1;

    @include mq($until: tablet) {
      margin-bottom: 20px;
      font-size: 36px;
    }

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: 'Aleo', serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  &__contact-list {
    margin-top: 15px;
  }

  &__helper-text {
    color: #667;
  }

  p + p,
  ul + p {
    margin-top: 15px;
  }
}
