@import '../../../styles/shared';

.c-popup-box {
  position: fixed;
  bottom: -100%;
  padding: 20px 0 44px 0;
  width: 100%;
  min-height: 40px;
  z-index: 9999;
  transition: all 0.6s;
  box-sizing: content-box;
  background: $tenant-primary-color;

  &.active {
    bottom: 0;
    transition: all 0.6s;
  }

  &.closed {
    bottom: -100%;
    transition: all 0.6s;
  }

  .container {
    &.extended {
      max-width: $extended-layout-width;
    }
  }

  &__display-control {
    position: absolute;
    bottom: 0;
    right: 10px;

    &-button {
      display: inline-block;
      color: white;
      text-decoration: none;
      padding: 0 22px;
      height: 40px;
      text-align: center;
      border-radius: 20px;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      font-family: $font-family-serif;
      vertical-align: top;
      background: #00172f;
      border: 1px solid #00172f;

      &--close {
        line-height: 40px;
        padding: 0 38px 0 22px;

        .down-arrow {
          position: absolute;
          top: 8px;
          right: 12px;
        }
      }

      &--reopen {
        background: $tenant-accent-color;
        display: none;
      }
    }
  }

  .c-lead-gen-popup-subscribe-form {
    width: 100%;
    position: relative;

    .container {
      max-width: $extended-layout-width;
    }
  }

  &__status {
    margin-top: 20px;
    color: #fff;
  }
}

.c-popup-box.minimised {
  display: none;

  .c-popup-box__display-control {
    position: absolute;
    width: calc(100% - 62px);
    right: 10px;
    top: 0;
    float: right;

    &-button {
      padding: 7px;
      font-size: 16px;
      line-height: 16px;
      height: 30px;
      width: 30px;
      float: left;

      &--reopen {
        display: block;
        width: auto;
        padding: 7px 25px;
        margin-right: 14px;
      }

      &--close {
        float: right;
        padding: 4px;

        i {
          font-size: 21px;
        }
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .c-lead-gen-popup-subscribe-form {
    display: none;
    padding-top: 0;
  }

  .c-popup-box__status {
    display: none;
  }
}

@include mq($from: mobile) {
  .c-popup-box.minimised {
    padding: 13px 0;
    min-height: 0;
    display: block;

    .c-lead-gen-popup-subscribe-form {
      display: inline-block;
    }
  }
}

@include mq($from: tablet) {
  .c-popup-box {
    .c-popup-box__display-control {
      position: absolute;
      top: 0;
      bottom: initial;
    }

    .c-lead-gen-popup-subscribe-form {
      display: flex;
      padding-top: 60px;
    }
  }

  .c-popup-box.minimised {
    .c-popup-box__display-control {
      position: absolute;
      width: auto;
      top: 8px;
      right: 10px;
    }

    .c-lead-gen-popup-subscribe-form {
      display: flex;
    }
  }
}

@include mq($from: extended-layout) {
  .c-popup-box.minimised {
    .c-popup-box__display-control {
      top: 6px;
    }
  }
}

@include mq($from: $ultra-wide-breakpoint) {
  .c-popup-box {
    .container.extended {
      max-width: 1730px;
    }
  }

  .c-popup-box.minimised {
    .c-popup-box__description {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding-right: 20px;
      padding-left: 10px;
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
    }

    .c-popup-box__display-control {
      &-button--reopen {
        display: none;
      }
    }

    .c-popup-box__status {
      display: block;
      text-align: right;
      padding-right: 20px;
      margin-top: 0;

      p {
        font-size: 16px;
      }
    }
  }
}
