@import '../../../../styles/shared';

.lead-gen-teaser-portrait {
  &__title {
    display: flex;

    &-text {
      padding-bottom: 0;
      font-size: 40px;
      line-height: initial;
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      font-weight: 800;
      text-transform: uppercase;
      color: #333;
      align-self: center;
    }
  }

  &__underline {
    height: 2px;
    margin: 14px 0 22px;
  }

  &__image {
    width: 100%;
    height: auto;
    margin: 0;

    &-wrapper {
      position: relative;
      line-height: 0;
      margin-bottom: 60px;
    }
  }

  &__content-wrapper--with-img {
    @include mq($from: tablet) {
      //@include padding-left(20px);
      padding-left: 20px;
    }
  }

  &__body {
    color: #000;

    p {
      line-height: 26px;

      &:last-of-type {
        margin-bottom: 30px;

        @media only screen and (min-width: $tablet-breakpoint) {
          margin-bottom: 60px;
        }
      }
    }
  }
}
