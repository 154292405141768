@import '../../../../styles/shared';

.lead-gen-checkbox-ad-hoc {
  margin: 10px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
  }

  &__title {
    color: #333;
    font-size: 16px;
    line-height: 24px;
  }

  &__error {
    color: #c9474d;
    font-size: 12px;
  }

  &__group {
    display: flex;
    flex-wrap: wrap;
    @include mq($until: tablet) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  &__item {
    width: 50%;
    @include mq($until: tablet) {
      width: 100%;
    }
  }
}
