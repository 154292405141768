@import '../../../../../styles/shared';

.c-terms-and-conditions {
  padding: 60px 0;
  color: #000;
  direction: ltr;

  @include mq($until: tablet) {
    padding: 40px 0;
  }

  h1 {
    font-weight: 700;
    font-size: 52px;
    margin: 0 0 40px 0;
    line-height: 1;

    @include mq($until: tablet) {
      font-size: 36px;
      margin-bottom: 20px;
    }

    @include one-of-themes-except([$DEFAULT, $FANEXPOHQ]) {
      font-family: 'Barlow Condensed Extra Bold', sans-serif;
      text-transform: uppercase;
    }

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }

    @include theme-only($FANEXPOHQ) {
      font-family: 'Proxima Nova ExCn Bl', sans-serif;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    //@include padding-left(30px);
    padding-left: 30px;

    &:first-of-type {
      //@include padding-left(0);
      padding-left: 0;
    }
  }

  @include mq(mobile) {
    p {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include mq(tablet) {
    p {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .statement {
    font-style: italic;
    font-weight: bold;
  }

  ol {
    //@include padding-left(30px);
    padding-left: 30px;
    counter-reset: item;
    list-style-type: none;

    @include mq($until: tablet) {
      //@include padding-left(0);
      padding-left: 0;
    }

    & > li {
      margin-top: 1.5em;
      margin-bottom: 1.5em;

      &::before {
        content: counters(item, '.') '.';
        counter-increment: item;
        padding-right: 3px;
        font-weight: bold;
      }

      ol li::before {
        content: counters(item, '.');
        font-weight: normal;
      }
    }

    .type-letter-list {
      counter-reset: listStyle;

      li::before {
        content: '(' counter(listStyle, lower-alpha) ')';
        counter-increment: listStyle;
        //@include padding-right(6px);
        padding-right: 6px;
      }
    }
  }

  @include mq(tablet) {
    ol {
      //@include padding-right(10px);
      padding-right: 10px;
    }
  }

  em {
    font-style: italic;
  }
}
