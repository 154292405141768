@import '../../../../../styles/shared';

.c-co-lo-agenda-checkbox-header {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;

  @include mq($until: tablet) {
    margin: 15px 20px;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
  }

  &__clear {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
}
