@import '../../../../styles/shared';

$speaker-border-color: rgb(239, 239, 239);

.session-speakers {
  @include margin(40px, 20px, 20px, 20px);

  @include mq($until: tablet) {
    margin: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &-link {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: flex-start;
      min-height: 132px;
      padding: 26px;
      margin-bottom: $gutter-width;
      border: 1px solid $speaker-border-color;
      color: #000;
      text-decoration: none;

      @include theme-only($DEFAULT) {
        border-radius: 16px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &-role {
      display: inline-block;
      margin: 0 0 0 20px;
      padding: 1px 15px;
      font-size: 14px;
      text-transform: uppercase;
      color: #fff;
      border-radius: 10px 10px 0 0;

      @include theme-only($ASIATECHXSG) {
        color: #000 !important;
      }

      &:empty {
        background: none !important;
      }
    }

    &-photo {
      min-width: 80px;
      height: 80px;
      border-radius: 50%;
      background: no-repeat center center #fff;
      background-size: cover;
    }

    &-text {
      @include margin-left(20px);
      line-height: 18px;
    }

    &-name {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
    }

    &-job {
      min-height: 36px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 300;
    }

    &-company {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
