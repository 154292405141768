@import '../../../../../styles/shared';

$spinner-height: 60px;

.c-search-content-spinner {
  display: inline-block;
  position: absolute;
  right: 50%;
  top: 50%;
  width: $spinner-height;
  height: $spinner-height;
  margin-top: -$spinner-height * 0.5;
  margin-right: -$spinner-height * 0.5;

  &--loading {
    animation: rotation 2s infinite linear;
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
