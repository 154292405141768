@import '../../../../styles/shared';

$navigation-height: 45px;
$border-color: #e5e5e5;

.c-navigation-dropdown {
  position: relative;
  line-height: $navigation-height;
  user-select: none;
  cursor: pointer;

  &__list {
    top: 0;
    width: 150px;
    display: none;
    line-height: 20px;
    position: absolute;
    margin-top: $navigation-height;
    background: #fff;
    border-radius: 0 0 3px 3px;
    border: 1px solid $border-color;
    overflow: hidden;
    z-index: 1000;

    &__item {
      font-size: 14px;
      text-align: left;
      padding: 8px 10px;
      width: auto;

      &:hover {
        background-color: #f7f7f7;
      }
    }
  }

  &.opened &__list {
    display: block;
  }

  &.opened &__icon {
    transform: rotate(180deg);
  }
}
