.l-audience {
  &-sticky {
    position: relative;
    z-index: 100;
  }

  &__menu {
    position: relative;
    z-index: 10;
  }

  &__content {
    background-color: #ffffff;
  }
}
