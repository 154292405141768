@import '../../../styles/shared';

.success-message {
  position: relative;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: all 500ms ease 0s;
  color: #333;
  font-weight: 300;
  font-size: 24px;
  border-bottom: 4px solid $form-border-color;
  margin-bottom: 0;

  &.visible {
    height: 60px;
    opacity: 1;
    margin-bottom: 30px;
    transition-duration: 0s;
  }
}
