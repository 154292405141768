@import '../../../../../styles/shared';

.c-multi-level-nav {
  &--blurred {
    background-color: $multi-level-nav-base-bg-color;

    [class$='--fixed-state'] {
      opacity: 0.25;
    }
  }

  &--top-nav {
    position: relative;
    z-index: 100;
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-multi-level-nav {
    background-color: $multi-level-nav-level-2-bg-color !important;

    &--blurred {
      background-color: $multi-level-nav-base-bg-color;

      [class$='--fixed-state'] {
        opacity: 0.25;
      }
    }
  }
}

@include one-of-themes(
  [$ONEOFAKIND,
  $NAJAHSTACK,
  $ARTTORONTO,
  $THEARTISTPROJECT,
  $INTERIORDESIGN]
) {
  .c-multi-level-nav {
    &--blurred {
      [class$='--fixed-state'] {
        opacity: 1;
      }
    }
  }
}
