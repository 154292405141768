@import '../../../styles/shared';

.c-forgot-password-form {
  margin: 40px 0;

  .c-form-field {
    &:-ms-input-placeholder {
      color: #cccccc !important;
    }
  }
}

@include theme-only($DEFAULT) {
  .c-forgot-password-form {
    border: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
    background-color: #ffffff;

    .c-form-block {
      border: 0;
      border-radius: 0;
      background-color: transparent;

      &__header {
        border: 0;
        padding: 25px 40px 0 40px;

        .c-form-block__panel {
          display: none;
        }
      }

      &__heading {
        color: #022244;
        font-family: $font-family-serif;
        font-size: 32px;
        line-height: 48px;
      }

      &__body {
        padding: 0 40px 40px 40px;

        .c-form {
          > .row {
            margin-bottom: 0;
          }

          .c-input-label {
            color: #333333;
            font-size: 16px;
            line-height: 24px;
          }

          p {
            font-size: 18px;
            line-height: 26px;
            color: #333333;
          }

          .c-form-field {
            font-weight: normal;
            color: rgb(51, 51, 51);
            font-family: 'Open sans', sans-serif;

            &.error {
              @include padding-left(10px);
            }
          }

          .ic-submit-button {
            outline: none;
            border: none;
            display: block;
            background: #cc31c2;
            color: white;
            width: 200px;
            height: 40px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            line-height: 22px;
            font-family: $font-family-serif;
            text-decoration: none;
            border-radius: 20px;
            padding: 10px;
            &:disabled {
              background-color: #ccc;
              color: #fff;
              box-shadow: none;
              transition: none;
              cursor: default;
            }
          }
        }
      }
    }
    .c-button__loader {
      width: 14px;
      height: 14px;

      & div {
        width: 14px;
        height: 14px;
        border-width: 3px;
      }
    }
  }

  @media screen and (max-width: $tablet-breakpoint) {
    .c-forgot-password-form {
      .c-form-block {
        &__body {
          .c-form {
            > .row {
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}
