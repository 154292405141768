@import '../../../../../styles/shared';

@mixin item-label($backgroundColor) {
  position: relative;
  top: -1px;
  @include margin-left(5px);
  @include padding(2px, 5px, 1px, 5px);
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
  color: #fff;
  background-color: $backgroundColor;
  border-radius: 2px;
  text-transform: uppercase;

  @include theme-only($DEFAULT) {
    text-transform: none;
  }
}

.c-agenda-list-view {
  .agenda-sessions {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    color: #333;
    cursor: pointer;
    border-style: solid;

    &.expanded_list {
      @include padding(20px, 20px, 20px, 18px);
    }

    &:not(.stream-border-color) {
      border-color: #999999;
    }

    &:hover {
      background: #f7f7f7;
    }

    &.session_break {
      background: #e5e5e5;

      &:hover {
        background: #dcdcdc;
      }
    }
  }

  &.sort-by-stream {
    .agenda-sessions {
      border-top-width: 3px;

      .stream {
        display: none;
      }
    }
  }
  &.sort-by-time {
    .agenda-sessions {
      [dir='ltr'] & {
        border-left-width: 2px;
      }

      [dir='rtl'] & {
        border-right-width: 2px;
      }

      margin-bottom: 5px;

      &.expanded_list:hover {
        @include padding(20px, 20px, 20px, 17px);
        [dir='ltr'] & {
          border-left-width: 3px;
        }

        [dir='rtl'] & {
          border-right-width: 3px;
        }
      }
    }
  }

  .agenda-group {
    margin-bottom: 10px;

    .agenda-sessions {
      border-top-width: 0;
      margin-bottom: 0;
      margin-top: 5px;
    }

    .agenda-stream-name + .agenda-sessions {
      margin-top: 0;
    }
  }
  .time {
    font-size: 13px;
    font-weight: 400;
  }
  .duration {
    font-size: 13px;
    font-weight: 300;
    @include padding-left(5px);
  }
  .agenda-item-free-label {
    @include item-label(#15cb8e);
  }
  .learning-agenda-item-format-label {
    @include item-label(initial);
    @include float(right);
  }
  .stream {
    font-size: 14px;
    font-weight: 300;
  }
  .title {
    padding-top: 6px;
    font-size: 16px;
    font-weight: 400;

    @include theme-only($DEFAULT) {
      @include event-only() {
        font-family: $font-family-serif;
      }

      @include course-only() {
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
  .description {
    font-size: 14px;
    color: #333;
    font-weight: 300;
    padding-top: 6px;

    strong {
      color: #626262;
    }
  }
  .agenda-item-speakers {
    padding-top: 6px;
    margin: 0;
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    display: block;
  }
}
