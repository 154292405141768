@import '../../../../../styles/shared';

.c-lead-gen-consent-first-statements {
  &__text {
    color: #717171;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;

    b {
      font-weight: bold;
    }
  }

  &__checkbox-title {
    color: $form-helper-color;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  &__checkbox-wrapper {
    display: flex;
    @include mq($until: tablet) {
      flex-wrap: wrap;
    }
  }

  &__checkbox {
    display: flex;
    //@include margin-right(18px);
    margin-right: 18px;
  }
}
