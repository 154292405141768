.c-co-lo-agenda-list {
  padding: 0 20px 20px;

  &--search-mode {
    padding: 20px;
  }

  &__session-modal {
    text-decoration: none;
  }
}
