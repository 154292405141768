@import '../../../../styles/shared';

.c-payment-journey-optional-radio-group {
  padding: 5px 0;

  .c-payment-journey-check-input {
    display: inline-block;
    @include margin-right(15px);
    margin-bottom: 5px;
  }

  &__free-text {
    margin: 5px -5px -5px;
    border-top: 1px solid #000;
  }
}
