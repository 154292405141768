@import '../../../styles/shared';

.c-hub-not-found-section {
  display: flex;
  align-items: center;
  flex: 1 0;
  padding: 60px 0;
  font-family: $font-family-base;
  text-align: center;
  background-color: #fff;

  &__title {
    font-size: 80px;
    font-weight: 700;
    color: $tenant-primary-color;
  }

  &__subtitle {
    margin-bottom: 10px;
    font-size: 32px;
    color: $tenant-primary-color;
  }

  &__text {
    font-size: 16px;
    color: #56595b;
  }
}

@include theme-only($KNECT365) {
  .c-hub-not-found-section {
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
    background: #efefef url(../../../assets/images/knect365/background-500.png);

    &__title {
      font-weight: 400;
      color: $tenant-accent-color;
    }

    &__text {
      color: #56595b;
    }
  }
}
