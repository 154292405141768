@import '../../../../../../styles/shared';

.c-multi-level-nav-mobile {
  position: absolute;
  top: $multi-level-nav-level-1-height-mobile;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow: auto;
  color: $multi-level-nav-level-1-text-color;

  @include mq($from: wide) {
    top: $multi-level-nav-level-1-height;
  }

  &::before {
    @include overlay;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__body {
    height: 100%;
    overflow-y: auto;
    padding: 10px 20px;

    @include mq($from: tablet) {
      padding: 40px 60px;
    }
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-multi-level-nav-mobile {
    min-height: initial !important;
    bottom: initial;
    background-color: $multi-level-nav-level-2-bg-color;

    &::before {
      display: none;
    }

    @include mq($from: tablet) {
      left: initial;
      width: 250px;
    }

    &__body {
      height: auto;

      @include mq($from: tablet) {
        padding: 10px 20px;
      }
    }
  }
}

@include one-of-themes(
  [$ONEOFAKIND,
  $NAJAHSTACK,
  $ARTTORONTO,
  $THEARTISTPROJECT,
  $INTERIORDESIGN]
) {
  .c-multi-level-nav-mobile {
    &::before {
      @include overlay;
      background-color: rgba(#fff, 0.3);
    }
  }
}
