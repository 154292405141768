@import '../../../../styles/shared';

.c-tooltip-mobile {
  display: none;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  width: calc(100% - 20px);
  position: fixed;
  bottom: 10px;
  padding: 10px;
  background-color: #fff;
  @include margin-left(10px);
  @include mq($until: tablet) {
    display: flex;
  }

  &__text {
    font-size: 14px;
    @include margin-right(10px);
  }
}
