@import '../../../../styles/shared';

.discount-list {
  color: #aaaaaa;

  &__paragraph {
    margin: 0 0 8px;

    &:last-child:not(&--small-margin) {
      margin-bottom: 16px;
    }

    &--small-margin {
      margin-bottom: 2px;
    }
  }
}
