@import '../../../../styles/shared';

.lead-gen-checkbox {
  display: flex;

  &__input {
    display: none;
  }

  &__label {
    min-height: 39px;
    display: flex;
    cursor: pointer;

    &-square-wrapper {
      //@include margin-right(16px);
      margin-right: 16px;

      &--small {
        //@include margin-right(10px);
        margin-right: 10px;
      }
    }

    &-square {
      height: 20px;
      width: 20px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid #dee0e5;
      position: relative;

      &-tick {
        content: '';
        position: absolute;
        width: 12px;
        height: 8px;
        //@include left(3px);
        left: 3px;
        top: 3px;
        transform: rotate(-45deg);
        //[dir='ltr'] & {
        //  transform: rotate(-45deg);
        //}
        //
        //[dir='rtl'] & {
        //  transform: rotate(45deg) scaleX(-1);
        //}
        //@include border-left(2px solid #fff);
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }

      &--active {
        background-color: #a1a1a1;
        border: 1px solid #a1a1a1;
      }

      &--error {
        border: 1px solid #c9474d;
      }
    }

    &-text-wrapper {
      display: flex;
    }

    &-text {
      color: #333;
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      padding-bottom: 10px;

      &--small {
        font-size: 14px;
        color: $form-helper-color;
        font-weight: 400;
      }
    }

    &-link {
      //@include margin-left(5px);
      margin-left: 5px;
    }
  }
}
