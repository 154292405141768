@import '../../../../../../styles/shared';

.c-site-lower-footer-menu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  @include mq($until: desktop) {
    flex-direction: column;
    text-align: right;
  }

  @include mq($from: tablet, $until: desktop) {
    padding: 16px 0 24px;
  }

  @include mq($until: tablet) {
    @include text-align(left);
  }

  &__item {
    @include margin-left(13px);
    line-height: 1.6;

    @include mq($until: desktop) {
      margin: 10px 0 0;
      line-height: initial;
    }
  }

  &__link {
    font-weight: 700;
    text-decoration: none;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include one-of-themes(
  [$FANEXPOHQ,
  $ONEOFAKIND,
  $NAJAHSTACK,
  $INTERIORDESIGN,
  $ARTTORONTO,
  $THEARTISTPROJECT,
  $MEFCC]
) {
  .c-site-lower-footer-menu {
    @include mq($until: desktop) {
      flex-direction: initial;
      text-align: initial;
    }

    @include mq($from: tablet, $until: desktop) {
      padding: 0;
    }

    @include mq($until: desktop) {
      justify-content: flex-start;
      margin-bottom: 8px;
    }

    &__item {
      margin-left: 20px;
      @include mq($until: desktop) {
        margin: 0 20px 0 0;
      }
    }

    &__link {
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      text-decoration: none;
      font-weight: normal;
    }
  }
}
