@import '../../../../styles/shared';

.c-form {
  .form-row {
    position: relative;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &.password-row {
      margin-bottom: 6px;
    }
  }

  & > .row {
    margin-bottom: 20px;

    & > .col-xs-12 + .col-xs-12 {
      @include mq($until: tablet) {
        margin-top: 20px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: #68356e;

    &:hover {
      text-decoration: underline;
    }
  }
}
