@import '../../../styles/shared';

.lead-gen-badge {
  display: inline-flex;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  background: #fff;

  &__text {
    padding-top: 1px;
    margin: 0 20px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: none;
    color: $tenant-accent-color;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    @include padding-left(1px);
    margin: 4px;
    @include margin-left(-9px);
    font-size: 16px;
    border-radius: 50%;
    background: $tenant-accent-color;
    color: #fff;
  }

  &--with-border {
    box-sizing: border-box;
    height: 26px;
    border-radius: 18px;
    border: 2px solid $tenant-accent-color;

    .lead-gen-badge {
      &__text {
        margin: 0 9px;
        font-size: 12px;
        opacity: 0.8;
      }

      &__icon {
        width: 18px;
        height: 18px;
        @include margin-left(-3px);
        @include margin-right(3px);
        font-size: 14px;
      }
    }
  }
}
