@import '../../../styles/shared';

.c-language-widget {
  @include margin-left(15px);

  @include mq($until: tablet) {
    @include margin-left(0);
  }

  &__list {
    &-item {
      display: inline-block;
      border: 1px solid #dee0e5;

      &.selected {
        pointer-events: none;
      }

      &.selected,
      &:hover {
        background: #efefef;
      }

      &:first-child {
        border-radius: 3px 0 0 3px;
      }
      &:last-child {
        border-radius: 0 3px 3px 0;
      }

      &:not(:first-child) {
        @include margin-left(-1px);
      }

      &-link {
        display: block;
        line-height: 1;
        padding: 3px 12px 5px;
        text-decoration: none;
        color: #002144;

        @include mq($until: tablet) {
          padding: 5px;
        }
      }

      .label {
        font-size: 12px;
        vertical-align: middle;
      }
    }
  }
}

@include one-of-themes([$FANEXPOHQ, $MEFCC]) {
  .c-language-widget {
    margin-bottom: 15px;
    margin-top: 15px;

    &__list-item {
      &.selected,
      &:hover {
        background-color: rgba(255, 255, 255, 0.7);
        .c-language-widget__list-item-link {
          color: #002144;
        }
      }

      &-link {
        color: #fff;
      }
    }
  }
}
