//TODO: move this variables to global theme level after branding updates
:root {
  /**
   * Payment error colors
   */
  --payment-palette-custom-error-light: #fecdca;
  --payment-palette-custom-error-light-rgb: 254, 205, 202;
  --payment-palette-custom-error-main-on-light: #c8444f;
  --payment-palette-custom-error-main-on-light-rgb: 200, 68, 79;
  --payment-palette-custom-error-main-on-dark: #cd555d;
  --payment-palette-custom-error-main-on-dark-rgb: 205, 85, 93;
  --payment-palette-custom-error-dark: #a02d35;
  --payment-palette-custom-error-dark-rgb: 160, 45, 53;

  /**
   * Payment warning colors
   */
  --payment-palette-custom-warning-light: #fbf2e4;
  --payment-palette-custom-warning-light-rgb: 251, 242, 228;
  --payment-palette-custom-warning-main: #c67700;
  --payment-palette-custom-warning-main-rgb: 198, 119, 0;
  --payment-palette-custom-warning-dark: #885200;
  --payment-palette-custom-warning-dark-rgb: 136, 82, 0;

  /**
   * Payment success colors
   */
  --payment-palette-custom-success-light: #e6f5ed;
  --payment-palette-custom-success-light-rgb: 230, 245, 237;
  --payment-palette-custom-success-main: #68ae60;
  --payment-palette-custom-success-main-rgb: 104, 174, 96;
  --payment-palette-custom-success-dark: #006529;
  --payment-palette-custom-success-dark-rgb: 0, 101, 41;

  /**
   * Payment info colors
   */
  --payment-palette-custom-info-light: #ecf2fa;
  --payment-palette-custom-info-light-rgb: 236, 242, 250;
  --payment-palette-custom-info-main: #397fe9;
  --payment-palette-custom-info-main-rgb: 57, 127, 233;
  --payment-palette-custom-info-dark: #024ab7;
  --payment-palette-custom-info-dark-rgb: 2, 74, 183;

  /**
   * Payment neutral colors
   */
  --payment-palette-neutral-0: #ffffff;
  --payment-palette-neutral-0-rgb: 255, 255, 255;
  --payment-palette-neutral-50: #f7f7f7;
  --payment-palette-neutral-50-rgb: 247, 247, 247;
  --payment-palette-neutral-100: #efefef;
  --payment-palette-neutral-100-rgb: 239, 239, 239;
  --payment-palette-neutral-200: #dee0e4;
  --payment-palette-neutral-200-rgb: 222, 224, 228;
  --payment-palette-neutral-400: #b7bcc3;
  --payment-palette-neutral-400-rgb: 183, 188, 195;
  --payment-palette-neutral-600: #636f82;
  --payment-palette-neutral-600-rgb: 99, 111, 130;
  --payment-palette-neutral-700: #56585a;
  --payment-palette-neutral-700-rgb: 86, 88, 90;
  --payment-palette-neutral-800: #333333;
  --payment-palette-neutral-800-rgb: 51, 51, 51;
  --payment-palette-neutral-900: #000000;
  --payment-palette-neutral-900-rgb: 0, 0, 0;
}
