@import '../../../styles/shared';

$logo-shadow-color: rgba(0, 0, 0, 0.2);

.c-person-header-section {
  position: relative;
  min-height: 200px;
  background: #fff;

  @include theme-only($ASIATECHXSG) {
    color: $body-color;
  }

  @include mq($until: tablet) {
    text-align: center;
  }

  &__top-line {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100px;
    background: #efefef;
  }

  &__photo {
    border-radius: 50%;
    background: no-repeat center center #fff;
    background-size: cover;

    @include mq($from: tablet) {
      position: absolute;
      top: 35px;
      @include left(20px);
      width: 130px;
      height: 130px;
    }

    @include mq($until: tablet) {
      width: 180px;
      height: 180px;
      @include margin(40px, auto, 20px, auto);
    }
  }

  &__logo {
    padding: 5px;
    background: no-repeat center center #fff;
    background-size: contain;
    background-origin: content-box;
    box-shadow: 0 0 5px $logo-shadow-color;

    @include mq($from: tablet) {
      position: absolute;
      top: 35px;
      @include left(20px);
      width: 235px;
      height: 130px;
    }

    @include mq($until: tablet) {
      width: 180px;
      height: 100px;
      @include margin(40px, auto, 20px, auto);
    }
  }

  &__name.c-event-section__heading {
    padding: 48px 0 12px;
    @include margin(0, 0, 0, 180px);

    @include theme-only($ASIATECHXSG) {
      font-family: 'Noto Sans', sans-serif;
      font-size: 38px;
      font-weight: bold;
      text-transform: none;
    }

    @include mq($until: tablet) {
      padding: 0;
      @include margin(0, auto, 20px, auto);
    }
  }

  &__role,
  &__type {
    font-size: 22px;
    font-weight: 300;
    @include margin(0, 0, 0, 180px);

    @include theme-only($ASIATECHXSG) {
      color: #000;
    }

    @include mq($from: tablet) {
      padding: 8px 0 0;
    }

    @include mq($until: tablet) {
      @include margin(0, auto, 20px, auto);
      font-size: 16px;
    }
  }

  &__category {
    max-width: 495px;
    padding: 58px 0 12px 0;
    @include margin(0, 0, 0, 265px);
    font-size: 22px;
    font-weight: 300;

    @include mq($until: tablet) {
      width: 80%;
      padding: 0;
      @include margin(0, auto, 20px, auto);
      font-size: 16px;
    }
  }

  &__company {
    @include margin(0, 0, 0, 265px);
    padding: 15px 0 12px 0;
    max-width: 495px;
    font-size: 40px;
    font-weight: 400;
    line-height: 1;

    @include theme-only($ASIATECHXSG) {
      font-size: 38px;
    }

    @include mq($until: tablet) {
      width: 80%;
      padding: 0;
      @include margin(0, auto, 20px, auto);
      font-size: 32px;
    }
  }
}
