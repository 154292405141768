@import '../../../styles/shared';

.c-lead-gen-subscribe-form {
  &__field,
  &__select {
    margin-bottom: 10px;

    input,
    select {
      width: 100%;
      height: 40px;
      padding: 0 17px 0 13px;
      border-radius: 3px;
      border: none;
      background-color: #fff;

      &:focus {
        outline: none;
        background-color: #efefef;
      }
    }

    &.error {
      span.error {
        display: block;
        font-size: 12px;
        text-align: right;
        color: #fff;
      }

      input,
      select {
        border: 1px solid rgba($form-error-color, 0.8);
      }
    }
  }

  &__select {
    position: relative;

    option[disabled] {
      color: #999999;
    }

    &:after {
      content: '\e313';
      position: absolute;
      top: 8px;
      right: 0;
      font-family: 'Material Icons';
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding-left: 9px;
      position: relative;

      &.none-selected {
        color: #999999;

        option {
          color: #999999;
        }
      }
    }
  }

  &__submit {
    margin-top: 10px;
    padding: 9px 35px 7px;
    font-family: $font-family-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    border-radius: 20px;
    border: 1px solid $tenant-accent-color;
    background-color: $tenant-accent-color;
    transition: border-color 0.2s ease;
    user-select: none;
    outline: none;
    color: #fff;

    &:hover {
      border-color: #fff;
    }
  }

  &__message {
    margin-top: 20px;
    color: #fff;
  }

  .col-xs-6:nth-child(odd),
  .col-md-6:nth-child(odd) {
    @include mq($from: desktop) {
      padding-right: 5px;
    }
  }

  .col-xs-6:nth-child(even),
  .col-md-6:nth-child(even) {
    @include mq($from: desktop) {
      padding-left: 5px;
    }
  }

  .minimised & {
    display: none;
    padding: 10px 0;
    position: relative;

    &__field,
    &__select {
      input,
      select {
        height: 30px;
        width: 232px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.3);
        color: #999999;
        font-size: 14px;
      }

      select {
        border: initial;

        &::-ms-value,
        &:visited::-ms-value,
        &:focus::-ms-value {
          background-color: rgba(0, 0, 0, 0.3);
        }

        option {
          background-color: white;
        }
      }

      input {
        margin-right: 10px;
      }

      &.error {
        input,
        select {
          border: 1px solid rgba($form-error-color, 0.8);
        }
      }
    }

    &__select:after {
      top: 4px;
    }

    &__submit {
      font-size: 16px;
      line-height: 16px;
      padding: 6px 22px;
      margin: 0;
    }
  }
}

@include mq($until: desktop) {
  .row .halfRow {
    &:nth-child(even) {
      padding-left: 5px;
    }
    &:nth-child(odd) {
      padding-right: 5px;
    }
  }
}

@include mq($from: tablet) {
  .c-lead-gen-subscribe-form {
    .minimised & {
      padding-top: 7px;
      padding-bottom: 5px;

      &__submit {
        margin-left: 10px;
      }
    }
  }
}

@include mq($from: $ultra-wide-breakpoint) {
  .c-lead-gen-subscribe-form {
    .minimised & {
      display: block;
    }
  }
}
