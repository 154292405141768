@import '../../../../styles/shared';

.lead-gen-select {
  margin: 10px 0;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 28px;
    padding-bottom: 14px;

    &-name {
      line-height: 24px;
      font-size: 16px;
      color: #333;
    }

    &-error {
      color: $form-error-color;
      font-size: 12px;
    }

    &-pre-req {
      color: #ee7a04;
      font-size: 12px;
    }
  }

  &__wrapper {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      //@include right(14px);
      right: 14px;
      top: 60%;
      transform: translateY(-50%);
      border-top: 4px solid #aaaaaa;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }

    &:focus {
      outline: none;
    }
  }

  &__default-option {
    display: none;
  }

  &__input {
    height: 44px;
    color: #333;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    border: 1px solid $form-border-color;
    background-color: #fff;
    display: block;
    width: 100%;
    padding: 10px 12px;
    border-radius: 5px;
    -moz-appearance: none;
    -webkit-appearance: none;

    &-error {
      border-color: $form-error-color;
    }

    &-pre-req {
      border-color: rgba(238, 122, 4, 0.2);
      background-color: rgba(238, 122, 4, 0.1);
    }

    &:focus {
      outline: none;
    }
  }
}
