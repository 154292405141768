@import '../../../../../styles/shared';

.c-search-filter-button {
  font-family: $font-family-serif;
  padding: 8px 17px;
  height: 40px;
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  min-width: 180px;

  &__text {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
  }

  &__icon {
    font-size: 22px;
    letter-spacing: -0.06px;
    line-height: 22px;
    margin-left: 10px;
  }
}
