@import '../../../../styles/shared';

.c-base-section-text {
  font-size: 16px;
  line-height: 22px;

  a {
    color: inherit;
  }

  @include mq($until: tablet) {
    font-size: 18px;
  }

  &--styled {
    @include one-of-themes([$FANEXPOHQ, $MEFCC]) {
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
    }
  }

  @include one-of-themes([$ONEOFAKIND, $NAJAHSTACK]) {
    font-family: 'GT America Thin', sans-serif;
    font-size: 22px;
    line-height: 28px;

    @include mq($until: tablet) {
      font-size: 22px;
    }
  }

  @include theme-only($THEARTISTPROJECT) {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 28px;

    @include mq($until: tablet) {
      font-size: 22px;
    }
  }

  @include theme-only($ARTTORONTO) {
    font-family: 'Barlow Regular', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;

    @include mq($until: tablet) {
      font-size: 22px;
    }
  }

  @include theme-only($INTERIORDESIGN) {
    font-family: 'Gibson', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;

    @include mq($until: tablet) {
      font-size: 18px;
    }
  }

  .formatted-text p {
    @include one-of-themes(
      [$ARTTORONTO,
      $THEARTISTPROJECT,
      $ONEOFAKIND,
      $NAJAHSTACK]
    ) {
      line-height: 28px;
    }

    @include theme-only($INTERIORDESIGN) {
      line-height: 24px;
    }
  }
}
