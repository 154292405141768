@import '../../../../styles/shared';

.c-form-block {
  border: 1px solid $form-border-color;
  background-color: #fff;
  border-radius: 3px;

  &__header {
    position: relative;
    padding: 25px 20px 20px;
    border-bottom: 1px solid $form-border-color;
  }

  &__body {
    padding: 20px 25px 25px;
  }

  &__heading {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    color: #002144;
  }

  &__panel {
    position: absolute;
    top: 0;
    bottom: 0;
    @include right(0);
    height: 100%;

    a {
      display: inline-block;
      text-decoration: none;
      color: #68356e;
      padding: 22px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
