@import '../../../../../../styles/shared';

.c-site-lower-footer-copyright {
  margin-top: 20px;
  font-size: 14px;
  color: #fff;

  p {
    line-height: 26px;
  }

  @include text-align(left);

  @include mq($from: desktop) {
    @include text-align(right);
  }
}
